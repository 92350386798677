const IMAGE_UPLOAD_CONFIG = {
  maxWidth: 1200,
  quality: 0.9
};

const REGISTER_TABLE_SUM_ROWS = [
  'tent',
  'seminar',
  'seminarLive',
  'sponsor',
  'sponsor2',
  'totalCost',
  'quantity',
  'period1count',
  'period2count',
  'sum',
  'discount'
];

// key word for PRODUCTS_FOR_NGO tents ngo
const TYPE_OF_ORG_3X3_ARRAY = [
  'ngo',
  'Ideell förening/organisation: svag ekonomiskt ställning (årsredovisning krävs)',
  'Ideell förening: svag ekonomisk ställning (årsredovisning krävs)'
];

const PRODUCTS_FOR_NGO = ['tent2_5x2_5'];
const ALL_PRODUCTS_IDS = [
  'tent2_5x2_5',
  'tent5x2_5',
  'tent5x5',
  'sponsor',
  'sponsor2',
  'seminars',
  'seminarsLive'
];

const DAYS_TO_INVOICE = 5; // 5days
const EVENT_DURATION_STRING = '29 MAJ–1 JUN 2024';

// multiple
// amne
const MAX_SELECT = 5;
const OPTIONS_ARRAY_TO_LIMIT = ['amne', 'tillganglighet', 'sprak'];

const EVENT_YEAR = 2024;

const DAY_LABELS = {
  labelDay1: '29 maj',
  labelDay2: '30 maj',
  labelDay3: '31 maj',
  labelDay4: '1 juni',
  period1Label: '29 maj - 30 maj (2 dagar)',
  period2Label: '31 maj - 1 juni (2 dagar)',
  fullPeriodLabel: '29 maj - 1 juni (4 dagar)'
};

const EVENT_DAYS_ARRAY = ['day1', 'day2', 'day3', 'day4'];

const AVAILABLE_TIME_SLOTS = {
  [EVENT_DAYS_ARRAY[0]]: [0, 0, 0, 0], // day1: [0, 0, 0, 0]
  [EVENT_DAYS_ARRAY[1]]: [0, 0, 0, 0],
  [EVENT_DAYS_ARRAY[2]]: [0, 0, 0, 0],
  [EVENT_DAYS_ARRAY[3]]: [0, 0, 0, 0]
};

const EVENT_DATES_ARRAY = [
  'Onsdag 29 maj',
  'Torsdag 30 maj',
  'Fredag 31 maj',
  'Lördag 1 juni'
];

const EVENT_TIME_SLOTS = [
  '12.30 - 13.15',
  '13.45 - 14.30',
  '15.00 - 15.45',
  '16.15 - 17.00'
];

const SEMINAR_DETAILS = {
  days: EVENT_DATES_ARRAY,
  timeSlots: EVENT_TIME_SLOTS
};

const PRODUCT_CATEGORIES = {
  tents: ['tent'],
  seminars: ['seminars'],
  sponsor: ['sponsor'],
  tillagg: ['tillagg']
};

const PRODUCTS = [
  {
    productId: 'tent2_5x2_5',
    order: '1',
    title: 'Tältyta för ideella organisationer med svag ekonomi',
    pris: 17500,
    taltyta: '2,5m x 2,5m',
    category: PRODUCT_CATEGORIES.tents,
    fullPeriodDiscount: 0,
    ...DAY_LABELS,
    typeOfOrg: 'ngo'
  },
  {
    productId: 'tent5x2_5',
    order: '1',
    title: 'Tältyta 5m x 2,5m',
    pris: 35000,
    taltyta: '5m x 2,5m',
    category: PRODUCT_CATEGORIES,
    fullPeriodDiscount: 0,
    ...DAY_LABELS
  },
  {
    productId: 'tent5x5',
    order: '1',
    title: 'Tältplats 5m x 5m',
    pris: 70000,
    taltyta: '5m x 5m',
    category: PRODUCT_CATEGORIES,
    fullPeriodDiscount: 0,
    ...DAY_LABELS
  },
  {
    productId: 'sponsor',
    order: '1',
    title: 'Stödorganisation',
    pris: 300000,
    taltyta: '5m x 5m',
    category: PRODUCT_CATEGORIES.sponsor,
    ...DAY_LABELS
  },
  {
    productId: 'sponsor2',
    order: '1',
    title: 'Partner',
    pris: 500000,
    taltyta: '5m x 5m',
    category: PRODUCT_CATEGORIES.sponsor,
    ...DAY_LABELS
  },
  {
    productId: 'seminars',
    order: '1',
    title: 'Boka scentid – utan livesändning',
    pris: 20000,
    category: PRODUCT_CATEGORIES.seminars,
    ...SEMINAR_DETAILS
  },
  {
    productId: 'seminarsLive',
    order: '1',
    title: 'Boka scentid – med livesändning & inspelning',
    pris: 35000,
    category: PRODUCT_CATEGORIES.seminars,
    ...SEMINAR_DETAILS
  }
];

const STATS = {
  seminars: {
    availableTimeSlots: AVAILABLE_TIME_SLOTS
  },
  seminarsLive: {
    availableTimeSlots: AVAILABLE_TIME_SLOTS
  },
  tent2_5x2_5: {
    p1Count: 0,
    p2Count: 0,
    p1Max: 0,
    p2Max: 0
  },
  tent5x2_5: {
    p1Count: 0,
    p2Count: 0,
    p1Max: 0,
    p2Max: 0
  },
  tent5x5: {
    p1Count: 0,
    p2Count: 0,
    p1Max: 0,
    p2Max: 0
  },
  sponsor: {
    count: 0,
    max: 0
  },
  sponsor2: {
    count: 0,
    max: 0
  }
};

export * from './options';
export * from './assets';

export {
  REGISTER_TABLE_SUM_ROWS,
  OPTIONS_ARRAY_TO_LIMIT,
  MAX_SELECT,
  TYPE_OF_ORG_3X3_ARRAY,
  DAYS_TO_INVOICE,
  PRODUCTS_FOR_NGO,
  ALL_PRODUCTS_IDS,
  EVENT_YEAR,
  EVENT_DAYS_ARRAY,
  DAY_LABELS,
  AVAILABLE_TIME_SLOTS,
  SEMINAR_DETAILS,
  PRODUCTS,
  STATS,
  IMAGE_UPLOAD_CONFIG,
  EVENT_DURATION_STRING,
  EVENT_DATES_ARRAY,
  EVENT_TIME_SLOTS,
  PRODUCT_CATEGORIES
};
