/**
 * Saves data to the server using Firestore.
 *
 * @param {Object} options - The options for saving to the server.
 * @param {string} options.path - The path to the document in Firestore.
 * @param {string} [options.method='set'] - The method to use for saving (default: 'set').
 * @param {Object} options.data - The data to save to the server.
 * @param {Object} options.firestore - The Firestore instance.
 * @returns {Promise} A promise that resolves when the data is successfully saved to the server.
 * @throws {Error} If the Firestore instance is not provided.
 */
const saveToServer = ({ path, method = 'set', data, firestore }) => {
  if (!firestore) {
    throw new Error('firestore instance required!');
  }

  return firestore[method](path, data, { merge: true });
};

export { saveToServer };
