/* eslint-disable react/no-unused-class-component-methods */
/**
 *
 * AdminRegister
 *
 */
import React from 'react';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Button } from 'semantic-ui-react';

import AccDataTable from '../../components/AccDataTable';
import { IosCalendarOutline, MdSearch } from '../../components/Icons';
import Loader from '../../components/Loader';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import { ContentContainer, WhiteBox } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import Account from '../Account';
import { RegisterDataBox } from './AdminRegisterStyles';
import exportData from './exportData';
import handleTableDataTransform from '../../utils/handleTableDataTransform';
import getUsersFullBookingsData from '../../utils/getUsersFullBookingsData';

class AdminRegister extends React.Component {
  state = {
    search: '',
    dataFilter: [
      ['getAll', 'Alla'],
      ['hasTillagg', 'tillagg'],
      ['hasTent', 'utställare'],
      ['hasTent2_5x2_5', '2,5x2,5'],
      ['hasTent5x2_5', '5x2,5'],
      ['hasTent5x5', '5x5'],
      ['hasSponsor', 'sponsorer'],
      ['hasSeminars', 'seminariehållare'],
      ['hasSeminarslive', 'seminariehållarelive']
    ],
    selectedDataFilter: ['getAll', 'Alla'],
    // selectedDataFilter: ['hasTillagg', 'tillagg'],
    showDetailsView: false,
    selectedUserRow: {}
  };

  fullData = {};

  getAll = (item) => item;

  // The following filter functions should match the state filter ['hasTent','hasTent']
  hasTent = (item) => item.tent && item.tent > 0;

  hasTillagg = (item) => item.tillagg && item.tillagg > 0;

  hasTent2_5x2_5 = ({ tentBookings }) => {
    if (tentBookings?.length > 0) {
      const filteredData = filter(tentBookings, { productId: 'tent2_5x2_5' });
      return filteredData?.length > 0;
    }
    return false;
  };

  hasTent5x2_5 = ({ tentBookings }) => {
    if (tentBookings?.length > 0) {
      const filteredData = filter(tentBookings, { productId: 'tent5x2_5' });

      return filteredData?.length > 0;
    }
    return false;
  };

  hasTent5x5 = ({ tentBookings }) => {
    if (tentBookings?.length > 0) {
      const filteredData = filter(tentBookings, { productId: 'tent5x5' });

      return filteredData?.length > 0;
    }
    return false;
  };

  hasSponsor = (item) => item.sponsor && item.sponsor > 0;

  hasSeminars = (item) => item.seminar && item.seminar > 0;

  hasSeminarslive = (item) => item.seminarLive && item.seminarLive > 0;

  inputChangeHandler = ({ target }) => {
    const { value } = target;
    this.setState({
      search: value
    });
  };

  toggleFilter = (item) => () => {
    this.setState({
      selectedDataFilter: item
    });
  };

  handleCancelPreview = () => {
    this.setState({
      selectedUserRow: {},
      showDetailsView: false
    });
  };

  // data preview
  handleSelectedUserRowClick = ({ authId }) => {
    this.setState({
      selectedUserRow: {
        authId
      },
      showDetailsView: true
    });
  };

  handleTableDataFilter = (search, selectedDataFilter) => (item) => {
    if (search && search !== '') {
      return (
        item.email?.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        item.fakturaEmail?.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        item.orgName?.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        item.telefonnummer?.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    if (selectedDataFilter && selectedDataFilter !== '') {
      return this[selectedDataFilter[0]](item);
    }
    return true;
  };

  computeFilteredUserData = () => {
    const { search, selectedDataFilter } = this.state;

    const dataArray = Array.isArray(this.fullData)
      ? this.fullData
      : Object.values(this.fullData);

    const filteredArrayData = dataArray?.filter(
      this.handleTableDataFilter(search, selectedDataFilter)
    );

    exportData(filteredArrayData);
  };

  renderFilterRow = (dataFilter, selectedDataFilter) =>
    dataFilter?.map((item) => (
      <div
        key={item}
        role="presentation"
        className={`filter ${selectedDataFilter === item ? 'selected' : ''}`}
        onClick={this.toggleFilter(item)}
      >
        {item[1]}
      </div>
    ));

  refreshComponent = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ editAndSave: true });
  };

  render() {
    // console.count('Register');
    const {
      users,
      orderedUsers,
      orderedBookings,
      orderedTillaggBookings,
      orderedSeminarBookings,
      orderedSeminarLiveBookings,
      orderedSponsorBookings,
      firestore,
      firebase,
      claims
    } = this.props;

    const {
      search,
      dataFilter,
      selectedDataFilter,
      showDetailsView,
      selectedUserRow
    } = this.state;

    this.fullData = getUsersFullBookingsData({
      users,
      orderedBookings,
      orderedTillaggBookings,
      orderedSeminarBookings,
      orderedSeminarLiveBookings,
      orderedSponsorBookings
    });

    if (showDetailsView) {
      // console.log(this.fullData[selectedUserRow.authId]);
      return (
        <Account
          {...this.fullData[selectedUserRow.authId]}
          firestore={firestore}
          firebase={firebase}
          registerState={this.refreshComponent}
          handleCancelPreview={this.handleCancelPreview}
          claims={claims}
        />
      );
    }
    // console.log(orderedUsers);
    return (
      <ContentContainer>
        <section>
          <WhiteBox>
            <RegisterDataBox>
              <h3>
                <IosCalendarOutline />
                &nbsp;Register
                <div className="filters">
                  {orderedUsers ? orderedUsers.length : <Loader />}{' '}
                  Organisationer
                </div>
              </h3>
              <div style={{ display: 'flex' }}>
                <Button
                  primary
                  size="mini"
                  basic
                  content="Ladda ner data"
                  icon="file excel"
                  labelPosition="right"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => this.computeFilteredUserData()}
                />
              </div>
              <div className="horizontal-container horizontal-container-row">
                <div className="singel-flex">
                  <div className="search">
                    <MdSearch />
                    <input
                      className="search-input"
                      maxLength="140"
                      type="search"
                      placeholder="Sök organisation"
                      onChange={this.inputChangeHandler}
                    />
                  </div>
                </div>
                <div className="singel-flex filter-row">
                  {this.renderFilterRow(dataFilter, selectedDataFilter)}
                </div>
              </div>
            </RegisterDataBox>
            {!users ? (
              <div>
                <Loader />
              </div>
            ) : (
              <AccDataTable
                obj={this.fullData}
                columns={[
                  ['orgName', 'Organisation'],
                  ['loggedin', 'Senast inloggad'],
                  ['orgNr', 'orgnr'],
                  ['typeOfOrg', 'orgtyp'],
                  ['createAt', 'datum'],
                  ['tillagg', 'tillagg'],
                  ['tent', 'Tältyta'],
                  ['seminar', 'Seminarietider'],
                  ['seminarLive', 'Seminarietider Live'],
                  ['sponsor', 'Partners'],
                  ['totalCost', 'fakturabelopp'],
                  ['telefonnummer', 'telefon'],
                  ['email', 'epost'],
                  ['authId', 'userId'],
                  ['peId', 'PE:Accounting Id']
                ]}
                columnClick={{
                  orgName: this.handleSelectedUserRowClick
                }}
                sort="orgName"
                filter={this.handleTableDataFilter(search, selectedDataFilter)}
                transform={handleTableDataTransform}
              />
            )}
          </WhiteBox>
        </section>
      </ContentContainer>
    );
  }
}

AdminRegister.propTypes = {
  users: PropTypes.object,
  firestore: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired,
  claims: PropTypes.object.isRequired,
  orderedUsers: PropTypes.array,
  orderedBookings: PropTypes.array,
  orderedTillaggBookings: PropTypes.array,
  orderedSeminarBookings: PropTypes.array,
  orderedSeminarLiveBookings: PropTypes.array,
  orderedSponsorBookings: PropTypes.array
};

AdminRegister.defaultProps = {
  orderedBookings: [],
  orderedTillaggBookings: [],
  orderedSeminarBookings: [],
  orderedSeminarLiveBookings: [],
  orderedSponsorBookings: []
};

export default withErrorBoundary(
  AdminRegister,
  MyFallbackComponent,
  errorCallback
);
