/**
 *
 * User Bookings (Split of Account page)
 *
 */
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import set from 'lodash/set';
import unset from 'lodash/unset';
import PropTypes from 'prop-types';
import debounceRender from 'react-debounce-render';
import { withErrorBoundary } from 'react-error-boundary';
import {
  Button,
  Form,
  Grid,
  Icon,
  Label,
  Message,
  Modal
} from 'semantic-ui-react';

import { uploadFileToStorage } from '../../api/index';
import ContentEditor from '../../components/ContentEditor';
import KontaktaWorkman from '../../components/KontaktaWorkman';
import {
  SeminarLogo,
  StarLogo,
  TentLogo,
  WarnLogo
} from '../../components/Logos';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import TextCopy from '../../components/TextCopy/TextCopy';
import {
  AMNE_OPTIONS,
  TILLGANGLIGHET_OPTIONS,
  SPRAK_OPTIONS,
  MAX_SELECT,
  DAYS_TO_INVOICE
} from '../../configs';
import {
  ContentContainer,
  DataBox,
  PasswordError,
  WhiteBox
} from '../../styledComponents';
import { clearDateBeforeSaving } from '../../utils/clearDateBeforeSaving';
import daysLeft from '../../utils/daysLeft';
import { errorCallback } from '../../utils/index';
import Wrapper from './style';

import handleTableDataTransform from '../../utils/handleTableDataTransform';

const INITIAL_STATE = {
  readOnlyCurrentUser: '',
  readOnlyCurrentUserBookings: {},
  readOnlyCurrentUserTillaggBookings: {},
  readOnlyrenderSponsorBookings: {},
  readOnlySeminarBookings: {},
  readOnlySeminarLiveBookings: {},
  stateCurrentUser: {},
  stateCurrentUserBookings: {},
  stateCurrentUserTillaggBookings: {},
  stateSponsorBookings: {},
  stateSeminarBookings: {},
  stateSeminarLiveBookings: {},
  isDeleteClicked: false,
  isSaveClicked: false,
  uploading: false,
  password: '',
  repeatPassword: '',
  submittingPassword: false,
  disableInput: false,
  isPasswordSuccess: false,
  email: '',
  oldPassword: '',
  cosponsors: {},
  loading: false
};

const getSaysLeftText = ({ createAt, sentInvoice, invoiceCreatedAt }) => {
  if (!createAt) {
    return 'Data createAt missing';
  }

  const fakturaDatum = sentInvoice
    ? `Fakturan skickades den ${handleTableDataTransform(invoiceCreatedAt) || ''}`
    : `Fakturan kommer att skickas efter ${handleTableDataTransform({
        seconds: parseInt(createAt.seconds, 10) + 60 * 60 * 24 * DAYS_TO_INVOICE
      })}`;

  return !sentInvoice && daysLeft(createAt) > 0
    ? ` Ni har ${daysLeft(createAt)} dagar kvar att avboka. ${fakturaDatum}`
    : fakturaDatum;
};

export class UserBookings extends React.Component {
  state = {
    ...INITIAL_STATE,
    isOpen: {},
    modalOpen: false,
    uploadingCurrentUserBookings: false
  };

  isAdmin = get(this, 'props.claims.admin', false);
  // remove createAt check updateAt
  // field from updating
  // this converts the field from timeStamp to
  // array and it breaks the invoice function

  componentDidUpdate() {
    const {
      stateCurrentUser,
      stateCurrentUserBookings,
      stateCurrentUserTillaggBookings,
      stateSeminarBookings,
      stateSeminarLiveBookings,
      stateSponsorBookings,
      loading
    } = this.state;

    if (loading) {
      return;
    }

    const {
      currentUser,
      currentUserBookings,
      currentUserTillaggBookings,
      currentUserSeminars,
      currentUserSeminarsLive,
      currentUserSponsor
    } = this.props;

    if (isEmpty(stateCurrentUser) && !isEmpty(currentUser)) {
      this.setState({ stateCurrentUser: { ...currentUser } });
    }
    if (isEmpty(stateCurrentUserBookings) && !isEmpty(currentUserBookings)) {
      this.setState({ stateCurrentUserBookings: { ...currentUserBookings } });
    }
    if (
      isEmpty(stateCurrentUserTillaggBookings) &&
      !isEmpty(currentUserTillaggBookings)
    ) {
      this.setState({
        stateCurrentUserTillaggBookings: { ...currentUserTillaggBookings }
      });
    }
    if (isEmpty(stateSeminarBookings) && !isEmpty(currentUserSeminars)) {
      this.setState({ stateSeminarBookings: { ...currentUserSeminars } });
    }
    if (
      isEmpty(stateSeminarLiveBookings) &&
      !isEmpty(currentUserSeminarsLive)
    ) {
      this.setState({
        stateSeminarLiveBookings: { ...currentUserSeminarsLive }
      });
    }
    if (isEmpty(stateSponsorBookings) && !isEmpty(currentUserSponsor)) {
      this.setState({ stateSponsorBookings: { ...currentUserSponsor } });
    }
  }

  toggleState = (stateKey, key) => {
    if (key) {
      this.setState((prev) => ({
        [stateKey]: {
          ...prev[stateKey],
          [key]: !prev[stateKey][key]
        }
      }));
    } else {
      this.setState((prev) => ({
        [stateKey]: !prev[stateKey]
      }));
    }
  };

  toggleReadOnlyMode =
    (readOnlyId, bookingID = '') =>
    () => {
      const { isSaveClicked } = this.state;

      if (isSaveClicked) {
        this.setState({
          isSaveClicked: false
        });
      }

      if (bookingID && readOnlyId !== 'readOnlyCurrentUser') {
        this.setState((prev) => ({
          [readOnlyId]: {
            ...prev[readOnlyId],
            [bookingID]: !prev[readOnlyId][bookingID]
          }
        }));
      } else {
        this.setState((prev) => ({
          [readOnlyId]: !prev[readOnlyId]
        }));
      }
    };

  // handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  handlePasswordInputChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value });

  changePassword = () => {
    this.setState({ disableInput: true, submittingPassword: true });
    const { password, repeatPassword, oldPassword } = this.state;
    const {
      firebase,
      currentUser: { email }
    } = this.props;
    const user = firebase.auth().currentUser;
    if (password !== repeatPassword) {
      this.setState({
        passwordError: {
          code: 'Password not right',
          message: 'Must have 6 char'
        }
      });
      return;
    }

    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      oldPassword
    );

    user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => {
        user
          .updatePassword(repeatPassword)
          .then(() => {
            this.setState({
              isPasswordSuccess: true,
              submittingPassword: false
            });
            setTimeout(this.handleClose, 2000);
          })
          .catch((error) => {
            this.setState({
              passwordError: error,
              isPasswordError: true,
              disableInput: false,
              submittingPassword: false
            });
          });
      })
      .catch((error) => {
        this.setState({
          passwordError: error,
          isPasswordError: true,
          disableInput: false,
          submittingPassword: false
        });
      });
  };

  //  #region saveToDatabase
  saveToDatabase = (args) => async () => {
    const {
      databasePath,
      bookingId,
      reduxPath,
      data,
      stateDataId,
      readOnlyId
    } = args;

    this.toggleReadOnlyMode(readOnlyId, bookingId)();
    const { [stateDataId]: isDataChanged } = this.state;

    if (databasePath && !isEmpty(isDataChanged)) {
      const { firestore, firebase } = this.props;

      // console.log('data to be saved', data);

      this.setState({
        loading: true,
        isSaveClicked: true
      });

      const cleanedData = clearDateBeforeSaving(data);

      const {
        discount,
        userId,
        logo,
        kontaktProfilbild,
        kontaktProfilbild2,
        moderatorBild,
        deltagare
      } = data;

      const newDiscount = discount && Math.abs(discount);

      const newDataToSave = {
        ...cleanedData,
        discount: newDiscount || 0,
        updateAt: firestore.FieldValue.serverTimestamp()
      };

      // if deltagare exists upload files and save url
      if (deltagare) {
        const deltagareWithUploads = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const item of deltagare) {
          const { logo: logoLocal } = item || {};

          if (typeof logoLocal === 'object') {
            try {
              // eslint-disable-next-line no-await-in-loop
              const downloadURL = await uploadFileToStorage({
                uid: userId,
                fileList: logoLocal,
                firebase
              });

              if (downloadURL) {
                deltagareWithUploads.push({ ...item, logo: downloadURL });
              }
            } catch (error) {
              console.error('deltagare', error);
            } finally {
              console.info('deltagare', 'request finished');
            }
          } else {
            deltagareWithUploads.push(item);
          }
        }

        newDataToSave.deltagare = deltagareWithUploads;
      }

      const fileListItems = [];
      if (logo instanceof FileList) {
        fileListItems.push({ name: 'logo', value: logo });
      }
      if (kontaktProfilbild instanceof FileList) {
        fileListItems.push({
          name: 'kontaktProfilbild',
          value: kontaktProfilbild
        });
      }
      if (kontaktProfilbild2 instanceof FileList) {
        fileListItems.push({
          name: 'kontaktProfilbild2',
          value: kontaktProfilbild2
        });
      }
      if (moderatorBild instanceof FileList) {
        fileListItems.push({
          name: 'moderatorBild',
          value: moderatorBild
        });
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const item of fileListItems) {
        if (!item) {
          return;
        }

        const { name, value } = item;

        try {
          let downloadURL = '';

          // for user profile there is no userId
          if (name === 'logo' && !userId) {
            const tempUserId = userId || bookingId; // for user profile there is no userId
            // eslint-disable-next-line no-await-in-loop
            downloadURL = await uploadFileToStorage({
              uploadPath: `uploads/company_logo/${tempUserId}`,
              uid: tempUserId,
              fileList: value,
              firebase
            });
          } else {
            // eslint-disable-next-line no-await-in-loop
            downloadURL = await uploadFileToStorage({
              uid: userId,
              fileList: value,
              firebase
            });
          }

          if (downloadURL) {
            newDataToSave[name] = downloadURL || '';
          }
        } catch (error) {
          console.error(name, error);
        } finally {
          console.info(name, 'request finished');
        }
      }

      // console.log('Account newDataToSave', newDataToSave);

      // firebase save function
      firestore
        .update(
          {
            collection: databasePath,
            doc: bookingId,
            path: reduxPath || databasePath
          },
          newDataToSave
        )
        .then(() => {
          console.info('saved successfully');
          /* TODO:check if it causes any issues */
          // this.setState({ [stateDataId]: {} });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error });
        })
        .finally(() => {
          this.setState({
            loading: false
          });

          const { authId } = this.props;

          if (reduxPath === 'currentUserSeminarsBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserSeminarsLiveBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserTillaggBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserSponsorBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }
        });
    }
  };
  //  #endregion

  //  #region deleteFromDatabase
  deleteFromDatabase = (args) => () => {
    const { databasePath, data, id, reduxPath, stateDataId } = args;

    // TODO:refactor to use modals
    // eslint-disable-next-line no-alert
    const confirmDelete = window.confirm(
      'Är du säkert på att du vill ta bort din bokning?'
    );

    const { firestore } = this.props;
    if (firestore && id && confirmDelete) {
      this.setState({
        loading: true,
        isDeleteClicked: true
      });

      // add to deleted collection
      firestore
        .set(
          { collection: 'deletedBookings', doc: id },
          {
            ...{ data },
            collection: 'deletedBookings',
            deleteAt: firestore.FieldValue.serverTimestamp()
          }
        )
        .then(() => {
          console.info('delete successfull');
        })
        .catch((err) => console.error('delete fail', err));

      // delete from database
      firestore
        .delete({
          collection: databasePath,
          doc: id,
          path: reduxPath
        })
        .then(() => {
          console.info('Successfully deleted');
          this.setState({ [stateDataId]: {} });
          const { authId } = this.props;

          if (reduxPath === 'currentUserSeminarsBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserSeminarsLiveBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserTillaggBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserSponsorBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          firestore
            .get({
              collection: 'seminarBookings'
            })
            .catch((err) => console.error(err));

          firestore
            .get({
              collection: 'seminarLiveBookings'
            })
            .catch((err) => console.error(err));
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error });
        })
        .finally(() => {
          this.setState({
            isDeleteClicked: false,
            ...INITIAL_STATE
          });
        });
    }
  };
  //  #endregion

  //  #region handleCancel
  handleCancel = () => {
    const { registerState } = this.props;
    this.setState(
      () => ({
        ...INITIAL_STATE
      }),
      () => {
        if (registerState) registerState();
      }
    );
  };
  //  #endregion

  //  #region handleSponsorInputChange
  handleSponsorInputChange = (id, deltagareIndex, objectKey) => (event) => {
    const {
      target: { name, value, files }
    } = event;

    const { isSaveClicked } = this.state;

    if (isSaveClicked) {
      this.setState({
        isSaveClicked: false
      });
    }

    this.setState((prev) => {
      const localState = cloneDeep(prev);

      if (objectKey) {
        set(
          localState,
          `stateSponsorBookings.${id}.${objectKey}[${deltagareIndex}].${name}`,
          files || value
        );
      } else {
        set(localState, `stateSponsorBookings.${id}.${name}`, files || value);
      }

      return localState;
    });
  };
  //  #endregion

  // cosponsors
  //  #region handleCosponsorInputChange
  handleBookingChange = (id, deltagareIndex, objectKey) => (event) => {
    const {
      target: { name, value, files }
    } = event;

    const { isSaveClicked } = this.state;

    if (isSaveClicked) {
      this.setState({
        isSaveClicked: false
      });
    }

    this.setState((prev) => {
      const localState = cloneDeep(prev);

      if (objectKey) {
        set(
          localState,
          `stateCurrentUserBookings.${id}.${objectKey}[${deltagareIndex}].${name}`,
          files || value
        );
      } else {
        set(
          localState,
          `stateCurrentUserBookings.${id}.${name}`,
          files || value
        );
      }

      return localState;
    });
  };
  //  #endregion

  //  #region handleSeminarInputChange
  handleSeminarInputChange = (id, deltagareIndex, objectKey) => (event) => {
    const {
      target: { name, value, files }
    } = event;

    const { isSaveClicked } = this.state;

    if (isSaveClicked) {
      this.setState({
        isSaveClicked: false
      });
    }

    this.setState((prev) => {
      const localState = cloneDeep(prev);

      if (objectKey) {
        set(
          localState,
          `stateSeminarBookings.${id}.${objectKey}[${deltagareIndex}].${name}`,
          files || value
        );
      } else {
        set(localState, `stateSeminarBookings.${id}.${name}`, files || value);
      }

      return localState;
    });
  };
  //  #endregion

  //  #region handleSeminarLiveInputChange
  handleSeminarLiveInputChange = (id, deltagareIndex, objectKey) => (event) => {
    const {
      target: { name, value, files }
    } = event;

    const { isSaveClicked } = this.state;

    if (isSaveClicked) {
      this.setState({
        isSaveClicked: false
      });
    }

    this.setState((prev) => {
      const localState = cloneDeep(prev);

      if (objectKey) {
        set(
          localState,
          `stateSeminarLiveBookings.${id}.${objectKey}[${deltagareIndex}].${name}`,
          files || value
        );
      } else {
        set(
          localState,
          `stateSeminarLiveBookings.${id}.${name}`,
          files || value
        );
      }

      return localState;
    });
  };
  //  #endregion

  //  #region renderCurrentUserBookings
  renderCurrentUserBookings = (bookings) => {
    if (isEmpty(bookings)) return;

    // console.log('tents all bookings', bookings);

    const bookingKeys = Object.keys(bookings);

    // loops through all bookings
    return bookingKeys?.map((bookingId) => {
      const {
        stateCurrentUserBookings,
        readOnlyCurrentUserBookings,
        isOpen,
        isSaveClicked,
        isDeleteClicked,
        loading
      } = this.state;

      const { isFromUser } = this.props;

      const databasePath = 'bookings';
      const reduxPath = isFromUser ? 'currentUserBookings' : '';
      const currentReadOnly = !readOnlyCurrentUserBookings[bookingId];

      const stateDataId = 'stateCurrentUserBookings';
      const readOnlyId = 'readOnlyCurrentUserBookings';

      const newData = {
        ...bookings[bookingId],
        ...stateCurrentUserBookings[bookingId]
      };

      const {
        amne,
        tillganglighet,
        sprak,
        taltyta,
        cosponsors,
        cosponsorsCount,
        createAt,
        discount,
        fullPeriodLabel,
        highlightsDay1,
        highlightsDay2,
        highlightsDay3,
        highlightsDay4,
        isFullPeriod,
        labelDay1,
        labelDay2,
        labelDay3,
        labelDay4,
        ovrigt,
        period1,
        period1Label,
        period2,
        period2Label,
        quantity,
        sentInvoice,
        invoiceCreatedAt,
        totalPrice,
        reservationSiteLink,
        kontaktperson,
        kontaktJobbtitel,
        kontaktOrganisation,
        kontaktEmail,
        kontaktTel,
        kontaktperson2,
        kontaktJobbtitel2,
        kontaktOrganisation2,
        kontaktEmail2,
        kontaktTel2
      } = newData;

      // console.log('amne', amne);

      let datum = '';

      if (isFullPeriod) {
        datum = fullPeriodLabel;
      } else if (period1) {
        datum = period1Label;
      } else if (period2) {
        datum = period2Label;
      }

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(totalPrice).toLocaleString(
            'sv'
          )})`}
        >
          <Form.Input
            max={totalPrice}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={this.isAdmin && !isFromUser ? currentReadOnly : true}
            onChange={this.handleBookingChange(bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createAt,
        sentInvoice,
        invoiceCreatedAt
      });

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                this.toggleState('isOpen', bookingId);
              }}
            >
              <TentLogo />
              Tält - Tältyta {taltyta || ''}
            </h3>
            <Form
              loading={loading}
              className="horizontal-container"
              onSubmit={this.saveToDatabase({
                databasePath,
                reduxPath,
                data: newData,
                bookingId,
                stateDataId,
                readOnlyId
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Tält">{`Tältyta ${taltyta || ''}`}</li>
                    <li aria-label="Datum">{datum}</li>
                    <li aria-label="Antal">{quantity}</li>
                    <li aria-label="Pris">
                      {Number(totalPrice).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>

                  <br />

                  <ul aria-label="Uppgifter för tältet">
                    <li>
                      <Form.TextArea
                        onChange={this.handleBookingChange(bookingId)}
                        readOnly={currentReadOnly}
                        label="Förklara vad ni vill att besökare ska veta om era aktiviteter (max 800 tecken inkl. blanksteg)"
                        maxLength="800"
                        id="ovrigt"
                        name="ovrigt"
                        value={ovrigt || ''}
                      />
                    </li>
                  </ul>

                  <br />
                  <ul aria-label="Kategorityp">
                    <li>
                      <p>Ämne</p>
                      {amne &&
                        map(amne, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        onChange={(_, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleBookingChange(bookingId)({
                            target
                          });
                        }}
                        disabled={currentReadOnly}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                      />
                    </li>
                    <li>
                      <p>Tillgänglighet</p>
                      {tillganglighet &&
                        map(tillganglighet, (item, i) => (
                          <Label key={i}>{item}</Label>
                        ))}
                      <br />
                      <br />
                      <Form.Select
                        onChange={(_, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleBookingChange(bookingId)({
                            target
                          });
                        }}
                        disabled={currentReadOnly}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                      />
                    </li>
                    <li>
                      <p>Språk</p>
                      {sprak &&
                        map(sprak, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        onChange={(_, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleBookingChange(bookingId)({
                            target
                          });
                        }}
                        disabled={currentReadOnly}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                      />
                    </li>
                  </ul>

                  <br />
                  <ul aria-label="Extern länk för anmälan.">
                    <li>
                      <Label className="info">
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </Label>
                      <Form.Input
                        readOnly={currentReadOnly}
                        onChange={this.handleBookingChange(bookingId)}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                        så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                        den länk du fyller i."
                      />
                    </li>
                  </ul>

                  <br />
                  <ul aria-label="KONTAKTPERSONER FÖR TÄLTET">
                    {/* contact 1 */}
                    <li>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              onChange={this.handleBookingChange(bookingId)}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>
                  <br />
                  <ul aria-label="KONTAKTPERSONER FÖR TÄLTET">
                    {/* contact 1 */}
                    <li>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              onChange={this.handleBookingChange(bookingId)}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>

                    {/* contact 2 */}
                    <li>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild2"
                              name="kontaktProfilbild2"
                              accept="image/*,.svg"
                              onChange={this.handleBookingChange(bookingId)}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild2 === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild2}
                              alt="kontaktProfilbild2"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktFotograf2"
                          name="kontaktFotograf2"
                          value={kontaktFotograf2 || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktKortbio2"
                          name="kontaktKortbio2"
                          value={kontaktKortbio2 || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleBookingChange(bookingId)}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>
                  <br />
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Highlights / Händer i tältet">
                    <li>
                      <TextCopy name="highlites-text-copy" />
                      <br />
                      <br />
                    </li>
                    {period1 && (
                      <>
                        <li
                          aria-label={`${labelDay1} (max 330 tecken inkl. blanksteg)`}
                        >
                          <Form.TextArea
                            maxLength="330"
                            className="short"
                            rows="2"
                            name="highlightsDay1"
                            value={highlightsDay1 || ''}
                            readOnly={currentReadOnly}
                            onChange={this.handleBookingChange(bookingId)}
                          />
                        </li>
                        <li
                          aria-label={`${labelDay2} (max 330 tecken inkl. blanksteg)`}
                        >
                          <Form.TextArea
                            maxLength="330"
                            className="short"
                            rows="2"
                            name="highlightsDay2"
                            value={highlightsDay2 || ''}
                            readOnly={currentReadOnly}
                            onChange={this.handleBookingChange(bookingId)}
                          />
                        </li>
                      </>
                    )}
                    {period2 && (
                      <>
                        <li
                          aria-label={`${labelDay3} (max 330 tecken inkl. blanksteg)`}
                        >
                          <Form.TextArea
                            maxLength="330"
                            className="short"
                            rows="2"
                            name="highlightsDay3"
                            value={highlightsDay3 || ''}
                            readOnly={currentReadOnly}
                            onChange={this.handleBookingChange(bookingId)}
                          />
                        </li>
                        <li
                          aria-label={`${labelDay4} (max 330 tecken inkl. blanksteg)`}
                        >
                          <Form.TextArea
                            maxLength="330"
                            className="short"
                            rows="2"
                            name="highlightsDay4"
                            value={highlightsDay4 || ''}
                            readOnly={currentReadOnly}
                            onChange={this.handleBookingChange(bookingId)}
                          />
                        </li>
                      </>
                    )}
                  </ul>

                  <ul>
                    <li>
                      <TextCopy name="medarrangor-text-copy" />
                      <br />
                      <br />
                      {/* render cosponsors */}
                      {map(cosponsors, (value, id) => {
                        if (value === undefined) {
                          return null;
                        }
                        const {
                          hemsida: itemHemsida,
                          organisationsnamn,
                          organisationsnummer
                        } = value || {};

                        return (
                          <fieldset key={id}>
                            <legend>Medarrangör</legend>
                            {currentReadOnly ? (
                              ''
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                <Icon
                                  name="trash alternate outline"
                                  color="red"
                                  size="large"
                                  link
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState((prevState) => {
                                      const localState = cloneDeep(prevState);

                                      unset(
                                        localState,
                                        `stateCurrentUserBookings.${bookingId}.cosponsors.${id}`
                                      );

                                      set(
                                        localState,
                                        `stateCurrentUserBookings.${bookingId}.cosponsorsCount`,
                                        cosponsorsCount - 1
                                      );

                                      return localState;
                                    });
                                  }}
                                />
                              </div>
                            )}

                            <Form.Input
                              onChange={this.handleBookingChange(
                                bookingId,
                                id,
                                'cosponsors'
                              )}
                              readOnly={currentReadOnly}
                              label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                              maxLength="120"
                              name="organisationsnamn"
                              value={organisationsnamn || ''}
                            />

                            <Form.Input
                              onChange={this.handleBookingChange(
                                bookingId,
                                id,
                                'cosponsors'
                              )}
                              readOnly={currentReadOnly}
                              label="Organisationsnummer (i formatet 802481–1658)"
                              maxLength="20"
                              // pattern="[0-9]{6}-[0-9]{4}"
                              name="organisationsnummer"
                              value={organisationsnummer || ''}
                            />

                            <Form.Input
                              onChange={this.handleBookingChange(
                                bookingId,
                                id,
                                'cosponsors'
                              )}
                              readOnly={currentReadOnly}
                              label="Hemsida (max 160 tecken inkl. blanksteg)"
                              maxLength="160"
                              name="hemsida"
                              value={itemHemsida || ''}
                            />
                            <br />
                            <br />
                          </fieldset>
                        );
                      })}

                      {currentReadOnly ? (
                        ''
                      ) : (
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            this.setState((prevState) => {
                              const localState = cloneDeep(prevState);

                              const newItemKey = `s${cosponsorsCount + 1}`;

                              set(
                                localState,
                                `stateCurrentUserBookings.${bookingId}.cosponsors`,
                                {
                                  ...cosponsors,
                                  [newItemKey]: { id: newItemKey }
                                }
                              );

                              set(
                                localState,
                                `stateCurrentUserBookings.${bookingId}.cosponsorsCount`,
                                cosponsorsCount + 1
                              );

                              return localState;
                            });
                          }}
                          // disabled={size(cosponsors) >= 4}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Row verticalAlign="top">
                  <Grid.Column width={16}>
                    <p>
                      <WarnLogo />
                      {daysLeftText}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {currentReadOnly ? (
                      <div className="flex no-gap">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleReadOnlyMode(
                              'readOnlyCurrentUserBookings',
                              bookingId,
                              'stateCurrentUserBookings'
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        <KontaktaWorkman />
                        {(!sentInvoice && daysLeft(createAt) > 0) ||
                        this.isAdmin ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              this.deleteFromDatabase({
                                databasePath,
                                data: newData,
                                id: bookingId,
                                reduxPath,
                                stateDataId: `stateCurrentUserBookings.${bookingId}`
                              })();
                            }}
                            disabled={isDeleteClicked}
                            content="Ta bort bokning"
                            icon={{ name: 'delete', color: 'red' }}
                            basic
                            primary
                            labelPosition="right"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex no-gap">
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Spara"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          basic
                          primary
                          type="button"
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleCancel();
                          }}
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  //  #region renderCurrentUserTillaggBookings
  renderCurrentUserTillaggBookings = (bookings) => {
    if (isEmpty(bookings)) return;

    // console.log('tents all bookings', bookings);

    const bookingKeys = Object.keys(bookings);

    // loops through all bookings
    return bookingKeys?.map((bookingId) => {
      const {
        stateCurrentUserTillaggBookings,
        readOnlyCurrentUserTillaggBookings,
        isOpen,
        isDeleteClicked,
        loading
      } = this.state;

      const { isFromUser } = this.props;

      const databasePath = 'tillaggBookings';
      const reduxPath = isFromUser ? 'currentUserTillaggBookings' : '';
      const currentReadOnly = !readOnlyCurrentUserTillaggBookings?.[bookingId];

      const stateDataId = 'stateCurrentUserTillaggBookings';
      const readOnlyId = 'readOnlyCurrentUserTillaggBookings';

      const newData = {
        ...bookings[bookingId],
        ...stateCurrentUserTillaggBookings[bookingId]
      };

      const {
        title,
        createAt,
        discount,
        quantity,
        sentInvoice,
        invoiceCreatedAt,
        totalPrice
      } = newData;

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(totalPrice).toLocaleString(
            'sv'
          )})`}
        >
          <Form.Input
            max={totalPrice}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={this.isAdmin && !isFromUser ? currentReadOnly : true}
            onChange={this.handleBookingChange(bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createAt,
        sentInvoice,
        invoiceCreatedAt
      });

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                this.toggleState('isOpen', bookingId);
              }}
            >
              <StarLogo /> Tillägg - {title || ''}
            </h3>
            <Form
              loading={loading}
              className="horizontal-container"
              onSubmit={this.saveToDatabase({
                databasePath,
                reduxPath,
                data: newData,
                bookingId,
                stateDataId,
                readOnlyId
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Antal">{quantity}</li>
                    <li aria-label="Pris">
                      {Number(totalPrice).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>
                </Grid.Column>
                <Grid.Column />
                <Grid.Row verticalAlign="top">
                  <Grid.Column width={16}>
                    <p>
                      <WarnLogo />
                      {daysLeftText}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div className="flex no-gap">
                      {(!sentInvoice && daysLeft(createAt) > 0) ||
                      this.isAdmin ? (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.deleteFromDatabase({
                              databasePath,
                              data: newData,
                              id: bookingId,
                              reduxPath,
                              stateDataId: `stateCurrentUserTillaggBookings.${bookingId}`
                            })();
                          }}
                          disabled={isDeleteClicked}
                          content="Ta bort bokning"
                          icon={{ name: 'delete', color: 'red' }}
                          basic
                          primary
                          labelPosition="right"
                        />
                      ) : null}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  //  #region renderSponsorBookings
  renderSponsorBookings = (bookings) => {
    if (isEmpty(bookings)) return false;

    const bookingKeys = Object.keys(bookings);

    return bookingKeys?.map((bookingId) => {
      const {
        stateSponsorBookings,
        readOnlyrenderSponsorBookings,
        isOpen,
        isSaveClicked,
        isDeleteClicked,
        loading
      } = this.state;

      const { isFromUser } = this.props;

      const databasePath = 'sponsorBookings';
      const reduxPath = isFromUser ? 'currentUserSponsorBookings' : '';
      const currentReadOnly = !readOnlyrenderSponsorBookings[bookingId];

      const stateDataId = 'stateSponsorBookings';
      const readOnlyId = 'readOnlyrenderSponsorBookings';

      const newData = {
        ...bookings[bookingId],
        ...stateSponsorBookings[bookingId]
      };

      const {
        amne,
        tillganglighet,
        sprak,
        taltyta,
        cosponsors,
        cosponsorsCount,
        createAt,
        fullPeriodLabel,
        highlightsDay1,
        highlightsDay2,
        highlightsDay3,
        highlightsDay4,
        labelDay1,
        labelDay2,
        labelDay3,
        labelDay4,
        ovrigt,
        pris,
        sentInvoice,
        invoiceCreatedAt,
        logo,
        discount = 0,
        reservationSiteLink,
        kontaktperson,
        kontaktJobbtitel,
        kontaktOrganisation,
        kontaktEmail,
        kontaktTel,
        kontaktperson2,
        kontaktJobbtitel2,
        kontaktOrganisation2,
        kontaktEmail2,
        kontaktTel2,
        productId
      } = newData;

      // eslint-disable-next-line no-nested-ternary
      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(pris).toLocaleString('sv')})`}
        >
          <Form.Input
            max={pris}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={this.isAdmin && !isFromUser ? currentReadOnly : true}
            onChange={this.handleSponsorInputChange(bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createAt,
        sentInvoice,
        invoiceCreatedAt
      });

      const bookingTitle =
        productId === 'sponsor' ? 'Stödorganisation' : 'Partner';

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                this.toggleState('isOpen', bookingId);
              }}
            >
              <TentLogo /> {bookingTitle}
            </h3>
            <Form
              loading={loading}
              onSubmit={this.saveToDatabase({
                databasePath,
                data: newData,
                stateDataId,
                readOnlyId,
                reduxPath,
                bookingId
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Tält">{`Tältyta ${taltyta}` || ''}</li>
                    <li aria-label="Datum">{fullPeriodLabel}</li>
                    <li aria-label="Pris">
                      {Number(pris).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>
                  <br />
                  <ul aria-label="Uppgifter för tältet">
                    <li>
                      <Form.TextArea
                        readOnly={currentReadOnly}
                        onChange={this.handleSponsorInputChange(bookingId)}
                        label="Förklara vad ni vill att besökare ska veta om era aktiviteter (max 800 tecken inkl. blanksteg)"
                        maxLength="800"
                        id="ovrigt"
                        name="ovrigt"
                        value={ovrigt || ''}
                      />
                      <Form.Field>
                        <label htmlFor="logo">
                          Bifoga organisationens logotyp
                          <span className="upload-note">
                            * allowed: jpg, jpeg, png, svg - max size 5MB
                          </span>
                          <Form.Input
                            type="file"
                            id="logo"
                            name="logo"
                            accept="image/*,.svg"
                            disabled={currentReadOnly}
                            onChange={this.handleSponsorInputChange(bookingId)}
                          />
                        </label>
                        <i className="note">* This is the uploaded file</i>
                        <br />
                        {typeof logo === 'string' ? (
                          <img
                            className="uploads-preview"
                            src={logo}
                            alt="logo"
                          />
                        ) : null}
                      </Form.Field>
                      <h5>Ämne</h5>
                      {amne &&
                        map(amne, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleSponsorInputChange(bookingId)({
                            target
                          });
                        }}
                        disabled={currentReadOnly}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                      />
                      <br />
                      <br />
                      <h5>Tillgänglighet</h5>
                      {tillganglighet &&
                        map(tillganglighet, (item, i) => (
                          <Label key={i}>{item}</Label>
                        ))}
                      <br />
                      <br />
                      <Form.Select
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleSponsorInputChange(bookingId)({
                            target
                          });
                        }}
                        disabled={currentReadOnly}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                      />
                      <h5>Språk</h5>
                      {sprak &&
                        map(sprak, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleSponsorInputChange(bookingId)({
                            target
                          });
                        }}
                        disabled={currentReadOnly}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                      />

                      <br />
                      <br />
                      <h5>Extern länk för anmälan.</h5>
                      <Label className="info">
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </Label>
                      <Form.Input
                        readOnly={currentReadOnly}
                        onChange={this.handleSponsorInputChange(bookingId)}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                        så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                        den länk du fyller i."
                      />
                      <br />
                      <br />

                      <h5>KONTAKTPERSONER FÖR TÄLTET</h5>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      {/* contact 1 */}
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              onChange={this.handleSponsorInputChange(
                                bookingId
                              )}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                      {/* contact 2 */}
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild2"
                              name="kontaktProfilbild2"
                              accept="image/*,.svg"
                              onChange={this.handleSponsorInputChange(
                                bookingId
                              )}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild2 === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild2}
                              alt="kontaktProfilbild2"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktFotograf2"
                          name="kontaktFotograf2"
                          value={kontaktFotograf2 || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktKortbio2"
                          name="kontaktKortbio2"
                          value={kontaktKortbio2 || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={this.handleSponsorInputChange(bookingId)}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Highlights / Händer i tältet">
                    <li>
                      <TextCopy name="highlites-text-copy" />
                    </li>
                    <li
                      aria-label={`${labelDay1} (max 330 tecken inkl. blanksteg)`}
                    >
                      <Form.TextArea
                        maxLength="330"
                        type="text"
                        name="highlightsDay1"
                        value={highlightsDay1 || ''}
                        readOnly={currentReadOnly}
                        onChange={this.handleSponsorInputChange(bookingId)}
                      />
                    </li>
                    <li
                      aria-label={`${labelDay2} (max 330 tecken inkl. blanksteg)`}
                    >
                      <Form.TextArea
                        maxLength="330"
                        type="text"
                        name="highlightsDay2"
                        value={highlightsDay2 || ''}
                        readOnly={currentReadOnly}
                        onChange={this.handleSponsorInputChange(bookingId)}
                      />
                    </li>
                    <li
                      aria-label={`${labelDay3} (max 330 tecken inkl. blanksteg)`}
                    >
                      <Form.TextArea
                        maxLength="330"
                        type="text"
                        name="highlightsDay3"
                        value={highlightsDay3 || ''}
                        readOnly={currentReadOnly}
                        onChange={this.handleSponsorInputChange(bookingId)}
                      />
                    </li>
                    <li
                      aria-label={`${labelDay4} (max 330 tecken inkl. blanksteg)`}
                    >
                      <Form.TextArea
                        maxLength="330"
                        type="text"
                        name="highlightsDay4"
                        value={highlightsDay4 || ''}
                        readOnly={currentReadOnly}
                        onChange={this.handleSponsorInputChange(bookingId)}
                      />
                    </li>
                  </ul>
                  <br />
                  <ul>
                    <li>
                      <TextCopy name="medarrangor-text-copy" />

                      <br />
                      <br />
                      {/* render cosponsors */}
                      {map(cosponsors, (value, key) => {
                        if (value === undefined) {
                          return null;
                        }
                        const {
                          hemsida,
                          organisationsnamn,
                          organisationsnummer
                        } = value || {};

                        return (
                          <fieldset key={key}>
                            <legend>Medarrangör</legend>

                            {currentReadOnly ? (
                              ''
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                <Icon
                                  name="trash alternate outline"
                                  color="red"
                                  size="large"
                                  link
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState((prevState) => {
                                      const localState = cloneDeep(prevState);

                                      unset(
                                        localState,
                                        `stateSponsorBookings.${bookingId}.cosponsors.${key}`
                                      );

                                      set(
                                        localState,
                                        `stateSponsorBookings.${bookingId}.cosponsorsCount`,
                                        cosponsorsCount - 1
                                      );

                                      return localState;
                                    });
                                  }}
                                />
                              </div>
                            )}

                            <Form.Input
                              onChange={this.handleSponsorInputChange(
                                bookingId,
                                key,
                                'cosponsors'
                              )}
                              readOnly={currentReadOnly}
                              label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                              maxLength="120"
                              name="organisationsnamn"
                              value={organisationsnamn || ''}
                            />

                            <Form.Input
                              onChange={this.handleSponsorInputChange(
                                bookingId,
                                key,
                                'cosponsors'
                              )}
                              readOnly={currentReadOnly}
                              label="Organisationsnummer (i formatet 802481–1658)"
                              maxLength="20"
                              // pattern="[0-9]{6}-[0-9]{4}"
                              name="organisationsnummer"
                              value={organisationsnummer || ''}
                            />

                            <Form.Input
                              onChange={this.handleSponsorInputChange(
                                bookingId,
                                key,
                                'cosponsors'
                              )}
                              readOnly={currentReadOnly}
                              label="Hemsida (max 160 tecken inkl. blanksteg)"
                              maxLength="160"
                              name="hemsida"
                              value={hemsida || ''}
                            />
                            <br />
                            <br />
                          </fieldset>
                        );
                      })}

                      {currentReadOnly ? (
                        ''
                      ) : (
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            this.setState((prevState) => {
                              const localState = cloneDeep(prevState);

                              const newItemKey = `s${cosponsorsCount + 1}`;

                              set(
                                localState,
                                `stateSponsorBookings.${bookingId}.cosponsors`,
                                {
                                  ...cosponsors,
                                  [newItemKey]: { id: newItemKey }
                                }
                              );

                              set(
                                localState,
                                `stateSponsorBookings.${bookingId}.cosponsorsCount`,
                                cosponsorsCount + 1
                              );

                              return localState;
                            });
                          }}
                          // disabled={size(cosponsors) >= 4}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>

                <Grid.Row>
                  <p>
                    <WarnLogo />
                    {daysLeftText}
                  </p>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {currentReadOnly ? (
                      <div className="flex no-gap">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleReadOnlyMode(
                              'readOnlyrenderSponsorBookings',
                              bookingId,
                              'staterenderSponsorBookings'
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        <KontaktaWorkman />
                        {daysLeft(createAt) >= 0 || this.isAdmin ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              this.deleteFromDatabase({
                                databasePath,
                                data: newData,
                                id: bookingId,
                                reduxPath,
                                stateDataId: 'stateSponsorBookings'
                              })();
                            }}
                            loading={loading}
                            disabled={isDeleteClicked}
                            content="Ta bort bokning"
                            icon={{ name: 'delete', color: 'red' }}
                            basic
                            primary
                            labelPosition="right"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex no-gap">
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Bekräfta"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleCancel();
                          }}
                          basic
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  //  #region renderSeminarBookings
  renderSeminarBookings = (seminar) => {
    if (isEmpty(seminar)) return;
    const {
      stateSeminarBookings,
      readOnlySeminarBookings: stateReadonly,
      isOpen,
      isSaveClicked,
      isDeleteClicked,
      loading
    } = this.state;

    const { isFromUser } = this.props;
    const reduxPath = isFromUser ? 'currentUserSeminarsBookings' : '';
    const databasePath = 'seminarBookings';

    return Object.keys(seminar)?.map((bookingId) => {
      const readOnlySeminarBookings = !(stateReadonly[bookingId] || '');
      const currentBooking = seminar[bookingId];

      const stateDataId = 'stateSeminarBookings';
      const readOnlyId = 'readOnlySeminarBookings';

      // [stateDataId][bookingId]
      const newData = {
        ...currentBooking,
        ...stateSeminarBookings[bookingId]
      };

      const {
        beskriv,
        coArrangers,
        createAt,
        dayString,
        deltagare,
        discount,
        amne,
        tillganglighet,
        sprak,
        moderator,
        moderatorBild,
        moderatorBio,
        moderatorFotograf,
        moderatorJobbtitel,
        moderatorOrganisation,
        namn,
        pris,
        sentInvoice,
        invoiceCreatedAt,
        timeSlot,
        reservationSiteLink,
        kontaktperson,
        kontaktJobbtitel,
        kontaktOrganisation,
        kontaktEmail,
        kontaktTel,
        kontaktperson2,
        kontaktJobbtitel2,
        kontaktOrganisation2,
        kontaktEmail2,
        kontaktTel2
      } = newData;

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(pris).toLocaleString('sv')})`}
        >
          <Form.Input
            max={Number(pris).toLocaleString('sv')}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={
              this.isAdmin && !isFromUser ? readOnlySeminarBookings : true
            }
            onChange={this.handleSeminarInputChange(bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createAt,
        sentInvoice,
        invoiceCreatedAt
      });

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                this.toggleState('isOpen', bookingId);
              }}
            >
              <SeminarLogo /> Seminarietid — {dayString} ({timeSlot})
            </h3>
            <Form
              loading={loading}
              onSubmit={this.saveToDatabase({
                databasePath,
                reduxPath,
                data: newData,
                bookingId,
                stateDataId,
                readOnlyId
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Datum">{dayString}</li>
                    <li aria-label="Tid">{timeSlot}</li>
                    <li aria-label="Pris">
                      {Number(pris).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>

                  <ul aria-label="Uppgifter för seminariet">
                    <li>
                      <Form.Input
                        maxLength="90"
                        readOnly={readOnlySeminarBookings}
                        onChange={this.handleSeminarInputChange(bookingId)}
                        label="Namn på seminariet (max 90 tecken inkl. blanksteg)"
                        id="Namn"
                        name="namn"
                        value={namn || ''}
                        required
                        autoComplete="off"
                      />
                      <Form.TextArea
                        readOnly={readOnlySeminarBookings}
                        onChange={this.handleSeminarInputChange(bookingId)}
                        id="beskriv"
                        name="beskriv"
                        label="Beskrivning av seminariet (max 800 tecken inkl. blanksteg) "
                        maxLength="800"
                        value={beskriv || ''}
                        autoComplete="off"
                      />

                      <br />
                      <br />
                      <h5>
                        Välj de ämnen, språk och tillgänglighetsanpassningar som
                        passar in på ert seminarium. det går att välja flera
                        alternativ.
                      </h5>

                      <p>Ämne</p>
                      {amne &&
                        map(amne, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        label="Ämne"
                        disabled={readOnlySeminarBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleSeminarInputChange(bookingId)({
                            target
                          });
                        }}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                      />
                      <p>Tillgänglighet</p>
                      {tillganglighet &&
                        map(tillganglighet, (item, i) => (
                          <Label key={i}>{item}</Label>
                        ))}
                      <br />
                      <br />
                      <Form.Select
                        label="Tillgänglighet"
                        disabled={readOnlySeminarBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleSeminarInputChange(bookingId)({ target });
                        }}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                      />
                      <p>Språk</p>
                      {sprak &&
                        map(sprak, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        label="Språk"
                        multiple
                        disabled={readOnlySeminarBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleSeminarInputChange(bookingId)({ target });
                        }}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                      />
                    </li>

                    <li>
                      <h5>Extern länk för anmälan.</h5>
                      <Label className="info">
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </Label>
                      <Form.Input
                        readOnly={readOnlySeminarBookings}
                        onChange={this.handleSeminarInputChange(bookingId)}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                        så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                        den länk du fyller i."
                      />
                    </li>

                    <li>
                      <h5>Moderator</h5>
                      <fieldset>
                        <legend>Moderator</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="moderator"
                          name="moderator"
                          value={moderator || ''}
                          label="Namn på moderator"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="moderatorJobbtitel"
                          name="moderatorJobbtitel"
                          value={moderatorJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="moderatorOrganisation"
                          name="moderatorOrganisation"
                          value={moderatorOrganisation || ''}
                          label="Organisation"
                        />
                        <Form.TextArea
                          maxLength="330"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="moderatorBio"
                          name="moderatorBio"
                          value={moderatorBio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        />
                        <Form.Field>
                          <label htmlFor="moderatorBild">
                            Lägg upp profilbild
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="moderatorBild"
                              name="moderatorBild"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarBookings}
                              onChange={this.handleSeminarInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof moderatorBild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={moderatorBild}
                              alt="moderatorBild"
                            />
                          ) : null}
                          <Form.Input
                            maxLength="120"
                            readOnly={readOnlySeminarBookings}
                            onChange={this.handleSeminarInputChange(bookingId)}
                            id="moderatorFotograf"
                            name="moderatorFotograf"
                            value={moderatorFotograf || ''}
                            label="Fotograf/bildkälla"
                          />
                        </Form.Field>
                      </fieldset>
                    </li>
                    <li>
                      <h5>KONTAKTPERSONER FÖR SEMINARIET</h5>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      {/* contact 1 */}
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarBookings}
                              onChange={this.handleSeminarInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"

                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          maxLength="330"

                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                      {/* contact 2 */}
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild2">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild2"
                              name="kontaktProfilbild2"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarBookings}
                              onChange={this.handleSeminarInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild2 === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild2}
                              alt="kontaktProfilbild2"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"

                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktFotograf2"
                          name="kontaktFotograf2"
                          value={kontaktFotograf2 || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          maxLength="330"

                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktKortbio2"
                          name="kontaktKortbio2"
                          value={kontaktKortbio2 || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarBookings}
                          onChange={this.handleSeminarInputChange(bookingId)}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <TextCopy name="medarrangor-text-copy" />
                      <br />
                      <br />
                      {/* render coArrangers */}
                      {map(coArrangers, (value, key) => {
                        if (value === undefined) {
                          return;
                        }

                        const {
                          hemsida: hemsidaItem,
                          organisationsnamn,
                          organisationsnummer
                        } = value || {};

                        return (
                          <fieldset key={key}>
                            <legend>Medarrangör</legend>

                            {readOnlySeminarBookings ? (
                              ''
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                <Icon
                                  name="trash alternate outline"
                                  color="red"
                                  size="large"
                                  link
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState((prevState) => {
                                      const localData = cloneDeep(prevState);

                                      unset(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers.${key}`
                                      );

                                      const coArrangersLocal = get(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers`
                                      );

                                      const newArray = coArrangersLocal?.filter(
                                        (item) => {
                                          return !!item;
                                        }
                                      );

                                      set(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers`,
                                        [...newArray]
                                      );

                                      return { ...localData };
                                    });
                                  }}
                                />
                              </div>
                            )}

                            <Form.Input
                              onChange={(event, target) =>
                                this.handleSeminarInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers'
                                )({ target })
                              }
                              readOnly={readOnlySeminarBookings}
                              label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                              maxLength="120"
                              name="organisationsnamn"
                              value={organisationsnamn || ''}
                            />

                            <Form.Input
                              onChange={(event, target) =>
                                this.handleSeminarInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers'
                                )({ target })
                              }
                              readOnly={readOnlySeminarBookings}
                              label="Organisationsnummer (i formatet 802481–1658)"
                              maxLength="20"
                              // pattern="[0-9]{6}-[0-9]{4}"
                              name="organisationsnummer"
                              value={organisationsnummer || ''}
                            />

                            <Form.Input
                              onChange={(event, target) =>
                                this.handleSeminarInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers'
                                )({ target })
                              }
                              readOnly={readOnlySeminarBookings}
                              label="Hemsida (max 160 tecken inkl. blanksteg)"
                              maxLength="160"
                              name="hemsida"
                              value={hemsidaItem || ''}
                            />
                            <br />
                            <br />
                          </fieldset>
                        );
                      })}

                      {readOnlySeminarBookings ? (
                        ''
                      ) : (
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            this.setState((prevState) => {
                              const localData = cloneDeep(prevState);

                              const coArrangersLocal = get(
                                localData,
                                `${stateDataId}.${bookingId}.coArrangers`
                              );

                              coArrangersLocal.push({});

                              const newArray = coArrangersLocal?.filter(
                                (item) => {
                                  return !!item;
                                }
                              );

                              set(
                                localData,
                                `${stateDataId}.${bookingId}.coArrangers`,
                                [...newArray]
                              );

                              return { ...localData };
                            });
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Paneldeltagare">
                    <li>
                      {map(deltagare, (item, index) => {
                        if (item === undefined) {
                          return null;
                        }
                        const {
                          beskrivning,
                          deltagare: deltagareItem,
                          fotografCaption,
                          jobbTitel,
                          logo,
                          organisation
                        } = item || {};
                        return (
                          <Form.Group key={index} grouped>
                            <fieldset>
                              <legend>Paneldeltagare {`${index + 1}`}</legend>
                              {readOnlySeminarBookings ? (
                                ''
                              ) : (
                                <div style={{ textAlign: 'right' }}>
                                  <Icon
                                    name="trash alternate outline"
                                    color="red"
                                    size="large"
                                    link
                                    onClick={(e) => {
                                      e.preventDefault();

                                      this.setState((prev) => {
                                        const localData = cloneDeep(prev);

                                        const localStateCopy = get(
                                          localData,
                                          `${stateDataId}.${bookingId}`
                                        );

                                        // delete localStateCopy.deltagare[index];

                                        localStateCopy.deltagare.splice(
                                          index,
                                          1
                                        );

                                        const newState = {
                                          [stateDataId]: {
                                            ...prev[stateDataId],
                                            [bookingId]: localStateCopy
                                          }
                                        };

                                        return { ...newState };
                                      });
                                    }}
                                  />
                                </div>
                              )}
                              <Form.Input
                                maxLength="120"
                                label="Namn på deltagare"
                                type="text"
                                name="deltagare"
                                value={deltagareItem || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Field>
                                <label htmlFor="logo">
                                  Lägg upp profilbild
                                  <span className="upload-note">
                                    (* allowed: jpg, jpeg, png, svg - max size
                                    5MB)
                                  </span>
                                  <Form.Input
                                    type="file"
                                    id="logo"
                                    name="logo"
                                    accept="image/*,.svg"
                                    disabled={readOnlySeminarBookings}
                                    onChange={(event) =>
                                      this.handleSeminarInputChange(
                                        bookingId,
                                        index,
                                        'deltagare'
                                      )(event)
                                    }
                                  />
                                </label>
                                <i className="note">
                                  * This is the uploaded file
                                </i>
                                <br />
                                {typeof logo === 'string' ? (
                                  <img
                                    className="uploads-preview"
                                    src={logo}
                                    alt="logo"
                                  />
                                ) : null}
                              </Form.Field>
                              <Form.Input
                                maxLength="120"
                                label="Fotograf/bildkälla"
                                type="text"
                                name="fotografCaption"
                                value={fotografCaption || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Input
                                maxLength="120"
                                label="Jobbtitel"
                                type="text"
                                name="jobbTitel"
                                value={jobbTitel || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Input
                                maxLength="120"
                                label="Organisation"
                                type="text"
                                name="organisation"
                                value={organisation || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.TextArea
                                maxLength="330"
                                label="Kort beskrivning om deltagaren (max 330 tecken)"
                                name="beskrivning"
                                value={beskrivning || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                              />
                            </fieldset>
                          </Form.Group>
                        );
                      })}

                      {!readOnlySeminarBookings && (
                        <Button
                          secondary
                          size="tiny"
                          content="Lägg till deltagare"
                          icon="plus user"
                          labelPosition="right"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState((prevState) => {
                              const localData = cloneDeep(prevState);

                              const deltagareArray = get(
                                localData,
                                `${stateDataId}.${bookingId}.deltagare`
                              );

                              deltagareArray.push({});

                              set(
                                localData,
                                `${stateDataId}.${bookingId}.deltagare`,
                                deltagareArray
                              );

                              return localData;
                            });
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Row>
                  <p>
                    <WarnLogo />
                    {daysLeftText}
                  </p>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {readOnlySeminarBookings ? (
                      <div className="flex no-gap">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleReadOnlyMode(
                              'readOnlySeminarBookings',
                              bookingId,
                              'stateSeminarBookings'
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        {(!sentInvoice && daysLeft(createAt) > 0) ||
                        this.isAdmin ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              this.deleteFromDatabase({
                                databasePath,
                                data: currentBooking,
                                id: bookingId,
                                reduxPath,

                                stateDataId: 'stateSeminarBookings'
                              })();
                            }}
                            loading={loading}
                            disabled={isDeleteClicked}
                            content="Ta bort bokning"
                            icon={{ name: 'delete', color: 'red' }}
                            basic
                            primary
                            labelPosition="right"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex no-gap">
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Spara"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleCancel();
                          }}
                          type="button"
                          basic
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  //  #region renderSeminarLiveBookings
  renderSeminarLiveBookings = (seminar) => {
    if (isEmpty(seminar)) return;
    const {
      stateSeminarLiveBookings,
      readOnlySeminarLiveBookings: stateReadonly,
      isOpen,
      isSaveClicked,
      isDeleteClicked,
      loading
    } = this.state;

    const { isFromUser } = this.props;
    const reduxPath = isFromUser ? 'currentUserSeminarsLiveBookings' : '';
    const databasePath = 'seminarLiveBookings';

    return Object.keys(seminar)?.map((bookingId) => {
      const readOnlySeminarLiveBookings = !(stateReadonly[bookingId] || '');
      const currentBooking = seminar[bookingId];

      const stateDataId = 'stateSeminarLiveBookings';
      const readOnlyId = 'readOnlySeminarLiveBookings';

      const newData = {
        ...currentBooking,
        ...stateSeminarLiveBookings[bookingId]
      };

      const {
        beskriv,
        coArrangers,
        createAt,
        dayString,
        deltagare,
        discount,
        amne,
        tillganglighet,
        sprak,
        moderator,
        moderatorBild,
        moderatorBio,
        moderatorFotograf,
        moderatorJobbtitel,
        moderatorOrganisation,
        namn,
        pris,
        sentInvoice,
        invoiceCreatedAt,
        timeSlot,
        reservationSiteLink,
        kontaktperson,
        kontaktJobbtitel,
        kontaktOrganisation,
        kontaktEmail,
        kontaktTel,
        kontaktperson2,
        kontaktJobbtitel2,
        kontaktOrganisation2,
        kontaktEmail2,
        kontaktTel2
      } = newData;

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(pris).toLocaleString('sv')})`}
        >
          <Form.Input
            max={pris}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={
              this.isAdmin && !isFromUser ? readOnlySeminarLiveBookings : true
            }
            onChange={this.handleSeminarLiveInputChange(bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createAt,
        sentInvoice,
        invoiceCreatedAt
      });

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                this.toggleState('isOpen', bookingId);
              }}
            >
              <SeminarLogo /> Seminarietid — livesändning — {dayString} (
              {timeSlot})
            </h3>
            <Form
              loading={loading}
              onSubmit={this.saveToDatabase({
                databasePath,
                reduxPath,
                data: newData,
                bookingId,
                stateDataId,
                readOnlyId
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Datum">{dayString}</li>
                    <li aria-label="Tid">{timeSlot}</li>
                    <li aria-label="Pris">
                      {Number(pris).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>

                  <ul aria-label="Uppgifter för seminariet">
                    <li>
                      <Form.Input
                        maxLength="90"
                        readOnly={readOnlySeminarLiveBookings}
                        onChange={this.handleSeminarLiveInputChange(bookingId)}
                        label="Namn på seminariet (max 90 tecken inkl. blanksteg)"
                        id="Namn"
                        name="namn"
                        value={namn || ''}
                        required
                        autoComplete="off"
                      />
                      <Form.TextArea
                        readOnly={readOnlySeminarLiveBookings}
                        onChange={this.handleSeminarLiveInputChange(bookingId)}
                        id="beskriv"
                        name="beskriv"
                        label="Beskrivning av seminariet (max 800 tecken inkl. blanksteg) "
                        maxLength="800"
                        value={beskriv || ''}
                        autoComplete="off"
                      />
                      <br />
                      <br />
                      <h5>
                        Välj de ämnen, språk och tillgänglighetsanpassningar som
                        passar in på ert seminarium. det går att välja flera
                        alternativ.
                      </h5>

                      <p>Ämne</p>
                      {amne &&
                        map(amne, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        label="Ämne"
                        disabled={readOnlySeminarLiveBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          return this.handleSeminarLiveInputChange(bookingId)({
                            target
                          });
                        }}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                      />
                      <p>Tillgänglighet</p>
                      {tillganglighet &&
                        map(tillganglighet, (item, i) => (
                          <Label key={i}>{item}</Label>
                        ))}
                      <br />
                      <br />
                      <Form.Select
                        label="Tillgänglighet"
                        disabled={readOnlySeminarLiveBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleSeminarLiveInputChange(bookingId)({
                            target
                          });
                        }}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                      />
                      <p>Språk</p>
                      {sprak &&
                        map(sprak, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        label="Språk"
                        multiple
                        disabled={readOnlySeminarLiveBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          this.handleSeminarLiveInputChange(bookingId)({
                            target
                          });
                        }}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                      />
                    </li>

                    <li>
                      <h5>Extern länk för anmälan.</h5>
                      <Label className="info">
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </Label>
                      <Form.Input
                        readOnly={readOnlySeminarLiveBookings}
                        onChange={this.handleSeminarLiveInputChange(bookingId)}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                        så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                        den länk du fyller i."
                      />
                    </li>

                    <li>
                      <h5>Moderator</h5>
                      <fieldset>
                        <legend>Moderator</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="moderator"
                          name="moderator"
                          value={moderator || ''}
                          label="Namn på moderator"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="moderatorJobbtitel"
                          name="moderatorJobbtitel"
                          value={moderatorJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="moderatorOrganisation"
                          name="moderatorOrganisation"
                          value={moderatorOrganisation || ''}
                          label="Organisation"
                        />
                        <Form.TextArea
                          maxLength="330"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="moderatorBio"
                          name="moderatorBio"
                          value={moderatorBio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        />
                        <Form.Field>
                          <label htmlFor="moderatorBild">
                            Lägg upp profilbild
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="moderatorBild"
                              name="moderatorBild"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarLiveBookings}
                              onChange={this.handleSeminarLiveInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof moderatorBild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={moderatorBild}
                              alt="moderatorBild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="moderatorFotograf"
                          name="moderatorFotograf"
                          value={moderatorFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                      </fieldset>
                    </li>
                    <li>
                      <h5>KONTAKTPERSONER FÖR SEMINARIET</h5>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      {/* contact 1 */}
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarLiveBookings}
                              onChange={this.handleSeminarLiveInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"

                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          maxLength="330"

                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                      {/* contact 2 */}
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild2">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild2"
                              name="kontaktProfilbild2"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarLiveBookings}
                              onChange={this.handleSeminarLiveInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild2 === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild2}
                              alt="kontaktProfilbild2"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"

                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktFotograf2"
                          name="kontaktFotograf2"
                          value={kontaktFotograf2 || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          maxLength="330"

                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktKortbio2"
                          name="kontaktKortbio2"
                          value={kontaktKortbio2 || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={this.handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <TextCopy name="medarrangor-text-copy" />
                      <br />
                      <br />
                      {/* render coArrangers */}
                      {map(coArrangers, (value, key) => {
                        if (value === undefined) {
                          return;
                        }

                        const {
                          hemsida: hemsidaItem,
                          organisationsnamn,
                          organisationsnummer
                        } = value || {};

                        return (
                          <fieldset key={key}>
                            <legend>Medarrangör</legend>

                            {readOnlySeminarLiveBookings ? (
                              ''
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                <Icon
                                  name="trash alternate outline"
                                  color="red"
                                  size="large"
                                  link
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState((prevState) => {
                                      const localData = cloneDeep(prevState);

                                      unset(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers.${key}`
                                      );

                                      const coArrangersLocal = get(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers`
                                      );

                                      const newArray = coArrangersLocal?.filter(
                                        (item) => {
                                          return !!item;
                                        }
                                      );

                                      set(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers`,
                                        [...newArray]
                                      );

                                      return { ...localData };
                                    });
                                  }}
                                />
                              </div>
                            )}

                            <Form.Input
                              onChange={(event, target) =>
                                this.handleSeminarLiveInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers'
                                )({ target })
                              }
                              readOnly={readOnlySeminarLiveBookings}
                              label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                              maxLength="120"
                              name="organisationsnamn"
                              value={organisationsnamn || ''}
                            />

                            <Form.Input
                              onChange={(event, target) =>
                                this.handleSeminarLiveInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers'
                                )({ target })
                              }
                              readOnly={readOnlySeminarLiveBookings}
                              label="Organisationsnummer (i formatet 802481–1658)"
                              maxLength="20"
                              // pattern="[0-9]{6}-[0-9]{4}"
                              name="organisationsnummer"
                              value={organisationsnummer || ''}
                            />

                            <Form.Input
                              onChange={(event, target) =>
                                this.handleSeminarLiveInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers'
                                )({ target })
                              }
                              readOnly={readOnlySeminarLiveBookings}
                              label="Hemsida (max 160 tecken inkl. blanksteg)"
                              maxLength="160"
                              name="hemsida"
                              value={hemsidaItem || ''}
                            />
                            <br />
                            <br />
                          </fieldset>
                        );
                      })}

                      {readOnlySeminarLiveBookings ? (
                        ''
                      ) : (
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            this.setState((prevState) => {
                              const localData = cloneDeep(prevState);

                              const coArrangersLocal = get(
                                localData,
                                `${stateDataId}.${bookingId}.coArrangers`
                              );

                              coArrangersLocal.push({});

                              const newArray = coArrangersLocal?.filter(
                                (item) => {
                                  return !!item;
                                }
                              );

                              set(
                                localData,
                                `${stateDataId}.${bookingId}.coArrangers`,
                                [...newArray]
                              );

                              return { ...localData };
                            });
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Paneldeltagare">
                    <li>
                      {map(deltagare, (item, index) => {
                        if (item === undefined) {
                          return null;
                        }
                        const {
                          beskrivning,
                          deltagare: deltagareItem,
                          fotografCaption,
                          jobbTitel,
                          logo,
                          organisation
                        } = item || {};
                        return (
                          <Form.Group key={index} grouped>
                            <fieldset>
                              <legend>Paneldeltagare {`${index + 1}`}</legend>
                              {readOnlySeminarLiveBookings ? (
                                ''
                              ) : (
                                <div style={{ textAlign: 'right' }}>
                                  <Icon
                                    name="trash alternate outline"
                                    color="red"
                                    size="large"
                                    link
                                    onClick={(e) => {
                                      e.preventDefault();

                                      this.setState((prev) => {
                                        const localData = cloneDeep(prev);

                                        const localStateCopy = get(
                                          localData,
                                          `${stateDataId}.${bookingId}`
                                        );

                                        // delete localStateCopy.deltagare[index];

                                        localStateCopy.deltagare.splice(
                                          index,
                                          1
                                        );

                                        const newState = {
                                          [stateDataId]: {
                                            ...prev[stateDataId],
                                            [bookingId]: localStateCopy
                                          }
                                        };

                                        return { ...newState };
                                      });
                                    }}
                                  />
                                </div>
                              )}
                              <Form.Input
                                maxLength="120"
                                label="Namn på deltagare"
                                type="text"
                                name="deltagare"
                                value={deltagareItem || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Field>
                                <label htmlFor="logo">
                                  Lägg upp profilbild (valfritt)
                                  <span className="upload-note">
                                    * allowed: jpg, jpeg, png, svg - max size
                                    5MB
                                  </span>
                                  <Form.Input
                                    type="file"
                                    id="logo"
                                    name="logo"
                                    accept="image/*,.svg"
                                    disabled={readOnlySeminarLiveBookings}
                                    onChange={(event) =>
                                      this.handleSeminarLiveInputChange(
                                        bookingId,
                                        index,
                                        'deltagare'
                                      )(event)
                                    }
                                  />
                                </label>
                                <i className="note">
                                  * This is the uploaded file
                                </i>
                                <br />
                                {typeof logo === 'string' ? (
                                  <img
                                    className="uploads-preview"
                                    src={logo}
                                    alt="logo"
                                  />
                                ) : null}
                              </Form.Field>
                              <Form.Input
                                maxLength="120"
                                label="Fotograf/bildkälla"
                                type="text"
                                name="fotografCaption"
                                value={fotografCaption || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Input
                                maxLength="120"
                                label="Jobbtitel"
                                type="text"
                                name="jobbTitel"
                                value={jobbTitel || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Input
                                maxLength="120"
                                label="Organisation"
                                type="text"
                                name="organisation"
                                value={organisation || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.TextArea
                                maxLength="330"
                                label="Kort beskrivning om deltagaren (max 330 tecken)"
                                name="beskrivning"
                                value={beskrivning || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  this.handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare'
                                  )({ target })
                                }
                              />
                            </fieldset>
                          </Form.Group>
                        );
                      })}

                      {!readOnlySeminarLiveBookings && (
                        <Button
                          secondary
                          size="tiny"
                          content="Lägg till deltagare"
                          icon="plus user"
                          labelPosition="right"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState((prevState) => {
                              const localData = cloneDeep(prevState);

                              const deltagareArray = get(
                                localData,
                                `${stateDataId}.${bookingId}.deltagare`
                              );

                              deltagareArray.push({});

                              set(
                                localData,
                                `${stateDataId}.${bookingId}.deltagare`,
                                deltagareArray
                              );

                              return localData;
                            });
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Row>
                  <p>
                    <WarnLogo />
                    {daysLeftText}
                  </p>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {readOnlySeminarLiveBookings ? (
                      <>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleReadOnlyMode(
                              'readOnlySeminarLiveBookings',
                              bookingId,
                              'stateSeminarLiveBookings'
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        {(!sentInvoice && daysLeft(createAt) > 0) ||
                        this.isAdmin ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              this.deleteFromDatabase({
                                databasePath,
                                data: currentBooking,
                                id: bookingId,
                                reduxPath,
                                stateDataId: 'stateSeminarLiveBookings'
                              })();
                            }}
                            loading={loading}
                            disabled={isDeleteClicked}
                            content="Ta bort bokning"
                            icon={{ name: 'delete', color: 'red' }}
                            basic
                            primary
                            labelPosition="right"
                          />
                        ) : null}
                      </>
                    ) : (
                      <div className="flex no-gap">
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Spara"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleCancel();
                          }}
                          type="button"
                          basic
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  render() {
    const {
      currentUser = '',
      currentUserBookings = '',
      currentUserTillaggBookings = '',
      currentUserSeminars = '',
      currentUserSeminarsLive = '',
      currentUserSponsor = ''
    } = this.props;

    const {
      modalOpen,
      submittingPassword,
      password,
      repeatPassword,
      isPasswordError,
      disableInput,
      passwordError,
      oldPassword,
      isPasswordSuccess,
      loading
    } = this.state;

    if (!currentUser) return <div>Loading...</div>;

    const hasBookings =
      !isEmpty(currentUserBookings) ||
      !isEmpty(currentUserTillaggBookings) ||
      !isEmpty(currentUserSeminars) ||
      !isEmpty(currentUserSeminarsLive) ||
      !isEmpty(currentUserSponsor);

    return (
      <Wrapper>
        <ContentContainer>
          <section className="pink">
            <WhiteBox transparent>
              <h1>
                <ContentEditor
                  inline
                  contentId="page-user-account-mina-bookings-title"
                >
                  Mina bokningar
                </ContentEditor>
              </h1>
              {hasBookings ? (
                <ContentEditor
                  inline
                  contentId="page-user-account-mina-bookings-hasbookings-text"
                >
                  {`<p>För att redigera dina bokningar, klicka på knappen
                    <b>Redigera uppgifter</b> längst ner på varje bokning.</p>`}
                </ContentEditor>
              ) : (
                <ContentEditor
                  inline
                  contentId="page-user-account-mina-bookings-no-bookings-text"
                >
                  {'<p>Du har inga bokningar.</p>'}
                </ContentEditor>
              )}
            </WhiteBox>

            {this.renderCurrentUserBookings(currentUserBookings)}
            {this.renderCurrentUserTillaggBookings(currentUserTillaggBookings)}
            {this.renderSponsorBookings(currentUserSponsor)}
            {this.renderSeminarBookings(currentUserSeminars)}
            {this.renderSeminarLiveBookings(currentUserSeminarsLive)}
          </section>
          {modalOpen && (
            <Modal
              open={modalOpen}
              onClose={this.handleClose}
              size="mini"
              closeOnDimmerClick={false}
              dimmer="inverted"
            >
              <Modal.Content scrolling>
                <WhiteBox>
                  <DataBox>
                    <div>
                      {isPasswordSuccess ? (
                        <Message
                          success
                          positive
                          content="Ditt lösenord har ändrats"
                        />
                      ) : (
                        <Form loading={loading}>
                          <ul aria-label="Bekräfta lösenord">
                            <li style={{ display: 'none' }}>
                              <Form.Input
                                noOutline
                                maxLength="120"
                                type="email"
                                required
                                name="email"
                                autoComplete="username"
                              />
                            </li>
                            <li aria-label="Gammalt lösenord">
                              <Form.Input
                                noOutline
                                maxLength="40"
                                onChange={this.handlePasswordInputChange}
                                value={oldPassword || ''}
                                required
                                type="password"
                                name="oldPassword"
                                autoComplete="password"
                              />
                            </li>
                            <li aria-label="Nytt lösenord">
                              <Form.Input
                                noOutline
                                type="password"
                                name="password"
                                value={password || ''}
                                readOnly={disableInput}
                                onChange={this.handlePasswordInputChange}
                                autoComplete="new-password"
                                required
                              />
                            </li>
                            <li aria-label="Upprepa nytt lösenord">
                              <Form.Input
                                noOutline
                                type="password"
                                name="repeatPassword"
                                autoComplete="new-password"
                                readOnly={disableInput}
                                value={repeatPassword || ''}
                                onChange={this.handlePasswordInputChange}
                                required
                              />
                            </li>
                          </ul>
                        </Form>
                      )}
                    </div>
                    <PasswordError isPasswordError={isPasswordError}>
                      <div>
                        {isPasswordError && (
                          <Message
                            error
                            header={passwordError.code}
                            content={passwordError.message}
                          />
                        )}
                      </div>
                    </PasswordError>
                  </DataBox>
                </WhiteBox>
              </Modal.Content>
              {isPasswordSuccess ? (
                ''
              ) : (
                <Modal.Actions>
                  <div className="flex no-gap">
                    <Button
                      basic
                      primary
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleClose();
                      }}
                      content="Avbryt"
                      icon="cancel"
                      labelPosition="right"
                    />
                    <Button
                      type="submit"
                      loading={loading}
                      primary
                      content="Bekräfta"
                      labelPosition="right"
                      icon="checkmark"
                      onClick={this.changePassword}
                      disabled={
                        password !== repeatPassword ||
                        password === '' ||
                        repeatPassword === '' ||
                        password.length < 6 ||
                        repeatPassword.length < 6 ||
                        oldPassword === '' ||
                        oldPassword.length < 6 ||
                        submittingPassword
                      }
                    />
                  </div>
                </Modal.Actions>
              )}
            </Modal>
          )}
        </ContentContainer>
      </Wrapper>
    );
  }
}

UserBookings.propTypes = {
  firestore: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  currentUserBookings: PropTypes.object,
  currentUserTillaggBookings: PropTypes.object,
  currentUserSeminars: PropTypes.object,
  currentUserSeminarsLive: PropTypes.object,
  currentUserSponsor: PropTypes.object,
  authId: PropTypes.string.isRequired,
  registerState: PropTypes.func,
  isFromUser: PropTypes.bool
};

UserBookings.defaultProps = {
  isFromUser: false
};

// export default UserBookings;
export default withErrorBoundary(
  debounceRender(UserBookings, 100),
  MyFallbackComponent,
  errorCallback
);
