/* eslint-disable no-alert */
/**
 *
 * CreateDatabase
 *
 */
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import debounceRender from 'react-debounce-render';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid, Input } from 'semantic-ui-react';

import MyFallbackComponent from '../../components/MyFallbackComponent';
import { PRODUCTS, STATS } from '../../configs';
import { WhiteBox } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import { saveToServer } from '../../utils/saveToServer';
import { StyledContentContainer } from './styles';

const PASSWORD = 'acc';

function CreateDatabase(props) {
  // console.log('props', props);

  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (password === PASSWORD) {
      setIsDisabled(false);
    } else if (!isDisabled) {
      setIsDisabled(true);
    }
  }, [password]);

  const handleChange = (e, { value }) => setPassword(value);

  const handleSubmit =
    ({ path, data }) =>
    async () => {
      // clearing the password
      setPassword('');

      const { firestore } = props;

      if (
        !(
          path &&
          window.confirm(`Do you want to overrites ${path} in database?`)
        )
      ) {
        return console.info('\n', path, 'upload cancled\n');
      }

      console.info('\n', path, 'saveToServer....\n');

      if (!data) {
        return;
      }

      if (isArray(data)) {
        const promiseArrray = data?.map((item) => {
          const { productId } = item;
          return saveToServer({
            path: `${path}/${productId}`,
            data: item,
            firestore
          });
        });

        await Promise.all(promiseArrray);
        return console.info(path, 'upload finish');
      }

      if (isObject(data)) {
        const promiseArrray = map(data, (item, key) => {
          return saveToServer({
            path: `${path}/${key}`,
            data: item,
            firestore
          });
        });

        await Promise.all(promiseArrray);
        return console.info(path, 'upload finish');
      }
    };

  return (
    <StyledContentContainer>
      <section>
        <WhiteBox>
          <h1>Create Database</h1>

          <Grid celled>
            {/* password to edit the form */}
            <Grid.Row>
              <Grid.Column>
                <Input
                  fluid
                  labelPosition="left"
                  label="Developer Password"
                  value={password}
                  name="password"
                  type="text"
                  placeholder={PASSWORD}
                  onChange={handleChange}
                />
                <p className="note info">**enter password to edit details</p>
              </Grid.Column>
            </Grid.Row>

            {/* Overrites Products */}
            <Grid.Row>
              <Grid.Column>
                <h4>Products</h4>
                <p>Creates products in database</p>

                <details>
                  <summary>Products Data</summary>
                  <pre>{JSON.stringify(PRODUCTS, null, 2)}</pre>
                </details>
                <br />

                <Button
                  icon="upload"
                  primary
                  basic
                  labelPosition="left"
                  type="button"
                  onClick={handleSubmit({
                    path: '/products',
                    data: PRODUCTS
                  })}
                  disabled={isDisabled}
                  content="Update Products"
                />
                <p className="note warn">
                  ** This action will delete and overrite data
                </p>
              </Grid.Column>
            </Grid.Row>

            {/* Overrites Stats */}
            <Grid.Row>
              <Grid.Column>
                <h4>Stats</h4>
                <p>Creates stats in database</p>

                <details>
                  <summary>Stats Data</summary>
                  <pre>{JSON.stringify(STATS, null, 2)}</pre>
                </details>
                <br />

                <Button
                  icon="upload"
                  primary
                  basic
                  labelPosition="left"
                  type="button"
                  onClick={handleSubmit({
                    path: '/stats',
                    data: STATS
                  })}
                  disabled={isDisabled}
                  content="Update Stats"
                />
                <p className="note warn">
                  ** This action will delete and overrite data
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </WhiteBox>
      </section>
    </StyledContentContainer>
  );
}

CreateDatabase.propTypes = {
  firestore: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  collections: (state) => ({
    assets: get(state, 'firestore.data.assets'),
    seminars: get(state, 'firestore.data.seminars'),
    seminarsLive: get(state, 'firestore.data.seminarsLive'),
    products: get(state, 'firestore.data.products')
  })
});

const withConnect = connect(mapStateToProps);

const getFirebaseCollections = () => [
  'assets',
  'seminars',
  'seminarsLive',
  'products'
];

export default compose(
  withConnect,
  firestoreConnect((props) => getFirebaseCollections(props))
)(
  withErrorBoundary(
    debounceRender(CreateDatabase, 100),
    MyFallbackComponent,
    errorCallback
  )
);
