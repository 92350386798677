/**
 *
 * RenderTents
 *
 */
import React, { useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button, Checkbox, Form } from 'semantic-ui-react';

import { getStats } from '../../containers/App/selectors';
import { CardContainer, WhiteBox } from '../../styledComponents';
import { errorCallback, isCurrentCountMax } from '../../utils/index';
import ContentEditor from '../ContentEditor';
import GalleryEditor from '../GalleryEditor';
import { TentLogo } from '../Logos';
import MyFallbackComponent from '../MyFallbackComponent';

function RenderTents(props) {
  const { isFromSponsor, handleNextButtonClick, products, history, stats } =
    props;

  const [state, setState] = useState({});

  // console.log('props', props);
  // console.log('state', state);

  const handleInputChange = (e) => {
    const {
      target: { name, value, type, checked }
    } = e;

    const localValue = type === 'checkbox' ? checked : value;

    setState((prev) => {
      return {
        ...prev,
        [name]: localValue
      };
    });
  };

  const renderTents = () => {
    if (isEmpty(products)) {
      return (
        <WhiteBox>
          <h4>Produkter inte tillgängliga.</h4>
        </WhiteBox>
      );
    }

    return products?.map((item) => {
      const { productId, pris, period1Label, period2Label } = item;

      const disableButton = (stateId) =>
        !(
          get(state, `period1_${stateId}`, '') ||
          get(state, `period2_${stateId}`, '')
        );

      const renderPriceOptions = () => {
        switch (productId) {
          case 'tent5x5': {
            return (
              <>
                <p>
                  <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                </p>
                {/* <i>
                * 10 000kr rabatt vid bokning av båda perioderna (gäller till
                och med 8 april kl 12.00)
              </i> */}
                <h4>Välj period</h4>
                <div>
                  <Form.Field
                    name={`period1_${productId}`}
                    id={`period1_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats
                      })?.label
                    }
                    checked={get(state, `period1_${productId}`, false)}
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats
                      })?.isDisabled
                    }
                  />
                  <Form.Field
                    name={`period2_${productId}`}
                    id={`period2_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats
                      })?.label
                    }
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats
                      })?.isDisabled
                    }
                    checked={get(state, `period2_${productId}`, false)}
                  />
                </div>
              </>
            );
          }

          case 'tent5x2_5': {
            return (
              <>
                <p>
                  <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                </p>
                {/* <i>
                  * 10 000kr rabatt vid bokning av båda perioderna (gäller till
                  och med 8 april kl 12.00)
                </i> */}
                <h4>Välj period</h4>
                <div>
                  <Form.Field
                    name={`period1_${productId}`}
                    id={`period1_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats
                      })?.label
                    }
                    checked={get(state, `period1_${productId}`, false)}
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats
                      })?.isDisabled
                    }
                  />
                  <Form.Field
                    name={`period2_${productId}`}
                    id={`period2_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats
                      })?.label
                    }
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats
                      })?.isDisabled
                    }
                    checked={get(state, `period2_${productId}`, false)}
                  />
                </div>
              </>
            );
          }

          case 'tent2_5x2_5': {
            return (
              <>
                <p>
                  <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                </p>
                {/* <i>
                  * 5000kr rabatt vid bokning av båda perioderna – hela veckan
                  (gäller till och med 1 jan kl 12.00)
                </i> */}
                <h4>Välj period</h4>
                <div>
                  <Form.Field
                    name={`period1_${productId}`}
                    id={`period1_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats
                      })?.label
                    }
                    checked={get(state, `period1_${productId}`, false)}
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats
                      })?.isDisabled
                    }
                  />
                  <Form.Field
                    name={`period2_${productId}`}
                    id={`period2_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats
                      })?.label
                    }
                    checked={get(state, `period2_${productId}`, false)}
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats
                      })?.isDisabled
                    }
                  />
                </div>
              </>
            );
          }

          default:
            return false;
        }
      };

      switch (productId) {
        case 'tent2_5x2_5':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNextButtonClick({
                    ...item,
                    ...state
                  });
                }}
              >
                <CardContainer>
                  <h3 className="flex product-title">
                    <TentLogo />
                    <ContentEditor
                      inline
                      contentId="page-book-tent-product-25-25-title"
                    >
                      {'<span class="no-break">Tältyta 2,5 x 2,5m</span>'}
                    </ContentEditor>
                  </h3>
                  <div className="media-text">
                    <div>
                      <ContentEditor contentId="page-book-tent-product-25-25-details">
                        {`<h4>Tältyta för ideella organisationer med svag ekonomi.</h4>
                        <p>
                          Detta ingår i din bokning av tältyta –
                          <span class="no-break">2,5m x 2,5m</span>
                        </p>
                        <ul>
                          <li>2,5x2,5 yta, (effektiva mått 2470x2470mm)</li>
                          <li>1 ståbord och 2 barstolar</li>
                          <li>
                            Vitmålade mässväggar i trä. På dessa får man fästa skruv, spik och tejp så
                            länge man tar bort det efter eventet.
                          </li>
                          <li>
                            Tältets sidor kan stängas. För att bäst bemöta besökare måste alla sidor
                            vara öppna under öppettiderna.
                          </li>
                          <li>
                            Namnskylt med namn på er organisation - 2500x500 mm varav utställarens
                            skyltyta är 1700x500mm. (Övrig yta används för huvudpartners.)
                          </li>
                          <li>
                            El-anslutning: 240V /10A max garanterad effekt 1000W. I varje tält ingår
                            en dosa med tre uttag
                          </li>
                        </ul>
                        <p class="note">
                          * ritningar och bilder är ungefärliga. Vid bokning av tältyta
                          <b class="no-break">5m x 2,5m</b> eller
                          <b class="no-break">2,5m x 2,5m</b> delar ni tältet med 1, 2 eller 3 andra
                          aktörer med avskiljande väggar mellan. Platstilldelning görs självständigt
                          av Järvaveckan. Önskas större yta så finnas
                          <b class="no-break">5m x 2,5m</b> eller egen tältyta (ett fristående tält)
                          på <b class="no-break">5m x 5m.</b> I sådant fall hör av er till oss på
                          <a href="mailto:info@jarvaveckan.se">info@jarvaveckan.se</a>
                        </p>
                        <div class="ui label ui label note hightlight">
                          2,5x2,5 får bokas av en aktör som betalar hela avgiften men kan användas av
                          max två aktörer - inklusive den som bokar. Endast den aktör som har bokat
                          syns på hemsidan och i övrig kommunikation samt får sin logotyp/namn tryckt
                          på namnskylten. Avvikelse tillåts ej och leder till korrigering samt extra
                          avgift på <span class="no-break">5 000 kr</span>.
                        </div>
                        <h4><span>Ordinarie pris/period</span></h4>
                        `}
                      </ContentEditor>
                      {renderPriceOptions()}
                    </div>
                    <div>
                      <GalleryEditor contentId="gallery-tent2_5x2_5" />
                    </div>
                  </div>
                </CardContainer>
                <Button
                  primary
                  type="submit"
                  disabled={disableButton(productId)}
                  icon="right arrow"
                  labelPosition="right"
                  size="large"
                  floated="right"
                  content="Nästa steg"
                />
              </Form>
            </WhiteBox>
          );

        case 'tent5x2_5':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNextButtonClick({
                    ...item,
                    ...state
                  });
                }}
              >
                <CardContainer>
                  <h3 className="flex product-title">
                    <TentLogo />
                    <ContentEditor
                      inline
                      contentId="page-book-tent-product-5-25-title"
                    >
                      {'<span class="no-break">Tältyta 5 x 2,5m</span>'}
                    </ContentEditor>
                  </h3>
                  <div className="media-text">
                    <div>
                      <ContentEditor contentId="page-book-tent-product-5-25-details">
                        {`<p>Detta ingår i din bokning av Tältyta – 5m x 2,5m</p>
                      <ul>
                        <li>5 x 2,5m yta, (effektiva mått 5000x2470mm)</li>
                        <li>1 ståbord och 2 barstolar</li>
                        <li>
                          Vitmålade mässväggar i trä. På dessa får man fästa
                          skruv, spik och tejp så länge man tar bort det efter
                          eventet.
                        </li>
                        <li>
                          Tältets sidor kan stängas. För att bäst bemöta
                          besökare måste alla sidor vara öppna under
                          öppettiderna.
                        </li>
                        <li>
                          Namnskylt med namn på din organisation - 5000x500 mm
                          varav utställarens skyltyta är 4000x500mm. (Övrig yta
                          används för huvudpartners.)
                        </li>
                        <li>
                          El-anslutning: 240V /10A max garanterad effekt 1000W.
                          I varje tält ingår en dosa med tre uttag
                        </li>
                      </ul>
                      <p className="note">
                        * ritningar och bilder är ungefärliga. Vid bokning av
                        <span className="no-break">
                          tältyta <b>5m x 2,5m</b>
                        </span>
                        delar ni tältet med 1 eller 2 andra aktörer
                        <b>med avskiljande väggar mellan</b>. Platstilldelning
                        görs självständigt av Järvaveckan. Önskas egen tältplats
                        (ett fristående tält) rekommenderas 5x5 tältet. Den går
                        att se och boka i bokningssystemet. Om det inte det
                        stämmer eller ni önskar större yta än 5x5, kontakta oss
                        på
                        <a href="mailto:info@jarvaveckan.se">
                          info@jarvaveckan.se
                        </a>
                      </p>
                      <div className="ui label note hightlight">
                        5x2,5 får bokas av en aktör som betalar hela avgiften
                        men kan användas av max två aktörer - inklusive den som
                        bokar. Endast den aktör som har bokat syns på hemsidan
                        och i övrig kommunikation samt får sin logotyp/namn
                        tryckt på namnskylten. Avvikelse tillåts ej och leder
                        till korrigering samt extra avgift på
                        <span className="no-break">5 000 kr.</span>
                      </div>
                      <h4>
                        <span>Ordinarie pris/period</span>
                      </h4>`}
                      </ContentEditor>

                      {renderPriceOptions()}
                      <br />
                      <br />
                    </div>
                    <div>
                      <GalleryEditor contentId="gallery-tent5x2_5" />
                    </div>
                  </div>
                </CardContainer>
                <Button
                  primary
                  type="submit"
                  icon="right arrow"
                  labelPosition="right"
                  size="large"
                  floated="right"
                  content="Nästa steg"
                  disabled={disableButton(productId)}
                />
              </Form>
            </WhiteBox>
          );

        case 'tent5x5':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNextButtonClick({
                    ...item,
                    ...state
                  });
                }}
              >
                <CardContainer>
                  <h3 className="flex product-title">
                    <TentLogo />
                    <ContentEditor
                      inline
                      contentId="page-book-tent-product-5-5-title"
                    >
                      {'<span className="no-break">Tältplats 5 x 5m</span>'}
                    </ContentEditor>
                  </h3>
                  <div className="media-text">
                    <div>
                      <ContentEditor contentId="page-book-tent-product-5-5-details">
                        {`<p>
                        Detta ingår i din bokning av Tältplats – 5m x 5m. Du
                        äger hela tältet.
                      </p>
                      <ul>
                        <li>5 x 5m yta, (Effektiva mått 5000x4870mm)</li>
                        <li>1 ståbord och 2 barstolar</li>
                        <li>
                          Vitmålade mässväggar i trä. På dessa får man fästa
                          skruv, spik och tejp så länge man tar bort det efter
                          eventet.
                        </li>
                        <li>
                          Tältets sidor kan stängas. För att bäst bemöta
                          besökare måste alla sidor vara öppna under
                          öppettiderna.
                        </li>
                        <li>
                          Namnskylt med namn på din organisation - 5000x500 mm
                          varav utställarens skyltyta är 4000x500mm. (Övrig yta
                          används för huvudpartners.)
                        </li>
                        <li>
                          El-anslutning: 240V /10A max garanterad effekt 1000W.
                          I varje tält ingår en dosa med tre uttag
                        </li>
                      </ul>
                      <p className="note">
                        * ritningar och bilder är ungefärliga. Platstilldelning
                        görs självständigt av Järvaveckan. Om ni önskar större
                        yta än 5x5 kontakta oss på
                        <a href="mailto:info@jarvaveckan.se">
                          info@jarvaveckan.se
                        </a>
                      </p>
                      <div className="ui label note hightlight">
                        5x5 får bokas av en aktör som betalar hela avgiften men
                        kan användas av max två aktörer - inklusive den som
                        bokar. Endast den aktör som har bokat syns på hemsidan
                        och i övrig kommunikation samt får sin logotyp/namn
                        tryckt på namnskylten. Avvikelse tillåts ej och leder
                        till korrigering samt extra avgift på
                        <span className="no-break">5 000 kr.</span>
                      </div>
                      <h4>
                        <span>Ordinarie pris/period</span>
                      </h4>`}
                      </ContentEditor>
                      {renderPriceOptions()}
                      <br />
                      <br />
                      <h4>Mängd tält</h4>
                      <Form.Group inline>
                        <Form.Field
                          control="input"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min={1}
                          max={5}
                          onChange={handleInputChange}
                          value={get(state, `quantity_${productId}`, 1)}
                          name={`quantity_${productId}`}
                        />
                        <Form.Field
                          name={`bredvid_${productId}`}
                          id={`bredvid_${productId}`}
                          control={Checkbox}
                          onChange={handleInputChange}
                          label="Våra tält ska stå bredvid varandra"
                          checked={get(state, `bredvid_${productId}`, false)}
                          disabled={
                            get(state, `quantity_${productId}`, '') <= 1
                          }
                        />
                      </Form.Group>
                    </div>
                    <div>
                      <GalleryEditor contentId="gallery-tent5x5" />
                    </div>
                  </div>
                </CardContainer>
                <Button
                  primary
                  type="submit"
                  icon="right arrow"
                  labelPosition="right"
                  size="large"
                  floated="right"
                  content="Nästa steg"
                  disabled={disableButton(productId)}
                />
              </Form>
            </WhiteBox>
          );

        case 'sponsor':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <CardContainer>
                <h3 className="flex product-title">
                  <TentLogo />
                  <ContentEditor
                    inline
                    contentId="page-book-tent-product-partnerskap-title"
                  >
                    Stödorganisation
                  </ContentEditor>
                </h3>
                <div className="media-text">
                  <div>
                    <ContentEditor contentId="page-book-tent-product-partnerskap-details">
                      {`
                    <p>
                      Det finns tre typer av partnerskap på Järvaveckan:
                      <b>Stödorganisation, Partner</b> och <b>Partner</b>.
                      Partnerskapet börjar med stödorganisation. Kontakta oss
                      för att bli partner och/eller huvudpartner på
                      <a href="mailto:info@jarvaveckan.se">
                        info@jarvaveckan.se
                      </a>
                    </p>

                    <h4>
                      <span>
                        I partnerskapspaketet – <b>Stödorganisation</b> – ingår
                      </span>
                    </h4>

                    <ol>
                      <li>
                        <b>
                          Tältyta i form av tält (5 x 5 meter) centralt placerat
                          på området som kan disponeras efter behov. (effektiva
                          mått 5000x4870mm)
                        </b>
                        <ul>
                          <li>Plastgolv 5x5m</li>
                          <li>2 ståbord och 2 barstolar</li>
                          <li>
                            Vitmålade mässväggar i trä. På dessa får man fästa
                            skruv, spik och tejp så länge man tar bort det efter
                            eventet.
                          </li>
                          <li>
                            Tältets sidor kan stängas. För att bäst bemöta
                            besökare måste sidorna (minimum 2st) vara öppna
                            under öppettiderna.
                          </li>
                          <li>
                            Namnskylt med namn på din organisation - 5000x500 mm
                          </li>
                          <li>
                            El-anslutning: 240V /10A max garanterad effekt
                            1000W. I varje tält ingår en dosa med tre uttag
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <b>Varumärkes exponering</b> i form av logotyp i samband
                        med marknadsföringen
                        <ul>
                          <li>Jarvaveckan.se</li>
                          <li>Grafiskt material digitalt likväl i print</li>
                          <li>
                            Vid huvudscens front samt vid entré till området
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <b>Diplomutdelning på huvudscenen</b>
                        <ul>
                          <li>Presentation och diplomutdelning.</li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <b>Återrapportering</b>
                        <ul>
                          <li>
                            Resultat levereras i form av en rapport samt en
                            kortfilm senast den 30 september 2024.
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <b>Partnerträff</b> under hösten 2024 inför Järvaveckan
                        2024.
                      </li>
                      <li>
                        <b>Associationsrätt</b> rätt till att använda
                        ”Järvaveckan” i företagets kommunikation.
                      </li>
                    </ol>

                    <h4>
                      <span>Ordinarie pris/period</span>
                    </h4>
                    `}
                    </ContentEditor>

                    <p>
                      <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                    </p>
                  </div>
                  <div>
                    <GalleryEditor contentId="gallery-sponsor" />
                  </div>
                </div>
              </CardContainer>
              {isFromSponsor ? (
                <Button
                  primary
                  onClick={() =>
                    handleNextButtonClick({
                      ...item,
                      ...state
                    })
                  }
                  floated="right"
                  labelPosition="right"
                  size="large"
                  icon="arrow right"
                  content={
                    isCurrentCountMax({
                      productId,
                      fieldLabel: 'Nästa Steg',
                      stats
                    })?.label
                  }
                  disabled={
                    isCurrentCountMax({
                      productId,
                      fieldLabel: 'Nästa Steg',
                      stats
                    })?.isDisabled
                  }
                />
              ) : (
                <>
                  <br />
                  <br />
                  <Button
                    primary
                    size="large"
                    floated="right"
                    icon="right arrow"
                    labelPosition="right"
                    content="Läs mer om att bli partner"
                    onClick={() => {
                      history.push('/user/book/sponsor');
                    }}
                  />
                </>
              )}
            </WhiteBox>
          );

        case 'sponsor2':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <CardContainer>
                <h3 className="flex product-title">
                  <TentLogo />
                  <ContentEditor
                    inline
                    contentId="page-book-tent-product-partnerskap2-title"
                  >
                    Partner
                  </ContentEditor>
                </h3>
                <div className="media-text">
                  <div>
                    <ContentEditor contentId="page-book-tent-product-partnerskap2-details">
                      {`
                    <p>
                      Det finns tre typer av partnerskap på Järvaveckan:
                      <b>Stödorganisation, Partner</b> och <b>Partner</b>.
                      Partnerskapet börjar med stödorganisation. Kontakta oss
                      för att bli partner och/eller huvudpartner på
                      <a href="mailto:info@jarvaveckan.se">
                        info@jarvaveckan.se
                      </a>
                    </p>

                    <h4>
                      <span>
                        I partnerskapspaketet – <b>Stödorganisation</b> – ingår
                      </span>
                    </h4>

                    <ol>
                      <li>
                        <b>
                          Tältyta i form av tält (5 x 5 meter) centralt placerat
                          på området som kan disponeras efter behov. (effektiva
                          mått 5000x4870mm)
                        </b>
                        <ul>
                          <li>Plastgolv 5x5m</li>
                          <li>2 ståbord och 2 barstolar</li>
                          <li>
                            Vitmålade mässväggar i trä. På dessa får man fästa
                            skruv, spik och tejp så länge man tar bort det efter
                            eventet.
                          </li>
                          <li>
                            Tältets sidor kan stängas. För att bäst bemöta
                            besökare måste sidorna (minimum 2st) vara öppna
                            under öppettiderna.
                          </li>
                          <li>
                            Namnskylt med namn på din organisation - 5000x500 mm
                          </li>
                          <li>
                            El-anslutning: 240V /10A max garanterad effekt
                            1000W. I varje tält ingår en dosa med tre uttag
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <b>Varumärkes exponering</b> i form av logotyp i samband
                        med marknadsföringen
                        <ul>
                          <li>Jarvaveckan.se</li>
                          <li>Grafiskt material digitalt likväl i print</li>
                          <li>
                            Vid huvudscens front samt vid entré till området
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <b>Diplomutdelning på huvudscenen</b>
                        <ul>
                          <li>Presentation och diplomutdelning.</li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <b>Återrapportering</b>
                        <ul>
                          <li>
                            Resultat levereras i form av en rapport samt en
                            kortfilm senast den 30 september 2024.
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <b>Partnerträff</b> under hösten 2024 inför Järvaveckan
                        2024.
                      </li>
                      <li>
                        <b>Associationsrätt</b> rätt till att använda
                        ”Järvaveckan” i företagets kommunikation.
                      </li>
                    </ol>

                    <h4>
                      <span>Ordinarie pris/period</span>
                    </h4>
                    `}
                    </ContentEditor>

                    <p>
                      <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                    </p>
                  </div>
                  <div>
                    <GalleryEditor contentId="gallery-sponsor2" />
                  </div>
                </div>
              </CardContainer>
              {isFromSponsor ? (
                <Button
                  primary
                  onClick={() =>
                    handleNextButtonClick({
                      ...item,
                      ...state
                    })
                  }
                  floated="right"
                  labelPosition="right"
                  size="large"
                  icon="arrow right"
                  content={
                    isCurrentCountMax({
                      productId,
                      fieldLabel: 'Nästa Steg',
                      stats
                    })?.label
                  }
                  disabled={
                    isCurrentCountMax({
                      productId,
                      fieldLabel: 'Nästa Steg',
                      stats
                    })?.isDisabled
                  }
                />
              ) : (
                <>
                  <br />
                  <br />
                  <Button
                    primary
                    size="large"
                    floated="right"
                    icon="right arrow"
                    labelPosition="right"
                    content="Läs mer om att bli partner"
                    onClick={() => {
                      history.push('/user/book/sponsor');
                    }}
                  />
                </>
              )}
            </WhiteBox>
          );

        default:
          return false;
      }
    });
  };
  return <>{renderTents()}</>;
}

RenderTents.propTypes = {
  handleNextButtonClick: PropTypes.func,
  products: PropTypes.array,
  history: PropTypes.object,
  stats: PropTypes.object,
  isFromSponsor: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  stats: getStats
});

const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withConnect
)(withErrorBoundary(RenderTents, MyFallbackComponent, errorCallback));
