/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/**
 *
 * SeminarLive
 *
 */
import React, { Component } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Form, Icon, Label, Modal } from 'semantic-ui-react';

import { uploadFileToStorage } from '../../api';
import BookingConfirm from '../../components/BookingConfirm';
import BookingInformationNote from '../../components/BookingInformationNote';
import ContentEditor from '../../components/ContentEditor';
import GalleryEditor from '../../components/GalleryEditor';
import { SeminarLogo } from '../../components/Logos';
import MyFallbackComponent from '../../components/MyFallbackComponent';
// common logic
import RenderUserDetails from '../../components/RenderUserDetails';
import TermsCheckBox from '../../components/TermsCheckBox';
import TextCopy from '../../components/TextCopy/TextCopy';
import {
  AMNE_OPTIONS,
  SPRAK_OPTIONS,
  TILLGANGLIGHET_OPTIONS,
  MAX_SELECT,
  OPTIONS_ARRAY_TO_LIMIT,
  EVENT_DAYS_ARRAY
} from '../../configs';
import {
  CardContainer,
  ContentContainer,
  DataBox,
  WhiteBox
} from '../../styledComponents';
import generateKey from '../../utils/generateKey';
import { errorCallback, scrollToTop } from '../../utils/index';
// common logic
import RenderBookingSection from '../Seminar/RenderBookingSection';
import { CoArrangeContainer, GalleryContainer } from '../Seminar/SeminarStyles';

export class SeminarLive extends Component {
  // #region state
  state = {
    userSelections: [],
    userBookings: {},
    step: 0,
    error: false,
    modalOpen: false,
    submitting: false
  };
  // #endregion

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  // #region handleSubmit
  handleSubmit = async () => {
    this.setState({ submitting: true });
    const { firestore, authId, profile, firebase } = this.props;

    const { days } = get(this, 'props.products.seminarsLive', {});
    const organizerName = get(profile, 'orgName') || '';

    // state
    const { userBookings } = this.state;

    if (!userBookings) {
      console.warn('seminar bookings empty');
      this.setState({ submitting: false });
      return;
    }

    const bookingKeys = Object.keys(userBookings);

    for (const key of bookingKeys) {
      const booking = userBookings[key];

      if (!booking) {
        console.warn('seminarLive bookings empty');
        this.setState({ submitting: false });
        return;
      }

      const {
        coArrangers,
        deltagare,
        logo,
        moderatorBild,
        kontaktProfilbild,
        kontaktProfilbild2,
        day
      } = booking;

      const removeFields = [
        'coArrangers',
        'deltagare',
        'createAt',
        'updateAt',
        'moderatorBild',
        'kontaktProfilbild',
        'kontaktProfilbild2',
        'logo'
      ];

      const selectedBookingData = omit(booking, removeFields);

      const bookingData = {
        ...selectedBookingData,
        userId: authId,
        organizerName: organizerName || '',
        collection: 'seminarLiveBookings',
        ...get(this, 'props.products.seminarsLive'),
        sentInvoice: false,
        dayString: days[day],
        createAt: firestore.FieldValue.serverTimestamp()
      };

      // add to booking data
      const coArrangersFiltered = coArrangers?.filter(Boolean);
      bookingData.coArrangers = coArrangersFiltered;

      // upload all files if exists
      const deltagareFiltered = [];
      for (const item of deltagare) {
        if (!isEmpty(item)) {
          const { logo: itemLogo, ...restItem } = item || {};

          const newDeltagare = {
            ...restItem
          };

          let downloadUrl = '';
          if (itemLogo) {
            downloadUrl = await uploadFileToStorage({
              fileList: itemLogo,
              uid: authId,
              firebase
            });
          }

          newDeltagare.logo = downloadUrl || '';

          deltagareFiltered.push(newDeltagare);
        }
      }

      // add to booking data
      bookingData.deltagare = deltagareFiltered?.filter(Boolean);

      if (moderatorBild) {
        const downloadUrl = await uploadFileToStorage({
          fileList: moderatorBild,
          uid: authId,
          firebase
        });

        bookingData.moderatorBild = downloadUrl || '';
      }

      if (kontaktProfilbild) {
        const downloadUrl = await uploadFileToStorage({
          fileList: kontaktProfilbild,
          uid: authId,
          firebase
        });

        bookingData.kontaktProfilbild = downloadUrl || '';
      }

      if (kontaktProfilbild2) {
        const downloadUrl = await uploadFileToStorage({
          fileList: kontaktProfilbild2,
          uid: authId,
          firebase
        });

        bookingData.kontaktProfilbild2 = downloadUrl || '';
      }

      if (logo) {
        const downloadUrl = await uploadFileToStorage({
          fileList: logo,
          uid: authId,
          firebase
        });

        bookingData.logo = downloadUrl || '';
      }

      await firestore
        .add(
          {
            collection: 'seminarLiveBookings'
          },
          bookingData
        )
        .then(() => {
          this.setState({ step: 2, submitting: false });
          console.info('saved to database');
        })
        .catch((error) => {
          console.error('seminarLiveBookings booking error error', error);
        })
        .finally(() => {
          this.setState({ submitting: false });
          firestore
            .get({
              collection: 'seminarLiveBookings',
              path: 'currentUserSeminarsLiveBookings',
              where: ['userId', '==', authId]
            })
            .catch((err) => console.error(err));
        });
    }
  };
  // #endregion

  handleInputChange = (slotId) => (event, semanticEvent) => {
    const { files } = event.target || {};

    const { name, value } = semanticEvent;

    if (OPTIONS_ARRAY_TO_LIMIT.includes(name)) {
      if (value?.length > MAX_SELECT) {
        return alert('Max 5 val');
      }
    }

    this.setState((prev) => {
      const updatedUserBookings = prev.userBookings[slotId];
      updatedUserBookings[name] = files || value;

      return {
        userBookings: { ...prev.userBookings, [slotId]: updatedUserBookings }
      };
    });
  };

  handleCheckboxChange = (_, { name, value, type, checked }) => {
    const localValue = type === 'checkbox' ? checked : value;
    this.setState({ [name]: localValue });
  };

  handleCoArrangeChange =
    ({ slotId, index }) =>
    (event) => {
      const {
        target: { value, files, name }
      } = event;

      this.setState((prev) => {
        const updatedUserBookings = prev.userBookings[slotId];
        updatedUserBookings.coArrangers[index] = {
          ...updatedUserBookings.coArrangers[index],
          [name]: files || value
        };

        return {
          userBookings: { ...prev.userBookings, [slotId]: updatedUserBookings }
        };
      });
    };

  handleDeltagareChange =
    ({ slotId, index }) =>
    (event) => {
      const {
        target: { value, files, name }
      } = event;

      this.setState((prevState) => {
        const newUserBookings = prevState.userBookings[slotId];
        newUserBookings.deltagare[index] = {
          ...newUserBookings.deltagare[index],
          [name]: files || value
        };

        return {
          userBookings: {
            ...prevState.userBookings,
            [slotId]: newUserBookings
          }
        };
      });
    };

  // #region handleUserSelection
  handleUserSelection = (day, slot) => {
    this.setState((prevState) => {
      const prev = prevState.userSelections;
      let isBooked = false;
      const result = prev?.filter((booking) => {
        const hasBooking = booking.day === day && booking.timeSlot === slot;
        if (hasBooking) {
          isBooked = true;
        }
        return !hasBooking;
      });
      if (!isBooked) {
        result.push({
          day,
          timeSlot: slot,
          deltagare: [''],
          coArrangers: [],
          slotId: generateKey()
        });
      }
      return {
        userSelections: result
      };
    });
  };
  // #endregion

  createBookings = () => {
    //  Create object from selection array
    this.setState((prev) => ({
      userBookings: reduce(
        prev.userSelections,
        (obj, param) => {
          const ob = obj;
          ob[param.slotId] = param;
          return ob;
        },
        {}
      ),
      step: 1
    }));
  };

  // #region makeSeminarOptions
  makeSeminarOptions = (optionDay) => {
    const { userSelections } = this.state;
    const { liveSeminars, stats } = this.props;

    const timeSlots = get(this, 'props.products.seminarsLive.timeSlots', []);
    const availableTimeSlots = get(
      stats,
      'seminarsLive.availableTimeSlots',
      []
    );

    const seminarArray = !liveSeminars ? [] : Object.values(liveSeminars);
    const bookings = seminarArray?.filter((o) => get(o, 'day') === optionDay);

    const bookingsPerSlot = timeSlots?.map((slot) => {
      const slotBookings = [];
      bookings.forEach((booking) => {
        if (booking.timeSlot === slot) {
          slotBookings.push(booking);
        }
      });
      return slotBookings;
    });

    return bookingsPerSlot?.map((slotBookings, index) => {
      const userBookings = userSelections?.filter(
        ({ day, timeSlot }) =>
          day === optionDay && timeSlot === timeSlots[index]
      );

      const indexToDayKey = EVENT_DAYS_ARRAY[optionDay];
      const slotArray = get(availableTimeSlots, `[${indexToDayKey}][${index}]`);

      return (
        <button
          type="button"
          key={index}
          className={
            (slotBookings.length >= slotArray ? 'select full' : 'select') +
            (userBookings.length > 0 ? ' user-selected' : '')
          }
          onClick={() => this.handleUserSelection(optionDay, timeSlots[index])}
          disabled={slotBookings.length > slotArray}
        >
          {timeSlots[index]}{' '}
          <span style={{ display: 'inline' }}>
            ({slotBookings.length}/{slotArray})
          </span>
        </button>
      );
    });
  };
  // #endregion

  // #region bookSection
  bookSection = (slotId, index) => {
    const { userBookings } = this.state;
    const { days, pris } = get(this, 'props.products.seminarsLive', {});
    const selection = userBookings[slotId];
    // this.state.userBookings[slotId]

    return (
      <div key={selection.slotId}>
        <WhiteBox transparent>
          <h2>
            <ContentEditor
              inline
              contentId="page-book-seminarie-live-step-2-title"
            >
              Information om ditt seminarie live
            </ContentEditor>{' '}
            #{index + 1}
          </h2>
          <ContentEditor contentId="page-book-seminarie-live-step-2-info">
            Här kan ni fylla i information kring era aktiviteter på Järvaveckan
            2024. För att säkra er plats och genomföra bokningen behöver ni
            inledningsvis enbart fylla i Namn på seminariet.
          </ContentEditor>
          <br />
          <p>
            <b>Tid: </b>
            {days[selection.day]}, {selection.timeSlot}
            <br />
            <b>Pris: </b> {Number(pris).toLocaleString('sv')} kr/h exkl moms
          </p>
          <BookingInformationNote />
        </WhiteBox>
        <WhiteBox>
          <DataBox>
            {/* section */}
            <div className="list-container">
              <ul
                aria-label="Uppgifter för seminariet"
                style={{ maxWidth: '50%' }}
              >
                <li>
                  <Form.Input
                    maxLength="90"
                    onChange={this.handleInputChange(slotId)}
                    label="Namn på seminariet (max 90 tecken inkl. blanksteg)"
                    id="Namn"
                    name="namn"
                    required
                    autoComplete="off"
                  />
                  <Form.TextArea
                    onChange={this.handleInputChange(slotId)}
                    id="beskriv"
                    name="beskriv"
                    label="Beskrivning av seminariet (max 800 tecken inkl. blanksteg) "
                    maxLength="800"
                  />
                  <br />
                  <br />
                  <h5>
                    Välj de ämnen, språk och tillgänglighetsanpassningar som
                    passar in på ert seminarium. det går att välja flera
                    alternativ.
                  </h5>
                  <Form.Select
                    multiple
                    label="Ämne"
                    onChange={this.handleInputChange(slotId)}
                    value={
                      get(this, `state.userBookings[${slotId}].amne`) || []
                    }
                    id="amne"
                    name="amne"
                    options={AMNE_OPTIONS}
                    placeholder="välj ett eller flera"
                  />
                  <Form.Select
                    label="Tillgänglighet"
                    onChange={this.handleInputChange(slotId)}
                    id="tillganglighet"
                    name="tillganglighet"
                    value={
                      get(
                        this,
                        `state.userBookings[${slotId}].tillganglighet`
                      ) || []
                    }
                    multiple
                    options={TILLGANGLIGHET_OPTIONS}
                    placeholder="välj"
                  />
                  <Form.Select
                    label="Språk"
                    multiple
                    onChange={this.handleInputChange(slotId)}
                    id="sprak"
                    name="sprak"
                    value={
                      get(this, `state.userBookings[${slotId}].sprak`) || []
                    }
                    options={SPRAK_OPTIONS}
                    placeholder="välj"
                  />
                </li>
                <li>
                  <h5>
                    Extern länk för anmälan via exempelvis Eventbrite eller
                    Invajo.
                  </h5>
                  <Form.Input
                    onChange={this.handleInputChange(slotId)}
                    id="reservationSiteLink"
                    name="reservationSiteLink"
                    // value={reservationSiteLink || ''}
                    placeholder="https://accomplice.se"
                    label="Fyller du i detta fält
                      så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                      den länk du fyller i."
                  />
                </li>
                <li>
                  <h5>Moderator</h5>
                  <fieldset>
                    <legend>Moderator</legend>
                    <Form.Input
                      maxLength="120"
                      onChange={this.handleInputChange(slotId)}
                      id="moderator"
                      name="moderator"
                      // value={moderator || ''}
                      label="Namn på moderator"
                    />
                    <Form.Input
                      maxLength="120"
                      // readOnly={readOnlySeminarLiveBookings}
                      onChange={this.handleInputChange(slotId)}
                      id="moderatorJobbtitel"
                      name="moderatorJobbtitel"
                      // value={moderatorJobbtitel || ''}
                      label="Jobbtitel"
                    />
                    <Form.Input
                      maxLength="120"
                      // readOnly={readOnlySeminarLiveBookings}
                      onChange={this.handleInputChange(slotId)}
                      id="moderatorOrganisation"
                      name="moderatorOrganisation"
                      // value={moderatorOrganisation || ''}
                      label="Organisation"
                    />
                    <Form.TextArea
                      maxLength="330"
                      onChange={this.handleInputChange(slotId)}
                      id="moderatorBio"
                      name="moderatorBio"
                      // value={moderatorBio || ''}
                      label="Kort bio (max 330 tecken inkl. blanksteg)"
                    />
                    <Form.Input
                      type="file"
                      label="Lägg upp profilbild (* allowed: jpg, jpeg, png, svg - max size 5MB)"
                      id="moderatorBild"
                      name="moderatorBild"
                      accept="image/*,.svg"
                      onChange={this.handleInputChange(slotId)}
                    />
                    <Form.Input
                      maxLength="120"
                      // readOnly={readOnlySeminarLiveBookings}
                      onChange={this.handleInputChange(slotId)}
                      id="moderatorFotograf"
                      name="moderatorFotograf"
                      // value={moderatorFotograf || ''}
                      label="Fotograf/bildkälla"
                    />
                  </fieldset>
                </li>
                <li>
                  <h5>KONTAKTPERSONER FÖR SEMINARIET</h5>
                  <Label className="info">
                    Kontaktinfo (email & tel) kommer synas på vår hemsida
                    offentligt!
                  </Label>
                  {/* contack 1 */}
                  <fieldset>
                    <legend>Kontaktperson: 1</legend>
                    <Form.Input
                      maxLength="120"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktperson"
                      name="kontaktperson"
                      // value={kontaktperson || ''}
                      label="Förnamn och efternamn"
                    />
                    <Form.Input
                      maxLength="120"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktJobbtitel"
                      name="kontaktJobbtitel"
                      // value={kontaktJobbtitel || ''}
                      label="Jobbtitel"
                    />
                    <Form.Input
                      maxLength="120"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktOrganisation"
                      name="kontaktOrganisation"
                      // value={kontaktOrganisation || ''}
                      label="Organisation"
                    />
                    {/* <Form.Input
                      type="file"
                      label="Lägg upp profilbild (valfritt) (* allowed: jpg, jpeg, png, svg - max size 5MB)"
                      id="kontaktProfilbild"
                      name="kontaktProfilbild"
                      accept="image/*,.svg"
                      onChange={this.handleInputChange(slotId)}
                    />
                    <Form.Input
                      maxLength="120"

                      onChange={this.handleInputChange(slotId)}
                      id="kontaktFotograf"
                      name="kontaktFotograf"
                      // value={kontaktFotograf || ''}
                      label="Fotograf/bildkälla"
                    />
                    <Form.TextArea
                      maxLength="330"

                      onChange={this.handleInputChange(slotId)}
                      id="kontaktKortbio"
                      name="kontaktKortbio"
                      // value={kontaktKortbio || ''}
                      label="Kort bio (max 330 tecken inkl. blanksteg)"
                    /> */}
                    <Form.Input
                      maxLength="140"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktEmail"
                      name="kontaktEmail"
                      label="Email"
                      type="email"
                      // value={kontaktEmail || ''}
                      placeholder="name@mail.com"
                    />
                    <Form.Input
                      maxLength="140"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktTel"
                      name="kontaktTel"
                      label="Tel"
                      type="tel"
                      // value={kontaktTel || ''}
                      placeholder="+46123456789"
                    />
                  </fieldset>
                  {/* contack 2 */}
                  <fieldset>
                    <legend>Kontaktperson: 2</legend>
                    <Form.Input
                      maxLength="120"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktperson2"
                      name="kontaktperson2"
                      // value={kontaktperson2 || ''}
                      label="Förnamn och efternamn"
                    />
                    <Form.Input
                      maxLength="120"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktJobbtitel2"
                      name="kontaktJobbtitel2"
                      // value={kontaktJobbtitel2 || ''}
                      label="Jobbtitel"
                    />
                    <Form.Input
                      maxLength="120"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktOrganisation2"
                      name="kontaktOrganisation2"
                      // value={kontaktOrganisation2 || ''}
                      label="Organisation"
                    />
                    {/* <Form.Input
                      type="file"
                      label="Lägg upp profilbild (valfritt) (* allowed: jpg, jpeg, png, svg - max size 5MB)"
                      id="kontaktProfilbild2"
                      name="kontaktProfilbild2"
                      accept="image/*,.svg"
                      onChange={this.handleInputChange(slotId)}
                    />
                    <Form.Input
                      maxLength="120"

                      onChange={this.handleInputChange(slotId)}
                      id="kontaktFotograf2"
                      name="kontaktFotograf2"
                      // value={kontaktFotograf2 || ''}
                      label="Fotograf/bildkälla"
                    />
                    <Form.TextArea
                      maxLength="330"

                      onChange={this.handleInputChange(slotId)}
                      id="kontaktKortbio2"
                      name="kontaktKortbio2"
                      // value={kontaktKortbio2 || ''}
                      label="Kort bio (max 330 tecken inkl. blanksteg)"
                    /> */}
                    <Form.Input
                      maxLength="140"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktEmail2"
                      name="kontaktEmail2"
                      label="Email"
                      type="email"
                      // value={kontaktEmail2 || ''}
                      placeholder="name@mail.com"
                    />
                    <Form.Input
                      maxLength="140"
                      onChange={this.handleInputChange(slotId)}
                      id="kontaktTel2"
                      name="kontaktTel2"
                      label="Tel"
                      type="tel"
                      // value={kontaktTel2 || ''}
                      placeholder="+46123456789"
                    />
                  </fieldset>
                </li>
              </ul>
              <ul aria-label="Paneldeltagare" style={{ maxWidth: '50%' }}>
                <li>
                  {selection.deltagare?.map((field, i) => (
                    <Form.Group key={i} grouped>
                      <fieldset>
                        <legend>Paneldeltagare {`${i + 1}`}</legend>
                        <div style={{ textAlign: 'right' }}>
                          <Icon
                            name="trash alternate outline"
                            color="red"
                            size="large"
                            link
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState((prev) => {
                                const localStateCopy =
                                  prev.userBookings[slotId];

                                // delete localStateCopy.deltagare[i];

                                localStateCopy.deltagare.splice(i, 1);

                                return {
                                  userBookings: {
                                    ...prev.userBookings,
                                    [slotId]: localStateCopy
                                  }
                                };
                              });
                            }}
                          />
                        </div>

                        <Form.Input
                          maxLength="120"
                          onChange={this.handleDeltagareChange({
                            slotId,
                            index: i
                          })}
                          label="Namn på deltagare"
                          type="text"
                          name="deltagare"
                          autoComplete="off"
                        />
                        <Form.Input
                          type="file"
                          id="logo"
                          label="Lägg upp profilbild (* allowed: jpg, jpeg, png, svg - max size 5MB)"
                          name="logo"
                          accept="image/*,.svg"
                          onChange={this.handleDeltagareChange({
                            slotId,
                            index: i
                          })}
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={this.handleDeltagareChange({
                            slotId,
                            index: i
                          })}
                          label="Fotograf/bildkälla"
                          type="text"
                          name="fotografCaption"
                          autoComplete="off"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={this.handleDeltagareChange({
                            slotId,
                            index: i
                          })}
                          label="Jobbtitel"
                          type="text"
                          name="jobbTitel"
                          autoComplete="off"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={this.handleDeltagareChange({
                            slotId,
                            index: i
                          })}
                          label="Organisation"
                          type="text"
                          name="organisation"
                          autoComplete="off"
                        />
                        <Form.TextArea
                          maxLength="330"
                          onChange={this.handleDeltagareChange({
                            slotId,
                            index: i
                          })}
                          label="Kort beskrivning om deltagaren (max 330 tecken)"
                          name="beskrivning"
                        />
                      </fieldset>
                    </Form.Group>
                  ))}
                  <Button
                    secondary
                    size="tiny"
                    content="Lägg till deltagare"
                    icon="plus user"
                    labelPosition="right"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState((prevState) => {
                        const newUserBookings = prevState.userBookings[slotId];
                        newUserBookings.deltagare.push('');
                        return {
                          userBookings: {
                            ...prevState.userBookings,
                            [slotId]: newUserBookings
                          }
                        };
                      });
                    }}
                  />
                </li>
              </ul>
            </div>

            {/* section */}
            <div className="list-container">
              <ul style={{ maxWidth: '66%' }}>
                <li>
                  <TextCopy name="medarrangor-text-copy" />
                </li>
                <li>
                  {selection.coArrangers?.map((field, i) => (
                    <fieldset key={i}>
                      <legend>Medarrangör</legend>
                      <CoArrangeContainer>
                        <div style={{ textAlign: 'right' }}>
                          <Icon
                            name="trash alternate outline"
                            color="red"
                            size="large"
                            link
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState((prev) => {
                                const newUserBookings =
                                  prev.userBookings[slotId];
                                // delete newUserBookings.coArrangers[i];
                                newUserBookings.coArrangers.splice(i, 1);
                                return {
                                  userBookings: {
                                    ...prev.userBookings,
                                    [slotId]: newUserBookings
                                  }
                                };
                              });
                            }}
                          />
                        </div>
                        <Form.Input
                          maxLength="120"
                          onChange={this.handleCoArrangeChange({
                            slotId,
                            index: i
                          })}
                          name="organisationsnamn"
                          label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                          type="text"
                          autoComplete="off"
                        />
                        <Form.Input
                          maxLength="20"
                          label="Organisationsnummer (i formatet 802481–1658)"
                          // pattern="[0-9]{6}-[0-9]{4}"
                          placeholder="802481–1658"
                          name="organisationsnummer"
                          onChange={this.handleCoArrangeChange({
                            slotId,
                            index: i
                          })}
                          type="text"
                          autoComplete="off"
                        />
                        <Form.Input
                          maxLength="160"
                          onChange={this.handleCoArrangeChange({
                            slotId,
                            index: i
                          })}
                          label="Hemsida (max 160 tecken inkl. blanksteg)"
                          name="hemsida"
                          type="text"
                          autoComplete="off"
                        />
                      </CoArrangeContainer>
                    </fieldset>
                  ))}
                  <Button
                    secondary
                    size="tiny"
                    icon="plus user"
                    labelPosition="right"
                    content="Lägg till medarrangör"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState((prevState) => {
                        const newUserBookings = prevState.userBookings[slotId];
                        newUserBookings.coArrangers.push({});
                        return {
                          userBookings: {
                            ...prevState.userBookings,
                            [slotId]: newUserBookings
                          }
                        };
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
          </DataBox>
        </WhiteBox>
      </div>
    );
  };
  // #endregion

  // #region renderSteps
  renderSteps = () => {
    if (!get(this, 'props.products.seminarsLive', false)) return;
    const { history, firestore, currentUser, authId } = this.props;

    const { days, pris } = get(this, 'props.products.seminarsLive', {});

    const {
      userSelections,
      userBookings,
      step,
      submitting,
      error,
      modalOpen,
      godkannKopvillkorOchBekraftelse,
      godkannJarvaVekanKod
    } = this.state;

    switch (step) {
      case 2:
        return (
          <section>
            <BookingConfirm history={history} />
          </section>
        );
      case 1:
        return (
          <section className="pink">
            <Form
              onSubmit={this.handleOpen}
              loading={submitting}
              error={Boolean(error)}
            >
              {Object.keys(userBookings)?.map(this.bookSection)}
              <WhiteBox transparent>
                <Button
                  size="large"
                  type="button"
                  icon="arrow left"
                  basic
                  primary
                  content="Tillbaka"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      step: 0
                    });
                  }}
                />
                <Button
                  primary
                  type="submit"
                  icon="checkmark"
                  labelPosition="right"
                  size="large"
                  floated="right"
                  content="Slutför din bokning"
                  disabled={submitting}
                  loading={submitting}
                />
              </WhiteBox>

              {modalOpen && (
                <Modal
                  open={modalOpen}
                  onClose={this.handleClose}
                  size="tiny"
                  closeOnDimmerClick={false}
                  dimmer="inverted"
                >
                  <Modal.Content>
                    <WhiteBox>
                      <DataBox>
                        <div>
                          <ul aria-label="Vänligen bekräfta dina uppgifter">
                            <li>
                              <TextCopy name="confirm-your-details" />
                            </li>
                          </ul>
                        </div>
                        {map(userBookings, (o) => {
                          const { namn = '', slotId, day, timeSlot } = o;

                          return (
                            <div key={slotId}>
                              <ul aria-label={`Bekräfta bokning ${namn}`}>
                                <li aria-label="Namn">
                                  <span className="form-like">{namn}</span>
                                </li>
                                <li aria-label="Tid">
                                  <span className="form-like">{`${days[day]}, ${timeSlot}`}</span>
                                </li>
                                <li aria-label="Pris">
                                  <span className="form-like">
                                    {Number(pris).toLocaleString('sv')} kr exkl.
                                    moms
                                  </span>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                        {currentUser ? (
                          <RenderUserDetails
                            currentUser={currentUser}
                            authId={authId}
                            firestore={firestore}
                          />
                        ) : (
                          ''
                        )}
                        <TermsCheckBox
                          handleCheckboxChange={this.handleCheckboxChange}
                        />
                      </DataBox>
                    </WhiteBox>
                  </Modal.Content>
                  <Modal.Actions>
                    <div className="flex no-gap">
                      <Button
                        basic
                        onClick={this.handleClose}
                        primary
                        content="Avbryt"
                        icon="cancel"
                        labelPosition="right"
                      />
                      <Button
                        type="submit"
                        loading={submitting}
                        disabled={
                          !(
                            godkannKopvillkorOchBekraftelse &&
                            godkannJarvaVekanKod
                          )
                        }
                        primary
                        content="Bekräfta"
                        labelPosition="right"
                        icon="checkmark"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleClose();
                          this.handleSubmit();
                        }}
                      />
                    </div>
                  </Modal.Actions>
                </Modal>
              )}
            </Form>
          </section>
        );
      case 0:
      default:
        return (
          <section className="pink">
            <WhiteBox>
              <CardContainer>
                <h3 className="flex align-v-center">
                  <SeminarLogo />
                  <ContentEditor
                    inline
                    contentId="page-user-book-seminar-live-title"
                  >
                    Boka scentid med livesändning & inspelning
                  </ContentEditor>
                </h3>
                <TextCopy name="seminarLive-description" />
                <br />
                <br />
                <div className="horizontal-container">
                  <div>
                    <ContentEditor contentId="page-user-book-seminar-live-details">
                      {`
                      <h5>Beskrivning</h5>
                        <ul>
                          <li>Tältyta på 8x12m i form av tält med plastgolv</li>
                          <li>Scen 6x3m</li>
                          <li>Ca 50 sittplatser</li>
                          <li>3 ståbord</li>
                          <li>Mjukljus för studiomiljö</li>
                          <li>1 ljudanläggning/högtalare</li>
                          <li>6st trådlösa headset</li>
                          <li>2st trådlösa handmikrofoner</li>
                          <li>2st medhörningsmonitorer för publikfrågor</li>
                          <li>Sändningsdator, 3 kameror och bildmixer</li>
                          <li>2st Presentationsdatorer med klicker</li>
                          <li>1st stage-timer</li>
                          <li>86” Presentationsyta</li>
                          <li>Körproducent, bildproducent, ljudtekniker och kameraoperatör</li>
                          <li>Hörslinga för personer med hörselnedsättning</li>
                          <li>Ljudmixer</li>
                          <li>Rullstolsramper till scenen</li>
                          <li>Vattenautomat</li>
                          <li>6 timmar förarbete med producent inför seminariet</li>
                        </ul>
                        <p>
                          <i>
                            * ritningarna i bildspelet är ungefärliga. Bilderna i ritningen ingår inte.
                          </i>
                        </p>
                        <h5>Ordinarie pris</h5>
                      `}
                    </ContentEditor>
                    <p>{Number(pris).toLocaleString('sv')} kr/h exkl. moms</p>
                  </div>
                  <GalleryContainer>
                    <GalleryEditor contentId="gallery-seminarsLive" />
                  </GalleryContainer>
                </div>
              </CardContainer>
            </WhiteBox>
            <RenderBookingSection
              days={days}
              makeSeminarOptions={this.makeSeminarOptions}
            />
            <WhiteBox transparent>
              <Button
                primary
                size="large"
                floated="right"
                labelPosition="right"
                icon="right arrow"
                content="Nästa steg"
                disabled={userSelections.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  this.createBookings();
                  scrollToTop();
                }}
              />
            </WhiteBox>
          </section>
        );
    }
  };

  // #endregion
  render() {
    // console.log('SeminarLive state', this.state?);
    // console.log('SeminarLive props', this.props);

    return <ContentContainer>{this.renderSteps()}</ContentContainer>;
  }
}

SeminarLive.propTypes = {
  firestore: PropTypes.object,
  firebase: PropTypes.object,
  authId: PropTypes.string,
  liveSeminars: PropTypes.array,
  history: PropTypes.object,
  stats: PropTypes.object,
  profile: PropTypes.object,
  currentUser: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(
  withErrorBoundary(SeminarLive, MyFallbackComponent, errorCallback)
);
