// import camelCase from 'lodash/camelCase';
import deburr from 'lodash/deburr';

import { PRODUCT_CATEGORIES } from '../configs';
import isOrgTypeNgo from './isOrgTypeNgo';
import priceOfSelectedProduct from './priceOfSelectedProduct';
import tentsTotalPrice from './tentsTotalPrice';
import transformNgoText from './transformNgoText';

const scrollToTop = () => window.scrollTo(0, 0);

const errorCallback = (error, componentStack) => {
  if (process.env.NODE_ENV === 'production') {
    const data = {
      text: error.toString(),
      attachments: [
        {
          text: componentStack,
          fields: [
            {
              title: 'Error',
              value: 'High',
              short: true
            }
          ]
        }
      ]
    };

    fetch(
      'https://hooks.slack.com/services/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',
      {
        method: 'post',
        headers: {
          Accept: 'application/json, text/plain, */*'
        },
        body: JSON.stringify(data)
      }
    ).catch((err) => console.error(err));
  }
};

const sendDataToSlack = (
  path,
  payload,
  SLACK_URL = 'https://hooks.slack.com/services/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'
) => {
  const data = {
    text: path,
    attachments: [
      {
        text: payload
      }
    ]
  };
  fetch(SLACK_URL, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*'
    },
    body: JSON.stringify(data)
  }).catch((err) => console.error(err));
};

/*
  tent2_5x2_5: {
    p1Count: 0,
    p2Count: 0,
    p1Max: 10,
    p2Max: 10
  },
  tent5x2_5: {
    p1Count: 0,
    p2Count: 0,
    p1Max: 10,
    p2Max: 10
  },
  tent5x5: {
    p1Count: 0,
    p2Count: 0,
    p1Max: 10,
    p2Max: 10
  },
  sponsor: {
  count: 0,
  max: 10,
  }
  */

const isCurrentCountMax = (data) => {
  const { productId, period, fieldLabel, stats, category } = data;

  // category based logic for products
  if (Array.isArray(category)) {
    if (category?.includes?.(PRODUCT_CATEGORIES.tillagg[0])) {
      const { max, count } = data;
      let label = '';
      if (max === 0) {
        label = 'Kan bokas snart';
      }

      const isDisabled = count >= max;

      if (isDisabled) {
        label = 'Fullbokat';
      }

      return { label, isDisabled };
    }
  }

  // below logic depends on stats data
  if (!stats?.[productId]) {
    return;
  }

  const {
    p1Count,
    p2Count,
    p1Max = 0,
    p2Max = 0,
    count,
    max = 0
  } = stats?.[productId] || {};

  let label = fieldLabel;

  if (productId === 'sponsor' || productId === 'sponsor2') {
    if (max === 0) {
      label = 'Kan bokas snart';
    }

    const isDisabled = count >= max;

    if (isDisabled) {
      label = 'Fullbokat';
    }

    return { label, isDisabled };
  }

  if (period === 'p1Count') {
    if (p1Max === 0) {
      label = 'Kan bokas snart';
    }

    const isDisabled = p1Count >= p1Max;

    if (isDisabled) {
      label = `${fieldLabel} Fullbokat`;
    }

    return { label, isDisabled };
  }

  if (period === 'p2Count') {
    if (p2Max === 0) {
      label = 'Kan bokas snart';
    }

    const isDisabled = p2Count >= p2Max;

    if (isDisabled) {
      label = `${fieldLabel} Fullbokat`;
    }

    return { label, isDisabled };
  }
};

function deburrString(inputString) {
  if (!inputString) {
    return;
  }
  return deburr(inputString);
}

export {
  scrollToTop,
  errorCallback,
  isCurrentCountMax,
  transformNgoText,
  tentsTotalPrice,
  sendDataToSlack,
  isOrgTypeNgo,
  priceOfSelectedProduct,
  deburrString
};
