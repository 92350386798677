import React from 'react';
import PropTypes from 'prop-types';

import { P } from '../../styledComponents';
import ContentEditor from '../ContentEditor';

function TextCopy(props) {
  const { name } = props;

  switch (name) {
    case 'seminarLive-description':
      // <TextCopy name="seminarLive-description" />
      return (
        <ContentEditor contentId="page-user-book-seminar-live-description-text-copy">
          {`<i>Boka ett av våra scentält med livesändning och inspelningsteknik och
tekniker och nå ut med ert budskap till hela landet. Sänds live på vår
hemsida. Länken kan inbäddas i er egen hemsida och på andra
plattformar. Scenen kan användas för <b>seminarier, mindre föreläsningar, rundabordssamtal, mingel, workshops m.m.</b>
Plats för ca 60 personer. Bokning per timme varav 45min är programtid.
Detta ingår vid bokning av scentid i mindre tält.</i>`}
        </ContentEditor>
      );

    case 'seminar-description':
      // <TextCopy name="seminar-description" />
      return (
        <ContentEditor contentId="page-user-book-seminar-description-text-copy">
          {`<i>Boka ett av våra scentält och nå ut med ert budskap till våra och era besökare.
Scenen kan användas för <b>seminarier</b>, mindre
<b>föreläsningar, rundabordssamtal, mingel, workshops m.m.</b>
Plats för ca 60 personer. Bokning per timme varav 45min är programtid.</i>`}
        </ContentEditor>
      );

    case 'partner-description':
      // <TextCopy name="partner-description" />
      return (
        <ContentEditor contentId="user-book-content-partner-description-text-copy">
          {`Partnerskapserbjudandet är en möjlighet för företag, myndigheter och
          organisationer att sprida sitt budskap samtidigt som man är en viktig
          part i att möjliggöra dialog och förståelse mellan olika typer av
          makthavare, företag och medborgare som traditionellt inte givits de
          bästa förutsättningar att delta i det offentliga samtalet. Bli partner
          till Järvaveckan och motverka segregation och skapa inkludering
          samtidigt som du får möjlighet till maximal exponering.`}
        </ContentEditor>
      );

    case 'confirm-your-details':
      // <TextCopy name="confirm-your-details" />
      return (
        <ContentEditor contentId="page-user-book-confirm-your-details-text-copy">
          {`Ni håller nu på att genomföra en bokning för Järvaveckan 2024.
          Vänligen granska och bekräfta att ni angett korrekta uppgifter.
          Uppgifterna används som underlag i vår fakturering. När ni granskat
          allt, klicka på “Bekräfta” för att slutföra bokningen. En
          orderbekräftelse skickas då till er angivna e-postadress. Bokningen
          blir bindande 5 dagar efter att ni mottagit orderbekräftelsen.
          Därefter skickas en faktura med 30 dagars betalningsvillkor till er
          angivna e-postadress. Fakturan är bindande. Var uppmärksam på att
          orderbekräftelsen och/eller fakturan kan hamna under spam- eller
          skräppost. Om något är fel i er bokning var god kontakta
          <a href="mailto:info@jarvaveckan.se">info@jarvaveckan.se</a> eller
          ändra/ta bort er beställning på bokningssidan inom 5 dagar.
          `}
        </ContentEditor>
      );
    case 'tillagg-description':
      // <TextCopy name="tillagg-description" />
      return (
        <ContentEditor contentId="page-user-book-tillagg-description-text-copy">
          Amet lorem sem diam id sagittis malesuada nunc ut. Non sed malesuada
          ultrices tristique purus vulputate ornare diam tincidunt.
        </ContentEditor>
      );

    case 'medarrangor-text-copy':
      // <TextCopy name="medarrangor-text-copy" />
      return (
        <h5>
          Medarrangör:{' '}
          <span className="lower-case">
            Kommer ni att arrangera ert seminarium tillsammans med andra
            organisationer? Lägg till dem här! Det är för Järvaveckans egen
            informationsinhämtning och informationen kan komma att publiceras på
            vår hemsida och på andra platser.
          </span>
        </h5>
      );

    case 'highlites-text-copy':
      // <TextCopy name="highlites-text-copy" />
      return (
        <P>
          För att redigera dina uppgifter, klicka på knappen Redigera uppgifter
          längst ner på sidan.
        </P>
      );

    default:
      return null;
  }
}

TextCopy.propTypes = {
  name: PropTypes.string.isRequired
};

export default TextCopy;
