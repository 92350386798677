import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { BookingRegisterContentContainer } from './BookingRegisterStyles';

import AccDataTable from '../../components/AccDataTable';
import Loader from '../../components/Loader';
import { DAYS_TO_INVOICE, EVENT_YEAR } from '../../configs/index';
import { WhiteBox, DataBox } from '../../styledComponents';
import tentsTotalPrice from '../../utils/tentsTotalPrice';
import transformNgoText from '../../utils/transformNgoText';
import handleTableDataTransform from '../../utils/handleTableDataTransform';
import Account from '../Account';
import getUsersFullBookingsData from '../../utils/getUsersFullBookingsData';

// construct user object with all bookings
const addUserToBooking = (bookArray, users, filterBy) => {
  const dataObj = { ...bookArray };
  const newObj = {};

  Object.keys(dataObj).forEach((bookingId) => {
    const currentBooking = get(dataObj, bookingId);

    if (!currentBooking) {
      return;
    }

    const {
      userId,
      taltyta,
      quantity,
      isFullPeriod,
      period1,
      period2,
      priceDiscount = 0,
      discount = 0
    } = currentBooking;

    const period1count = period1 ? 1 * quantity : '';
    const period2count = period2 ? 1 * quantity : '';

    const fakturaDatum = get(currentBooking, 'sentInvoice', false)
      ? `Skickad ${handleTableDataTransform(get(currentBooking, 'invoiceCreatedAt')) || ''}`
      : `Skickas efter ${handleTableDataTransform({
          seconds:
            parseInt(get(currentBooking, 'createAt.seconds'), 10) +
            60 * 60 * 24 * DAYS_TO_INVOICE
        })}`;

    const periodString = isFullPeriod ? '1 & 2' : period1 ? '1' : '2';

    if (filterBy(dataObj[bookingId])) {
      newObj[bookingId] = {
        ...users[userId],
        ...dataObj[bookingId],
        authId: userId,
        tent: taltyta,
        periods: periodString,
        period1count,
        period2count,
        typeOfOrg: transformNgoText(get(users[userId], 'typeOfOrg')),
        sum: tentsTotalPrice(currentBooking),
        discount: (discount + priceDiscount) * -1,
        fakturaDatum
      };
    }
  });
  return newObj;
};

// construct user object with all bookings
const addUserToSeminar = (seminarArray, users) => {
  const dataObj = { ...seminarArray };
  const newObj = {};
  Object.keys(dataObj).forEach((bookingId) => {
    if (!bookingId) {
      return;
    }

    const currentBooking = get(dataObj, bookingId);

    if (!currentBooking) {
      return;
    }

    const bookingUserId = get(currentBooking, 'userId');

    const fakturaDatum = get(currentBooking, 'sentInvoice', false)
      ? `Skickad ${handleTableDataTransform(get(currentBooking, 'invoiceCreatedAt')) || ''}`
      : `Skickas efter ${handleTableDataTransform({
          seconds:
            parseInt(get(currentBooking, 'createAt.seconds'), 10) +
            60 * 60 * 24 * DAYS_TO_INVOICE
        })}`;

    newObj[bookingId] = {
      authId: bookingUserId,
      ...users[bookingUserId],
      ...dataObj[bookingId],
      sum:
        parseInt(get(currentBooking, 'pris'), 10) -
        get(currentBooking, 'discount', 0),
      discount: -get(currentBooking, 'discount', 0),
      fakturaDatum,
      typeOfOrg: transformNgoText(get(users[bookingUserId], 'typeOfOrg'))
    };
  });
  return newObj;
};

function BookingRegister(props) {
  const [showDetailsView, setShowDetailsView] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [editAndSave, setEditAndSave] = useState(false);

  const [tent2_5x2_5_Open, setTent2_5x2_5_Open] = useState(false);
  const [tent5x2_5_Open, setTent5x2_5_Open] = useState(false);
  const [tent5x5_Open, setTent5x5_Open] = useState(false);
  const [sponsorsOpen, setSponsorsOpen] = useState(false);
  const [sponsorsOpen2, setSponsorsOpen2] = useState(false);
  const [seminarsOpen, setSeminarsOpen] = useState(false);
  const [seminarsLiveOpen, setSeminarsLiveOpen] = useState(false);
  const [tillaggBookingsOpen, setTillaggBookingsOpen] = useState(false);

  const {
    users,
    bookings,
    tillaggBookings,
    sponsorBookings,
    seminarsBookings,
    seminarsLiveBookings,
    firestore,
    firebase,
    claims,
    orderedBookings,
    orderedTillaggBookings,
    orderedSeminarBookings,
    orderedSeminarLiveBookings,
    orderedSponsorBookings
  } = props;

  const tent2_5x2_5Data = addUserToBooking(
    bookings,
    users,
    (a) => get(a, 'productId') === 'tent2_5x2_5'
  );
  const tent5x2_5Data = addUserToBooking(
    bookings,
    users,
    (a) => get(a, 'productId') === 'tent5x2_5'
  );
  const tent5x5Data = addUserToBooking(
    bookings,
    users,
    (a) => get(a, 'productId') === 'tent5x5'
  );
  const sponsorData = addUserToBooking(
    sponsorBookings,
    users,
    (a) => get(a, 'productId') === 'sponsor'
  );
  const sponsorData2 = addUserToBooking(
    sponsorBookings,
    users,
    (a) => get(a, 'productId') === 'sponsor2'
  );

  // need to split with productId
  const tillaggData = addUserToBooking(
    tillaggBookings,
    users,
    () => true // using all bookings in the collection
  );

  const seminarData = addUserToSeminar(seminarsBookings, users);
  const seminarLiveData = addUserToSeminar(seminarsLiveBookings, users);

  // data preview
  const handleselectedUserIDClick = ({ authId }) => {
    setShowDetailsView(true);
    setSelectedUserID(authId);
  };

  const registerState = () => {
    setEditAndSave(true);
    console.info('editAndSave', editAndSave);
  };
  const handleCancelPreview = () => {
    setShowDetailsView(false);
    setSelectedUserID(null);
  };

  if (showDetailsView && selectedUserID) {
    const userFullData = getUsersFullBookingsData({
      users,
      selectedUserID,
      orderedBookings,
      orderedTillaggBookings,
      orderedSeminarBookings,
      orderedSeminarLiveBookings,
      orderedSponsorBookings
    });

    return (
      <Account
        {...userFullData}
        firestore={firestore}
        firebase={firebase}
        registerState={registerState}
        handleCancelPreview={handleCancelPreview}
        claims={claims}
      />
    );
  }

  return (
    <BookingRegisterContentContainer>
      <section>
        <WhiteBox>
          <DataBox>
            <h3>Total summa {EVENT_YEAR}</h3>
          </DataBox>
        </WhiteBox>
      </section>

      <section>
        <WhiteBox
          className={tent2_5x2_5_Open ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                setTent2_5x2_5_Open(!tent2_5x2_5_Open);
              }}
            >
              2,5x2,5 Tält
              <span className="small">
                {' '}
                (antal bokningar {Object.keys(tent2_5x2_5Data).length})
              </span>
            </h3>
          </DataBox>
          {!tent2_5x2_5Data ? (
            <div>
              <Loader />
            </div>
          ) : (
            <AccDataTable
              obj={tent2_5x2_5Data}
              columns={[
                ['orgName', 'Organisation'],
                ['typeOfOrg', 'orgtyp'],
                ['createAt', 'Bokat'],
                ['fakturaDatum', 'Faktura'],
                ['pris', 'Pris'],
                ['quantity', 'Tält'],
                ['tent', 'Tälttyp'],
                ['period1count', 'Period_1'],
                ['period2count', 'Period_2'],
                ['sum', 'Summa'],
                ['discount', 'Discount'],
                ['productId', 'Product Id'],
                ['email', 'epost'],
                ['authId', 'userId'],
                ['peId', 'PE:Accounting Id']
              ]}
              uniqKey="userId"
              sort="createAt"
              addClass={(item) => {
                if (typeof item === 'string') {
                  if (item.indexOf('Skickad') >= 0) {
                    return 'sent';
                  }
                  if (item.indexOf('Skickas') >= 0) {
                    return 'to-send';
                  }
                }
              }}
              transform={handleTableDataTransform}
              columnClick={{
                orgName: handleselectedUserIDClick
              }}
            />
          )}
        </WhiteBox>
      </section>

      <section>
        <WhiteBox
          className={tent5x2_5_Open ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                setTent5x2_5_Open(!tent5x2_5_Open);
              }}
            >
              5x2,5 Tält
              <span className="small">
                {' '}
                (antal bokningar {Object.keys(tent5x2_5Data).length})
              </span>
            </h3>
          </DataBox>
          {!tent5x2_5Data ? (
            <div>
              <Loader />
            </div>
          ) : (
            <AccDataTable
              obj={tent5x2_5Data}
              columns={[
                ['orgName', 'Organisation'],
                ['typeOfOrg', 'orgtyp'],
                ['createAt', 'Bokat'],
                ['fakturaDatum', 'Faktura'],
                ['pris', 'Pris'],
                ['quantity', 'Tält'],
                ['tent', 'Tälttyp'],
                ['period1count', 'Period_1'],
                ['period2count', 'Period_2'],
                ['sum', 'Summa'],
                ['discount', 'Discount'],
                ['productId', 'Product Id'],
                ['email', 'epost'],
                ['authId', 'userId'],
                ['peId', 'PE:Accounting Id']
              ]}
              uniqKey="userId"
              sort="createAt"
              addClass={(item) => {
                if (typeof item === 'string') {
                  if (item.indexOf('Skickad') >= 0) {
                    return 'sent';
                  }
                  if (item.indexOf('Skickas') >= 0) {
                    return 'to-send';
                  }
                }
              }}
              transform={handleTableDataTransform}
              columnClick={{
                orgName: handleselectedUserIDClick
              }}
            />
          )}
        </WhiteBox>
      </section>

      <section>
        <WhiteBox
          className={tent5x5_Open ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                setTent5x5_Open(!tent5x5_Open);
              }}
            >
              5x5 Tält
              <span className="small">
                {' '}
                (antal bokningar {Object.keys(tent5x5Data).length})
              </span>
            </h3>
          </DataBox>
          {!tent5x5Data ? (
            <div>
              <Loader />
            </div>
          ) : (
            <AccDataTable
              obj={tent5x5Data}
              columns={[
                ['orgName', 'Organisation'],
                ['typeOfOrg', 'orgtyp'],
                ['createAt', 'Bokat'],
                ['fakturaDatum', 'Faktura'],
                ['pris', 'Pris'],
                ['quantity', 'Tält'],
                ['tent', 'Tälttyp'],
                ['period1count', 'Period_1'],
                ['period2count', 'Period_2'],
                ['sum', 'Summa'],
                ['discount', 'Discount'],
                ['productId', 'Product Id'],
                ['email', 'epost'],
                ['authId', 'userId'],
                ['peId', 'PE:Accounting Id']
              ]}
              uniqKey="userId"
              sort="createAt"
              addClass={(item) => {
                if (typeof item === 'string') {
                  if (item.indexOf('Skickad') >= 0) {
                    return 'sent';
                  }
                  if (item.indexOf('Skickas') >= 0) {
                    return 'to-send';
                  }
                }
              }}
              transform={handleTableDataTransform}
              columnClick={{
                orgName: handleselectedUserIDClick
              }}
            />
          )}
        </WhiteBox>
      </section>

      <section>
        <WhiteBox
          className={sponsorsOpen ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                setSponsorsOpen(!sponsorsOpen);
              }}
            >
              Stödorganisation
              <span className="small">
                {' '}
                (antal bokningar {Object.keys(sponsorData).length})
              </span>
            </h3>
          </DataBox>
          {!sponsorData ? (
            <div>
              <Loader />
            </div>
          ) : (
            <AccDataTable
              obj={sponsorData}
              columns={[
                ['orgName', 'Organisation'],
                ['typeOfOrg', 'orgtyp'],
                ['createAt', 'Bokat'],
                ['fakturaDatum', 'Faktura'],
                ['pris', 'Pris'],
                ['quantity', 'Tält'],
                ['tent', 'Tälttyp'],
                ['period1count', 'Period_1'],
                ['period2count', 'Period_2'],
                ['sum', 'Summa'],
                ['discount', 'Discount'],
                ['productId', 'Product Id'],
                ['email', 'epost'],
                ['authId', 'userId'],
                ['peId', 'PE:Accounting Id']
              ]}
              uniqKey="userId"
              sort="createAt"
              addClass={(item) => {
                if (typeof item === 'string') {
                  if (item.indexOf('Skickad') >= 0) {
                    return 'sent';
                  }
                  if (item.indexOf('Skickas') >= 0) {
                    return 'to-send';
                  }
                }
              }}
              transform={handleTableDataTransform}
              columnClick={{
                orgName: handleselectedUserIDClick
              }}
            />
          )}
        </WhiteBox>
      </section>

      <section>
        <WhiteBox
          className={sponsorsOpen2 ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                setSponsorsOpen2(!sponsorsOpen2);
              }}
            >
              Partner
              <span className="small">
                {' '}
                (antal bokningar {Object.keys(sponsorData2).length})
              </span>
            </h3>
          </DataBox>
          {!sponsorData2 ? (
            <div>
              <Loader />
            </div>
          ) : (
            <AccDataTable
              obj={sponsorData2}
              columns={[
                ['orgName', 'Organisation'],
                ['typeOfOrg', 'orgtyp'],
                ['createAt', 'Bokat'],
                ['fakturaDatum', 'Faktura'],
                ['pris', 'Pris'],
                ['quantity', 'Tält'],
                ['tent', 'Tälttyp'],
                ['period1count', 'Period_1'],
                ['period2count', 'Period_2'],
                ['sum', 'Summa'],
                ['discount', 'Discount'],
                ['productId', 'Product Id'],
                ['email', 'epost'],
                ['authId', 'userId'],
                ['peId', 'PE:Accounting Id']
              ]}
              uniqKey="userId"
              sort="createAt"
              addClass={(item) => {
                if (typeof item === 'string') {
                  if (item.indexOf('Skickad') >= 0) {
                    return 'sent';
                  }
                  if (item.indexOf('Skickas') >= 0) {
                    return 'to-send';
                  }
                }
              }}
              transform={handleTableDataTransform}
              columnClick={{
                orgName: handleselectedUserIDClick
              }}
            />
          )}
        </WhiteBox>
      </section>

      <section>
        <WhiteBox
          className={seminarsOpen ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                setSeminarsOpen(!seminarsOpen);
              }}
            >
              Seminarium
              <span className="small">
                {' '}
                (antal bokningar {Object.keys(seminarData).length})
              </span>
            </h3>
          </DataBox>
          {!seminarData ? (
            <div>
              <Loader />
            </div>
          ) : (
            <AccDataTable
              obj={seminarData}
              columns={[
                ['orgName', 'Organisation'],
                ['typeOfOrg', 'orgtyp'],
                ['createAt', 'Bokat'],
                ['fakturaDatum', 'Faktura'],
                ['dayString', 'Dag'],
                ['timeSlot', 'Tid'],
                ['sum', 'Summa'],
                ['discount', 'Discount'],
                ['productId', 'Product Id'],
                ['email', 'epost'],
                ['authId', 'userId'],
                ['peId', 'PE:Accounting Id']
              ]}
              uniqKey="slotId"
              sort="createAt"
              addClass={(item) => {
                if (typeof item === 'string') {
                  if (item.indexOf('Skickad') >= 0) {
                    return 'sent';
                  }
                  if (item.indexOf('Skickas') >= 0) {
                    return 'to-send';
                  }
                }
              }}
              transform={handleTableDataTransform}
              columnClick={{
                orgName: handleselectedUserIDClick
              }}
            />
          )}
        </WhiteBox>
      </section>

      <section>
        <WhiteBox
          className={seminarsLiveOpen ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                setSeminarsLiveOpen(!seminarsLiveOpen);
              }}
            >
              Seminarium Live
              <span className="small">
                {' '}
                (antal bokningar {Object.keys(seminarLiveData).length})
              </span>
            </h3>
          </DataBox>
          {!seminarLiveData ? (
            <div>
              <Loader />
            </div>
          ) : (
            <AccDataTable
              obj={seminarLiveData}
              columns={[
                ['orgName', 'Organisation'],
                ['typeOfOrg', 'orgtyp'],
                ['createAt', 'Bokat'],
                ['fakturaDatum', 'Faktura'],
                ['dayString', 'Dag'],
                ['timeSlot', 'Tid'],
                ['sum', 'Summa'],
                ['discount', 'Discount'],
                ['productId', 'Product Id'],
                ['email', 'epost'],
                ['authId', 'userId'],
                ['peId', 'PE:Accounting Id']
              ]}
              uniqKey="slotId"
              sort="createAt"
              addClass={(item) => {
                if (typeof item === 'string') {
                  if (item.indexOf('Skickad') >= 0) {
                    return 'sent';
                  }
                  if (item.indexOf('Skickas') >= 0) {
                    return 'to-send';
                  }
                }
              }}
              transform={handleTableDataTransform}
              columnClick={{
                orgName: handleselectedUserIDClick
              }}
            />
          )}
        </WhiteBox>
      </section>

      <section>
        <WhiteBox
          className={tillaggBookingsOpen ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                setTillaggBookingsOpen(!tillaggBookingsOpen);
              }}
            >
              Tillägg
              <span className="small">
                {' '}
                (antal bokningar {Object.keys(tillaggData).length})
              </span>
            </h3>
          </DataBox>
          {!tillaggData ? (
            <div>
              <Loader />
            </div>
          ) : (
            <AccDataTable
              obj={tillaggData}
              columns={[
                ['orgName', 'Organisation'],
                ['createAt', 'Bokat'],
                ['fakturaDatum', 'Faktura'],
                ['pris', 'Pris'],
                ['quantity', 'Antal'],
                ['sum', 'Summa'],
                ['discount', 'Discount'],
                ['title', 'Product Name'],
                ['productId', 'Product Id'],
                ['email', 'epost'],
                ['authId', 'userId'],
                ['peId', 'PE:Accounting Id']
              ]}
              uniqKey="slotId"
              sort="createAt"
              addClass={(item) => {
                if (typeof item === 'string') {
                  if (item.indexOf('Skickad') >= 0) {
                    return 'sent';
                  }
                  if (item.indexOf('Skickas') >= 0) {
                    return 'to-send';
                  }
                }
              }}
              transform={handleTableDataTransform}
              columnClick={{
                orgName: handleselectedUserIDClick
              }}
            />
          )}
        </WhiteBox>
      </section>
    </BookingRegisterContentContainer>
  );
}

BookingRegister.propTypes = {
  users: PropTypes.object,
  bookings: PropTypes.object,
  tillaggBookings: PropTypes.object,
  sponsorBookings: PropTypes.object,
  seminarsBookings: PropTypes.object,
  seminarsLiveBookings: PropTypes.object,
  firestore: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired,
  claims: PropTypes.object.isRequired,
  orderedBookings: PropTypes.array,
  orderedTillaggBookings: PropTypes.array,
  orderedSeminarBookings: PropTypes.array,
  orderedSeminarLiveBookings: PropTypes.array,
  orderedSponsorBookings: PropTypes.array
};

BookingRegister.defaultProps = {
  orderedBookings: [],
  orderedTillaggBookings: [],
  orderedSeminarBookings: [],
  orderedSeminarLiveBookings: [],
  orderedSponsorBookings: []
};

export default BookingRegister;
