/**
 * selectedProduct
 */
export default function tentsTotalPrice(bookingData) {
  const { totalPrice, discount = 0, pris } = bookingData;

  if (totalPrice) {
    return totalPrice - discount;
  }

  return pris - discount;
}
