/**
 * Create the store with dynamic reducers
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore'; // <- needed if using firestore
import 'firebase/storage';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { createStore, applyMiddleware, compose } from 'redux';
import { reduxFirestore } from 'redux-firestore';
import reduxReset from 'redux-reset';
import ReduxThunk from 'redux-thunk';

import createReducer from './reducers';

// cspell:disable
const fbConfig = () => {
  if (process.env.APP_ENV === 'live') {
    return {
      apiKey: 'AIzaSyDZg_smslN2twDEgeEGlGFUnLk6t2wJUbc',
      authDomain: 'jarvaveckan-live.firebaseapp.com',
      databaseURL: 'https://jarvaveckan-live.firebaseio.com',
      projectId: 'jarvaveckan-live',
      storageBucket: 'jarvaveckan-live.appspot.com',
      messagingSenderId: '551008308511'
    };
  }
  return {
    apiKey: 'AIzaSyDYue9L3mtKAh_v8hQrtlbY-0cPi69d1T8',
    authDomain: 'jarvaveckan-booking.firebaseapp.com',
    databaseURL: 'https://jarvaveckan-booking.firebaseio.com',
    projectId: 'jarvaveckan-booking',
    storageBucket: 'jarvaveckan-booking.appspot.com',
    messagingSenderId: '886702413953'
  };
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableLogging: false,
  useFirestoreForStorageMeta: true
};

// initialize firebase instance
if (!firebase?.apps?.length) {
  firebase.initializeApp(fbConfig());
  // window.firebase = firebase;

  // initialize Firestore
  const firestore = firebase.firestore();
  const settings = {
    merge: true
  };
  firestore.settings(settings);
  // firebase.functions() // <- needed if using httpsCallable
}

export default function configureStore(initialState = {}) {
  const middlewares = [ReduxThunk, ReduxThunk.withExtraArgument(getFirebase)];
  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(
      ...enhancers,
      reduxReset(),
      reactReduxFirebase(firebase, rrfConfig),
      reduxFirestore(firebase)
    )
  );

  // Add an authentication state observer
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in
      const loggedin = new Date(); // Capture the loggedin

      // Update Firestore with loggedin
      const firestore = firebase.firestore();
      const userDocRef = firestore.collection('users').doc(user.uid);

      userDocRef
        .update({
          loggedin
        })
        // .then(() => {
        //   console.info('loggedin updated to db');
        // })
        .catch((error) => {
          console.error('Error updating loggedin in Firestore:', error);
        });
    }
  });

  // Extensions
  store.injectedReducers = {}; // Reducer registry

  return store;
}
