import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FileOrImagePreview from '../FileOrImagePreview/FileOrImagePreview';

function RenderInputs(props) {
  const { formFields, formValues = {} } = props;

  const [previewSrc, setPreviewSrc] = useState('');

  if (!formFields) return null;

  return formFields.map((item, index) => {
    const {
      label,
      name,
      value,
      checked,
      required,
      options,
      info,
      min,
      type = 'text',
      editable = true,
      readOnly = false,
      multiple = false
    } = item;

    let defaultValue = formValues[name] ?? value;
    const defaultChecked = formValues[name] ?? checked;

    // use editable if the form is in editable mode not add mode
    const readOnlyComputed = readOnly || (formValues[name] ? !editable : false);

    switch (type) {
      case 'select': {
        if (!multiple && Array.isArray(defaultValue)) {
          defaultValue = formValues?.[name]?.[0] ?? value;
        }

        return (
          <label key={index} htmlFor={name}>
            <span>{label}:</span>
            {required ? <span className="required">*</span> : ''}
            <select
              data-name={name}
              name={name}
              required={required}
              readOnly={readOnlyComputed}
              multiple={multiple}
              defaultValue={defaultValue}
            >
              <option value="">-</option>
              {options.map((option, index2) => (
                <option
                  data-value={option.value}
                  key={index2}
                  value={option.value}
                >
                  {option.text}
                </option>
              ))}
            </select>
            {info ? <span className="info">{info}</span> : ''}
          </label>
        );
      }

      case 'textarea':
        return (
          <label key={index} htmlFor={name}>
            <span>{label}:</span>
            <textarea
              name={name}
              data-name={name}
              required={required}
              readOnly={readOnlyComputed}
              cols={30}
              rows={10}
              defaultValue={defaultValue}
            />
            {info ? <span className="info">{info}</span> : ''}
          </label>
        );

      case 'file':
        return (
          <label key={index} htmlFor={name}>
            <span>{label}:</span>
            <span className="upload-note">
              * allowed: svg,png - max size 1MB
            </span>
            <input
              type={type}
              name={name}
              data-name={name}
              required={required}
              readOnly={readOnlyComputed}
              accept="image/*,.svg"
              onChange={(event) => {
                const file = event.target.files;
                if (file.length > 0) {
                  setPreviewSrc(file);
                }
              }}
            />
            <FileOrImagePreview img={previewSrc} />
            {info ? <span className="info">{info}</span> : ''}
          </label>
        );

      case 'checkbox':
        return (
          <label key={index} htmlFor={name}>
            <span>{label}:</span>
            {required ? <span className="required">*</span> : ''}
            <input
              type={type}
              name={name}
              required={required}
              data-name={name}
              defaultChecked={defaultChecked}
              defaultValue={defaultValue}
            />
            {info ? <span className="info">{info}</span> : ''}
          </label>
        );

      default:
        return (
          <label key={index} htmlFor={name}>
            <span>{label}:</span>
            {required ? <span className="required">*</span> : ''}
            <input
              type={type}
              name={name}
              required={required}
              data-name={name}
              readOnly={readOnlyComputed}
              defaultValue={defaultValue}
              min={min}
            />
            {info ? <span className="info">{info}</span> : ''}
          </label>
        );
    }
  });
}

RenderInputs.propTypes = {
  formFields: PropTypes.array.isRequired,
  formValues: PropTypes.object
};

export default RenderInputs;
