/**
 *
 * LandingPage
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { withFirestore } from 'react-redux-firebase';
import { compose } from 'redux';

import Login from '../../components/Login';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import { EVENT_YEAR, EVENT_DURATION_STRING } from '../../configs';
import { LandingPageWrapper } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import SignUpContainer from '../SignUpContainer';
import { Container, FormWrapper, AnimationWrapper } from './styles';

class LandingPage extends React.Component {
  state = {
    showLogin: true,
    step: 1,
    totalSteps: 3
  };

  handelNextStep = () => {
    this.setState((prevState) => ({
      step: prevState.step + 1
    }));
  };

  handelReset = () => {
    this.setState({ step: 1, showLogin: true });
  };

  handelPrevStep = () => {
    this.setState((prevState) => ({
      step: prevState.step - 1
    }));
  };

  renderText = () => {
    const { step, showLogin } = this.state;
    const { admin } = this.props;

    if (showLogin) {
      return admin ? (
        <>
          <h1>Välkommen till Järvaveckans administration!</h1>
          <p>Skriv in dina uppgifter för att logga in på bokningssidan.</p>
        </>
      ) : (
        <h1>Välkommen till Järvaveckan 2024!</h1>
      );
    }
    switch (step) {
      case 2:
        return (
          <h1 className="fz-4rem_desktop">
            Järvaveckan är den självklara mötesplatsen för social hållbarhet,
            demokrati, inkludering och mångfald. Vi välkomnar alla arrangörer
            som vill skapa positiv förändring i samhället.
          </h1>
        );
      case 3:
        return (
          <h1>
            Järvaveckan är en mötesplats för öppna och tillgängliga samtal utan
            barriärer.
          </h1>
        );
      case 4:
        return (
          <h1>
            Genom att medverka riktar du landets intresse mot alla de frågor som
            Sveriges utsatta områden ställer till samhället.
          </h1>
        );

      case 1:
      default:
        return (
          <>
            <h1>Järvaveckan {EVENT_YEAR}</h1>
            <p>
              Järvaveckan handlar om inkludering av alla människor i det
              sociala, ekonomiska och politiska livet. Tillsammans gör vi
              skillnad!
            </p>
            <p>
              Skapa ett konto på Järvaveckans bokningssida för att boka ditt
              deltagande nu.
            </p>
            <p>
              Alla ansökningar genomgår en granskning innan de godkänns för ett
              konto hos Järvaveckan.
            </p>
          </>
        );
    }
  };

  render() {
    const { toPath = '/user', admin = false } = this.props;
    const { showLogin, step, totalSteps } = this.state;

    return (
      <div>
        <Helmet>
          <title>Järvaveckans bokningssystem</title>
          <meta
            name="description"
            content="Boka ditt deltagande nu! Vi har nu öppnat bokningen för Järvaveckan 2024."
          />
        </Helmet>
        <LandingPageWrapper>
          <h2>
            <a
              href="https://jarvaveckan.se/"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline hover-current-color"
            >
              Järvaveckan
            </a>{' '}
            <span>{EVENT_DURATION_STRING}</span>
          </h2>
          <Container>
            <section>{this.renderText()}</section>
            <section>
              <FormWrapper>
                {showLogin ? (
                  <Login
                    toPath={toPath}
                    admin={admin}
                    goToSignUp={() => {
                      this.setState({
                        showLogin: false
                      });
                    }}
                  />
                ) : (
                  <SignUpContainer
                    handelNextStep={this.handelNextStep}
                    handelPrevStep={this.handelPrevStep}
                    handelReset={this.handelReset}
                    step={step}
                    totalSteps={totalSteps}
                    goToLogin={() => {
                      this.setState({
                        showLogin: true
                      });
                    }}
                  />
                )}
              </FormWrapper>
            </section>
          </Container>
        </LandingPageWrapper>
        <AnimationWrapper>
          <lottie-player
            src="https://lottie.host/d71e4030-e365-4751-96ee-0fea9c838c2c/uE27fSGGsE.json"
            autoPlay
            loop
            mode="normal"
            count="0"
            speed="1"
            direction="1"
            intermission="0"
            background="#0000"
            debug="true"
          />
        </AnimationWrapper>
      </div>
    );
  }
}

LandingPage.propTypes = {
  toPath: PropTypes.string,
  admin: PropTypes.bool
};

export default compose(withFirestore)(
  withErrorBoundary(LandingPage, MyFallbackComponent, errorCallback)
);
