/**
 *
 * Tent
 *
 */
import React, { Fragment } from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import set from 'lodash/set';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Message, Modal, Label } from 'semantic-ui-react';

import { uploadFileToStorage } from '../../api/index';
import BookingConfirm from '../../components/BookingConfirm';
import BookingInformationNote from '../../components/BookingInformationNote';
import ContentEditor from '../../components/ContentEditor';
import RenderCosponsor from '../../components/RenderCosponsor';
import RenderTents from '../../components/RenderTents';
import RenderUserDetails from '../../components/RenderUserDetails/index';
import TermsCheckBox from '../../components/TermsCheckBox';
import TextCopy from '../../components/TextCopy/TextCopy';
import {
  AMNE_OPTIONS,
  TILLGANGLIGHET_OPTIONS,
  SPRAK_OPTIONS,
  MAX_SELECT
} from '../../configs/index';
import { ContentContainer, DataBox, WhiteBox } from '../../styledComponents';
import { isOrgTypeNgo, priceOfSelectedProduct } from '../../utils';
import { SelectProducts } from './styles';

export class Tent extends React.Component {
  state = {
    step: 1,
    cosponsorsCount: 0,
    cosponsors: {},
    modalOpen: false,
    kopvillkor: '',
    work_man_info: '',
    godkannKopvillkorOchBekraftelse: false,
    godkannJarvaVekanKod: false,
    submitting: false
  };

  filterKey = 'tent';

  async componentDidMount() {
    const { setStep } = this.props;
    this.setState({ step: setStep || 1 });
  }

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  // #region handleInputChange
  handleInputChange = (cosponsorsId) => (e) => {
    const {
      target: { name, value, type, checked }
    } = e;
    if (cosponsorsId) {
      this.setState((prevState) => {
        const { cosponsors } = prevState;
        const newState = { ...cosponsors };
        newState[cosponsorsId] = {
          ...cosponsors[cosponsorsId],
          [name]: value
        };
        return { cosponsors: newState };
      });
    } else {
      const localValue = type === 'checkbox' ? checked : value;
      this.setState({ [name]: localValue });
    }
  };
  // #endregion

  handleCheckboxChange = (_, { name, value, type, checked }) => {
    const localValue = type === 'checkbox' ? checked : value;
    this.setState({ [name]: localValue });
  };

  // #region deleteCosponsors
  deleteCosponsors = (key) => {
    this.setState((prevState) => {
      const cosponsors = { ...prevState.cosponsors };
      delete cosponsors[key];
      return {
        ...prevState,
        cosponsors,
        cosponsorsCount: prevState.cosponsorsCount - 1
      };
    });
  };
  // #endregion

  handleNextButtonClick = (data) => {
    // console.log(data);
    this.setState((prevState) => ({
      step: prevState.step + 1,
      selectedProduct: { ...data }
    }));
  };

  // #region handleSubmit
  handleSubmit = async () => {
    this.setState({ submitting: true });

    const {
      firestore,
      firebase,
      currentUser,
      auth: { uid }
    } = this.props;

    const { organizerName } = currentUser || {};

    const omitState = [
      'step',
      'kopvillkor',
      'work_man_info',
      'modalOpen',
      'selectedProduct',
      'logo',
      'kontaktProfilbild',
      'kontaktProfilbild2',
      'submitting'
    ];

    const selectedState = omit(this.state, omitState);

    const selectedProduct = get(this, 'state.selectedProduct');

    if (!selectedProduct) {
      return 'empty product';
    }

    const filteredProductProperties = omit(selectedProduct, [
      'createAt',
      'updateAt',
      'images'
    ]);

    const bookingDataRaw = {
      userId: uid,
      collection: 'bookings',
      sentInvoice: false,

      ...selectedState,
      ...filteredProductProperties,
      ...priceOfSelectedProduct(selectedProduct),
      createAt: firestore.FieldValue.serverTimestamp(),
      organizerName: organizerName || ''
    };

    const { logo, kontaktProfilbild, kontaktProfilbild2 } = this.state;

    if (logo) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid,
          fileList: logo,
          firebase
        });

        if (downloadURL) {
          bookingDataRaw.logo = downloadURL;
        }
      } catch (error) {
        console.error('upload logo error', error);
      } finally {
        console.info('logo uploaded request finished');
      }
    }

    if (kontaktProfilbild) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid,
          fileList: kontaktProfilbild,
          firebase
        });
        if (downloadURL) {
          bookingDataRaw.kontaktProfilbild = downloadURL;
        }
      } catch (error) {
        console.error('kontaktProfilbild', error);
      } finally {
        console.info('kontaktProfilbild request finished');
      }
    }

    if (kontaktProfilbild2) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid,
          fileList: kontaktProfilbild2,
          firebase
        });
        if (downloadURL) {
          bookingDataRaw.kontaktProfilbild2 = downloadURL;
        }
      } catch (error) {
        console.error('kontaktProfilbild2', error);
      } finally {
        console.info('kontaktProfilbild2 request finished');
      }
    }

    const bookingData = omit(bookingDataRaw, isNil);

    firestore
      .add(
        {
          collection: 'bookings',
          path: 'currentUserBookings'
        },
        bookingData
      )
      .then(() => {
        this.setState((prevState) => ({ step: prevState.step + 1 }));
      })
      .catch((err) => this.setState({ error: err }))
      .finally(() => {
        firestore
          .get({
            collection: 'bookings',
            path: 'currentUserBookings',
            where: ['userId', '==', uid]
          })
          .catch((err) => console.error(err))
          .finally(() => this.setState({ submitting: false }));
      });
  };
  // #endregion

  selectedProducts = (products) =>
    filter(products, (item) => {
      const { category, productId } = item;

      // if not ngo remove 'tent2_5x2_5'
      const isNgoUser = isOrgTypeNgo(get(this.props, 'currentUser.typeOfOrg'));

      if (productId === 'tent2_5x2_5' && !isNgoUser) {
        return false;
      }

      return includes(category, this.filterKey);
    });

  // #region renderSteps
  renderSteps = () => {
    const { step, godkannKopvillkorOchBekraftelse, godkannJarvaVekanKod } =
      this.state;
    const { products, history, loading, firestore, currentUser, authId } =
      this.props;

    const { organizerName } = currentUser || {};

    if (loading) {
      return null;
    }

    switch (step) {
      case 2: {
        const selectedProduct = get(this, 'state.selectedProduct', {});

        if (!selectedProduct) {
          return 'empty product';
        }

        const {
          productId,
          pris,
          taltyta,
          labelDay1,
          labelDay2,
          labelDay3,
          labelDay4,
          period1Label,
          period2Label,
          fullPeriodLabel
        } = selectedProduct;

        const {
          quantity,
          period1,
          period2,
          bredvid,
          noOfPeriods,
          isFullPeriod,
          totalPrice
          //, priceDiscount
        } = priceOfSelectedProduct(selectedProduct);

        const {
          error,
          ovrigt,
          cosponsors,
          highlightsDay1,
          highlightsDay2,
          highlightsDay3,
          highlightsDay4,
          submitting,
          modalOpen,
          amne,
          tillganglighet,
          sprak,
          reservationSiteLink,
          kontaktperson,
          kontaktJobbtitel,
          kontaktOrganisation,
          kontaktEmail,
          kontaktTel,
          kontaktperson2,
          kontaktJobbtitel2,
          kontaktOrganisation2,
          kontaktEmail2,
          kontaktTel2
        } = this.state;

        let localTitle = 'tältyta';

        if (productId === 'tent5x5') {
          localTitle = 'tältplats';
        }

        return (
          <Fragment key={productId}>
            <WhiteBox transparent>
              <h2>Information om din {localTitle}</h2>
              <ContentEditor contentId="page-book-tent-step-2-info">
                Här kan ni fylla i information kring era aktiviteter på
                Järvaveckan 2024. För att säkra er plats och genomföra bokningen
                behöver ni inledningsvis enbart fylla i uppgifterna under Mitt
                Konto för att kunna göra en bokning.
              </ContentEditor>
              <p>
                <br />
                {isFullPeriod ? (
                  <>
                    <b>Full Period:</b> {fullPeriodLabel} <br />
                  </>
                ) : (
                  (period1 && (
                    <>
                      <b>Period-1:</b> {period1Label} <br />
                    </>
                  )) ||
                  (period2 && (
                    <>
                      <b>Period-2:</b> {period2Label} <br />
                    </>
                  ))
                )}
                <b>Tält:</b> <span className="titleCase">{localTitle}</span>{' '}
                {taltyta}.<br />
                <b>Antal Period:</b> {noOfPeriods}
                <br />
                <b>Pris/period:</b> {Number(pris).toLocaleString('sv')} kr
                exkl.moms <br />
                <b>Antal:</b> {quantity} <br />
                {bredvid && (
                  <>
                    <b>Bredvid:</b> JA <br />
                  </>
                )}
                <b>Summa Pris:</b> {Number(totalPrice).toLocaleString('sv')} kr
                exkl.moms
                <br />
              </p>

              <BookingInformationNote />
            </WhiteBox>
            <WhiteBox>
              <Form
                error={Boolean(error)}
                onSubmit={this.handleOpen}
                loading={submitting}
              >
                <Grid stackable columns="equal">
                  {/* form col-1 */}
                  <Grid.Column>
                    <h5>Uppgifter för tältet</h5>
                    <Form.TextArea
                      onChange={this.handleInputChange()}
                      label="Förklara vad ni vill att besökare ska veta om era aktiviteter (max 800 tecken inkl. blanksteg)"
                      maxLength="800"
                      id="ovrigt"
                      name="ovrigt"
                      value={ovrigt || ''}
                    />
                    <h5>Kategorityp</h5>
                    <Form.Select
                      multiple
                      label="Ämne"
                      onChange={(_, target) => {
                        const { name, value } = target;
                        if (value.length > MAX_SELECT) {
                          return alert('Max 5 val');
                        }

                        this.setState((prevState) => ({
                          ...prevState,
                          [name]: value
                        }));
                      }}
                      id="amne"
                      name="amne"
                      value={amne || []}
                      options={AMNE_OPTIONS}
                      placeholder="välj ett eller flera"
                    />
                    <Form.Select
                      label="Tillgänglighet"
                      onChange={(_, target) => {
                        const { name, value } = target;
                        if (value.length > MAX_SELECT) {
                          return alert('Max 5 val');
                        }
                        this.setState((prevState) => ({
                          ...prevState,
                          [name]: value
                        }));
                      }}
                      id="tillganglighet"
                      name="tillganglighet"
                      value={tillganglighet || []}
                      multiple
                      options={TILLGANGLIGHET_OPTIONS}
                      placeholder="välj"
                    />
                    <Form.Select
                      label="Språk"
                      multiple
                      onChange={(_, target) => {
                        const { name, value } = target;
                        if (value.length > MAX_SELECT) {
                          return alert('Max 5 val');
                        }
                        this.setState((prevState) => ({
                          ...prevState,
                          [name]: value
                        }));
                      }}
                      id="sprak"
                      name="sprak"
                      value={sprak || []}
                      options={SPRAK_OPTIONS}
                      placeholder="välj"
                    />

                    <h5>
                      Extern länk för anmälan via exempelvis Eventbrite eller
                      Invajo.
                    </h5>
                    <Form.Input
                      onChange={this.handleInputChange()}
                      id="reservationSiteLink"
                      name="reservationSiteLink"
                      value={reservationSiteLink || ''}
                      placeholder="https://accomplice.se"
                      label="Fyller du i detta fält
                      så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                      den länk du fyller i."
                    />

                    <h5>KONTAKTPERSONER FÖR TÄLTET</h5>
                    <Label className="info">
                      Kontaktinfo (email & tel) kommer synas på vår hemsida
                      offentligt!
                    </Label>
                    {/* contact 1 */}
                    <fieldset>
                      <legend>Kontaktperson: 1</legend>
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktperson"
                        name="kontaktperson"
                        value={kontaktperson || ''}
                        label="Förnamn och efternamn"
                      />
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktJobbtitel"
                        name="kontaktJobbtitel"
                        value={kontaktJobbtitel || ''}
                        label="Jobbtitel"
                      />
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktOrganisation"
                        name="kontaktOrganisation"
                        value={kontaktOrganisation || ''}
                        label="Organisation"
                      />
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange()}
                        id="kontaktEmail"
                        name="kontaktEmail"
                        label="Email"
                        type="email"
                        value={kontaktEmail || ''}
                        placeholder="name@mail.com"
                      />
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange()}
                        id="kontaktTel"
                        name="kontaktTel"
                        label="Tel"
                        type="tel"
                        value={kontaktTel || ''}
                        placeholder="+46123456789"
                      />
                    </fieldset>
                    {/* contact 2 */}
                    <fieldset>
                      <legend>Kontaktperson: 2</legend>
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktperson2"
                        name="kontaktperson2"
                        value={kontaktperson2 || ''}
                        label="Förnamn och efternamn"
                      />
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktJobbtitel2"
                        name="kontaktJobbtitel2"
                        value={kontaktJobbtitel2 || ''}
                        label="Jobbtitel"
                      />
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktOrganisation2"
                        name="kontaktOrganisation2"
                        value={kontaktOrganisation2 || ''}
                        label="Organisation"
                      />
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange()}
                        id="kontaktEmail2"
                        name="kontaktEmail2"
                        label="Email"
                        type="email"
                        value={kontaktEmail2 || ''}
                        placeholder="name@mail.com"
                      />
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange()}
                        id="kontaktTel2"
                        name="kontaktTel2"
                        label="Tel"
                        type="tel"
                        value={kontaktTel2 || ''}
                        placeholder="+46123456789"
                      />
                    </fieldset>
                  </Grid.Column>
                  {/* form col-2 */}
                  <Grid.Column>
                    <h5>Highlights / Händer i tältet</h5>
                    <TextCopy name="highlites-text-copy" />
                    <br />
                    <br />

                    {period1 && (
                      <>
                        <Form.TextArea
                          maxLength="330"
                          onChange={this.handleInputChange()}
                          value={highlightsDay1 || ''}
                          id="highlightsDay1"
                          name="highlightsDay1"
                          label={`${labelDay1} (max 330 tecken inkl. blanksteg)`}
                        />
                        <br />
                        <Form.TextArea
                          maxLength="330"
                          onChange={this.handleInputChange()}
                          value={highlightsDay2 || ''}
                          id="highlightsDay2"
                          name="highlightsDay2"
                          label={`${labelDay2} (max 330 tecken inkl. blanksteg)`}
                        />
                        <br />
                      </>
                    )}
                    {period2 && (
                      <>
                        <Form.TextArea
                          maxLength="330"
                          onChange={this.handleInputChange()}
                          value={highlightsDay3 || ''}
                          id="highlightsDay3"
                          name="highlightsDay3"
                          label={`${labelDay3} (max 330 tecken inkl. blanksteg)`}
                        />
                        <br />
                        <Form.TextArea
                          maxLength="330"
                          onChange={this.handleInputChange()}
                          value={highlightsDay4 || ''}
                          id="highlightsDay4"
                          name="highlightsDay4"
                          label={`${labelDay4} (max 330 tecken inkl. blanksteg)`}
                        />
                        <br />
                      </>
                    )}

                    <TextCopy name="medarrangor-text-copy" />

                    <RenderCosponsor
                      cosponsors={cosponsors}
                      deleteCosponsors={this.deleteCosponsors}
                      handleInputChange={this.handleInputChange}
                    >
                      <Button
                        type="button"
                        secondary
                        size="tiny"
                        icon="user plus"
                        labelPosition="right"
                        content="Lägg till medarrangörer"
                        onClick={() => {
                          this.setState((prevState) => {
                            const newItemKey = `s${
                              prevState.cosponsorsCount + 1
                            }`;

                            set(
                              prevState.cosponsors,
                              [newItemKey, 'id'],
                              newItemKey
                            );

                            return {
                              cosponsors: {
                                ...prevState.cosponsors
                              },
                              cosponsorsCount: prevState.cosponsorsCount + 1
                            };
                          });
                        }}
                        disabled={size(cosponsors) >= 4}
                      />
                    </RenderCosponsor>
                    <br />
                    <br />
                  </Grid.Column>

                  {/* form  error*/}
                  <Grid.Row>
                    {error ? (
                      <Message
                        negative
                        header="Error occurred"
                        content={error}
                      />
                    ) : null}
                  </Grid.Row>

                  {/* buttons */}
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        type="button"
                        basic
                        fluid
                        primary
                        content="Tillbaka"
                        size="large"
                        icon="left arrow"
                        onClick={() => {
                          this.setState((prevState) => ({
                            step: prevState.step - 1
                          }));
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        primary
                        type="submit"
                        icon="checkmark"
                        labelPosition="right"
                        size="large"
                        floated="right"
                        content="Bekräfta bokning"
                        disabled={submitting}
                        loading={submitting}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {modalOpen && (
                  <Modal
                    open={modalOpen}
                    onClose={this.handleClose}
                    size="tiny"
                    closeOnDimmerClick={false}
                    dimmer="inverted"
                  >
                    <Modal.Content>
                      <WhiteBox>
                        <DataBox>
                          <div>
                            <ul aria-label="Vänligen bekräfta dina uppgifter">
                              <li>
                                <TextCopy name="confirm-your-details" />
                              </li>
                              <li aria-label="Organisationsnamn">
                                <span className="form-like">
                                  {organizerName}
                                </span>
                              </li>
                              <li aria-label="Tält">
                                <span className="form-like">{taltyta}</span>
                              </li>
                              {period1 && (
                                <li aria-label="Period-1">
                                  <span className="form-like">
                                    {period1Label}
                                  </span>
                                </li>
                              )}
                              {period2 && (
                                <li aria-label="Period-2">
                                  <span className="form-like">
                                    {period2Label}
                                  </span>
                                </li>
                              )}
                              {bredvid && <li aria-label="Bredvid"> Ja</li>}
                              <li aria-label="Pris/period">
                                <span className="form-like">
                                  {Number(pris).toLocaleString('sv')} kr exkl.{' '}
                                  moms
                                </span>
                              </li>
                              <li aria-label="Antal">
                                <span className="form-like">{quantity}</span>
                              </li>
                              <li aria-label="Pris">
                                <span className="form-like">
                                  {Number(totalPrice).toLocaleString('sv')} kr
                                  exkl. moms
                                </span>
                              </li>
                            </ul>
                          </div>
                          {currentUser ? (
                            <RenderUserDetails
                              currentUser={currentUser}
                              authId={authId}
                              firestore={firestore}
                            />
                          ) : (
                            ''
                          )}
                          <TermsCheckBox
                            handleCheckboxChange={this.handleCheckboxChange}
                          />
                        </DataBox>
                      </WhiteBox>
                    </Modal.Content>
                    <Modal.Actions>
                      <div className="flex no-gap">
                        <Button
                          basic
                          onClick={this.handleClose}
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                        <Button
                          type="submit"
                          loading={submitting}
                          disabled={
                            !(
                              godkannKopvillkorOchBekraftelse &&
                              godkannJarvaVekanKod
                            )
                          }
                          primary
                          content="Bekräfta"
                          labelPosition="right"
                          icon="checkmark"
                          onClick={() => {
                            this.handleClose();
                            this.handleSubmit();
                          }}
                        />
                      </div>
                    </Modal.Actions>
                  </Modal>
                )}
              </Form>
            </WhiteBox>
          </Fragment>
        );
      }
      case 3:
        return <BookingConfirm history={history} />;
      case 1:
      default:
        return (
          <RenderTents
            products={this.selectedProducts(products)}
            handleInputChange={this.handleInputChange()}
            handleNextButtonClick={this.handleNextButtonClick}
            {...this.state}
          />
        );
    }
  };
  // #endregion

  render() {
    const { products } = this.props;
    const { step } = this.state;

    // console.log('Tent state', this.state);
    return (
      <ContentContainer>
        <section>
          <WhiteBox>
            <h1>
              <ContentEditor inline contentId="page-tent-title-1">
                Tältyta
              </ContentEditor>
            </h1>

            {step === 1 ? (
              <SelectProducts>
                {this.selectedProducts(products)?.map((item) => {
                  const { productId, taltyta } = item;
                  let localTitle = 'Tältyta';

                  if (productId === 'tent5x5') {
                    localTitle = 'Tältplats';
                  }

                  return (
                    <a key={productId} href={`#scroll_to_${productId}`}>
                      {localTitle} {taltyta}
                    </a>
                  );
                })}
              </SelectProducts>
            ) : null}
          </WhiteBox>

          {this.renderSteps()}
        </section>
      </ContentContainer>
    );
  }
}

Tent.propTypes = {
  firestore: PropTypes.object,
  firebase: PropTypes.object,
  auth: PropTypes.object,
  profile: PropTypes.object,
  currentUser: PropTypes.object,
  history: PropTypes.object,
  bookings: PropTypes.object,
  products: PropTypes.object,
  setStep: PropTypes.number,
  totalCurrentTentBookings: PropTypes.number,
  loading: PropTypes.bool,
  authId: PropTypes.string
};

export default Tent;
