import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import getDateFromTimeStamp from './getDateFromTimeStamp';
import { totalCost } from './getTotalPrice';

const filterByUserId = (data, userId) => filter(data, { userId });

const getUsersFullBookingsData = ({
  selectedUserID = '',
  users = {},
  orderedBookings,
  orderedTillaggBookings,
  orderedSeminarBookings,
  orderedSeminarLiveBookings,
  orderedSponsorBookings
}) => {
  const fullData = cloneDeep(users);

  const createDateWithBookings = (userId) => {
    const currentOrderedBookings = filterByUserId(orderedBookings, userId);
    const currentOrderedTillaggBookings = filterByUserId(
      orderedTillaggBookings,
      userId
    );
    const currentOrderedSeminarBookings = filterByUserId(
      orderedSeminarBookings,
      userId
    );
    const currentOrderedSeminarLiveBookings = filterByUserId(
      orderedSeminarLiveBookings,
      userId
    );
    const currentOrderedSponsorBookings = filterByUserId(
      orderedSponsorBookings,
      userId
    );

    const totalCostAll = totalCost({
      OrderedBookings: currentOrderedBookings,
      OrderedTillaggBookings: currentOrderedTillaggBookings,
      OrderedSeminarBookings: currentOrderedSeminarBookings,
      OrderedSeminarLiveBookings: currentOrderedSeminarLiveBookings,
      OrderedSponsorBookings: currentOrderedSponsorBookings
    });

    const tentCount = currentOrderedBookings?.reduce(
      (sum, item) => sum + parseInt(item.quantity, 10),
      0
    );
    const tillaggCount = currentOrderedTillaggBookings?.reduce(
      (sum, item) => sum + parseInt(item.quantity, 10),
      0
    );

    const newUserData = {
      ...fullData[userId],
      loggedin: getDateFromTimeStamp(fullData[userId].loggedin),
      totalCost: totalCostAll,
      tentBookings: currentOrderedBookings,
      tent: tentCount,
      tillagg: tillaggCount,
      seminar: currentOrderedSeminarBookings.length,
      seminarLive: currentOrderedSeminarLiveBookings.length,
      sponsor: currentOrderedSponsorBookings.length,
      authId: userId,
      currentUser: users[userId],
      // currentUser: { ...users[userId], id: userId },
      currentUserBookings: keyBy(currentOrderedBookings, 'id'),
      currentUserTillaggBookings: keyBy(currentOrderedTillaggBookings, 'id'),
      currentUserSeminars: keyBy(currentOrderedSeminarBookings, 'id'),
      currentUserSeminarsLive: keyBy(currentOrderedSeminarLiveBookings, 'id'),
      currentUserSponsor: keyBy(currentOrderedSponsorBookings, 'id')
    };

    return newUserData;
  };

  if (selectedUserID) {
    return createDateWithBookings(selectedUserID);
  }

  return Object.keys(users).reduce((acc, userId) => {
    acc[userId] = createDateWithBookings(userId);
    return acc;
  }, fullData);
};

export default getUsersFullBookingsData;
