function getDateFromTimeStamp(timeStamp) {
  // empty date return
  if (!timeStamp) {
    return 'No date';
  }

  // check valid date
  if (Number.isNaN(new Date(timeStamp))) {
    return 'Invalid date';
  }

  // check if firebase time stamp object {seconds: 1234567890, nanoseconds: 1234567890}
  if (timeStamp?.seconds) {
    return new Date(timeStamp.seconds * 1000).toLocaleDateString('sv-SE');
  }

  return new Date(timeStamp).toLocaleDateString('sv-SE');
}

export default getDateFromTimeStamp;
