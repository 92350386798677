/**
 *
 * Book
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';

import flagSvg from '../../assets/flag.svg';
import seminarSvg from '../../assets/seminar.svg';
import starSvg from '../../assets/start.svg';
import tentSvg from '../../assets/tent.svg';
import ContentEditor from '../../components/ContentEditor';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import TextCopy from '../../components/TextCopy/TextCopy';
import { ContentContainer, WhiteBox } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import {
  ImgContainer,
  CardContainer,
  JarvaButtonPrimaryWithMessage
} from './styledBook';

function Book(props) {
  const disabledMessage = 'OBS Vänta tills din ansökan är beviljad!';
  const { disableButtons = false, history } = props;
  return (
    <ContentContainer>
      <section>
        <WhiteBox transparent>
          <ContentEditor contentId="user-book-main-content">
            {`
            <h2>Boka din plats på Järvaveckan 2024</h2>
            <p>
              Här kan ni boka era aktiviteter på Järvaveckan 2024. Börja med att
              välja tältyta, seminarium eller partnerskap för att gå vidare.
              Efter att ha bokat en aktivitet kan ni lägga till flera.
            </p>
            <h4>Järvaveckans datum &amp; öppettider</h4>
            <ul>
              <li>Datum: onsdag 31 maj – lördag 3 juni</li>
              <li>För besökare: kl. 14:00 - 20:00 (6h)</li>
              <li>För utställare: kl. 13:30 - 20:30 (7h)</li>
            </ul>
            <h4>
              Perioder för bokning:
              <span>
                du kan boka två dagar i rad eller samtliga fyra dagar.
              </span>
            </h4>
            <ul>
              <li>Period 1: onsdag 31 maj – torsdag 1 juni</li>
              <li>Period 2: fredag 2 juni – lördag 3 juni</li>
              <li>Hela veckan: period 1 + period 2</li>
            </ul>
            <h4>
              Seminarietider:
              <span>du kan boka seminarier under samtliga fyra dagar.</span>
            </h4>
            <ul>
              <li>Seminarietid 1: 14.30 - 15.15</li>
              <li>Seminarietid 2: 15.45 - 16.30</li>
              <li>Seminarietid 3: 17.00 - 17.45</li>
              <li>Seminarietid 4: 18.45 - 19.30</li>
            </ul>
            <h4>Partiledartal: varje dag</h4>
            <ul>
              <li>Partiledartal 1: kl. 16.30</li>
              <li>Partiledartal 2: kl. 19.30</li>
            </ul>
            <h4>Inflyttningstider för utställare</h4>
            <ul>
              <li>Period 1: tisdag 30 maj kl. 14:00 – 18:00</li>
              <li>Period 2: fredag 2 juni kl. 08:00 – 11:00</li>
            </ul>
            <h4>Utflyttningstider för utställare</h4>
            <ul>
              <li>Period 1: torsdag 1 juni kl. 20:30 – 23:00</li>
              <li>Period 2: lördag 3 juni kl. 20:30 – 23:00</li>
            </ul>

            <h4>Har du frågor?</h4>
            <p>
              Kontakta oss på
              <a href="mailto:info@jarvaveckan.se?Subject=Hello%20again">
                info@jarvaveckan.se
              </a>
            </p>`}
          </ContentEditor>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer>
              <img src={tentSvg} alt="tent" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-tent-title"
                >
                  Tältyta
                </ContentEditor>
              </h3>
              <ContentEditor contentId="user-book-content-bookings-tent">
                Boka en av våra tältytor och kom i kontakt med Järvaveckans
                besökare. Vi erbjuder 2,5x2,5m för endast ideella organisationer
                med svag ekonomisk ställning. För övriga organisationer,
                myndigheter och ideella föreningar med stark ekonomisk ställning
                gäller 5x2,5m eller 5x5. Önskar ni ännu större yta, kontakta
                oss.
              </ContentEditor>
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                aria-label={disabledMessage}
                disabled={disableButtons}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/tent');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-tent-button"
                >
                  Läs mer och boka Tältyta
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer>
              <img src={seminarSvg} alt="seminar" width="33%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-seminar-title"
                >
                  Boka mindre scen & seminarium
                </ContentEditor>
              </h3>
              <TextCopy name="seminar-description" />
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                aria-label={disabledMessage}
                disabled={disableButtons}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/seminar');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-seminar-button"
                >
                  Läs mer och boka Seminarietid
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer>
              <img src={seminarSvg} alt="seminar" width="33%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-seminar-live-title"
                >
                  Boka mindre scen & seminarium – för digital livesändning &
                  inspelning
                </ContentEditor>
              </h3>
              <TextCopy name="seminarLive-description" />
              <br />

              <JarvaButtonPrimaryWithMessage
                basic
                aria-label={disabledMessage}
                disabled={disableButtons}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/seminar-live');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-Seminar-live-button"
                >
                  Läs mer och boka Seminarietid
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer className="large">
              <img src={flagSvg} alt="flag" width="90%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-partner-title"
                >
                  Partnerskap
                </ContentEditor>
              </h3>
              <TextCopy name="partner-description" />
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                disabled={disableButtons}
                aria-label={disabledMessage}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/sponsor');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-partner-button"
                >
                  Läs mer och bli partner
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer className="large" bgColor="var(--jarva-light-back)">
              <img src={starSvg} alt="start" width="90%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-tillagg-title"
                >
                  Övriga produkter och tjänster
                </ContentEditor>
              </h3>
              <TextCopy name="tillagg-description" />
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                disabled={disableButtons}
                aria-label={disabledMessage}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/tillagg');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-tillagg-button"
                >
                  Läs mer och boka tillägg
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>
      </section>
    </ContentContainer>
  );
}

Book.propTypes = {
  history: PropTypes.object.isRequired,
  disableButtons: PropTypes.bool
};
export default withErrorBoundary(Book, MyFallbackComponent, errorCallback);
