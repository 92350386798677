import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import 'tinymce/tinymce';
import { Editor } from '@tinymce/tinymce-react';

// import this before tinymce plugins are imported
import {
  getClaims,
  getContent,
  getIsEdit
} from '../../containers/App/selectors';
import { errorCallback } from '../../utils';
import { saveToServer } from '../../utils/saveToServer';
import MyFallbackComponent from '../MyFallbackComponent';
import { ContentEditorContainer } from './styles';
// first import 'tinymce/tinymce'; then import this
import { inlineSettings, defaultInitSettings } from './tinymce-imports';

function ContentEditor(props) {
  const {
    contentId,
    children,
    content,
    className,
    claims,
    placeHolder,
    inline = false,
    isButton = false
  } = props;

  const editorRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setEditMode] = useState(false);
  const [editorContent, setEditorContent] = useState(() => {
    return get(content, `${contentId}.content`, children);
  });

  useEffect(() => {
    if (content?.[contentId]) {
      setEditorContent(get(content, `${contentId}.content`, children));
    } else {
      setEditorContent(children);
    }
  }, [contentId, content, children]);

  const saveContentToFirebase = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSaving(true);

    if (!editorRef.current) {
      return;
    }

    const editedContent = editorRef.current?.getContent();

    const { firestore } = props;

    try {
      await saveToServer({
        path: `content/${contentId}`,
        data: {
          content: editedContent,
          updateAt: firestore.FieldValue.serverTimestamp(),
          editedBy: claims?.email
        },
        firestore
      });
      console.info('Data saved successfully.', `content/${contentId}`);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setIsSaving(false);
      // Exit edit mode after saving
      setEditMode(false);
      // set time
      setEditorContent(editedContent);
    }
  };

  const mergedInitSettings = inline
    ? {
        ...defaultInitSettings,
        ...inlineSettings
      }
    : defaultInitSettings;

  const computedClass = classNames(
    className,
    {
      isButton
    },
    { isAdmin: claims?.admin },
    { inline }
  );

  return (
    <ContentEditorContainer className={computedClass}>
      {claims?.admin && isEdit ? (
        <div
          className="editable-content"
          tabIndex={0}
          role="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Editor
            initialValue={editorContent || placeHolder}
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            init={mergedInitSettings}
          />

          <div className="button-container">
            <span
              role="button"
              tabIndex={0}
              className={classNames(
                'edit-button',
                'editable-content--save-button',
                { disabled: isSaving }
              )}
              onClick={saveContentToFirebase}
            >
              {isSaving ? 'Sparande...' : 'Spara'}
              <i aria-hidden="true" className="checkmark icon" />
            </span>
            <span
              role="button"
              tabIndex={0}
              className={classNames(
                'edit-button',
                'editable-content--cancel-button',
                { disabled: isSaving }
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditMode(false);
              }}
            >
              Avbryt
              <i aria-hidden="true" className="cancel icon" />
            </span>
          </div>
        </div>
      ) : (
        <div className="editable-content">
          <div
            dangerouslySetInnerHTML={{
              __html: editorContent || placeHolder
            }}
          />
          {claims?.admin && (
            <span
              role="button"
              tabIndex={0}
              className="edit-button icon-only editable-content--edit-button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditMode(true);
              }}
            >
              <i aria-hidden="true" className="pencil icon" />
            </span>
          )}
        </div>
      )}
    </ContentEditorContainer>
  );
}

ContentEditor.propTypes = {
  contentId: PropTypes.string.isRequired,
  firestore: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.string,
  placeHolder: PropTypes.string,
  content: PropTypes.object,
  claims: PropTypes.object,
  inline: PropTypes.bool,
  isButton: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  claims: getClaims,
  content: getContent,
  isEdit: getIsEdit
});

const withConnect = connect(mapStateToProps);

export default compose(
  withFirestore,
  withConnect
)(withErrorBoundary(ContentEditor, MyFallbackComponent, errorCallback));
