import React, { useState } from 'react';
import { isEmpty, filter, includes, omit, get, isNil } from 'lodash';
import { PropTypes } from 'prop-types';
import { Button, Message, Modal } from 'semantic-ui-react';

import minusIcon from '../../assets/minus.svg';
import plusIcon from '../../assets/plus.svg';
import BookingConfirm from '../../components/BookingConfirm';
import ContentEditor from '../../components/ContentEditor';
import RenderUserDetails from '../../components/RenderUserDetails';
import TermsCheckBox from '../../components/TermsCheckBox';
import TextCopy from '../../components/TextCopy/TextCopy';
import { ContentContainer, DataBox, WhiteBox } from '../../styledComponents';
import { isCurrentCountMax } from '../../utils';
import priceOfTillagg from '../../utils/priceOfTillagg';
import { TillaggContainer } from './styles';

const defaultState = {
  modalOpen: false,
  kopvillkor: '',
  work_man_info: '',
  godkannKopvillkorOchBekraftelse: false,
  godkannJarvaVekanKod: false,
  submitting: false,
  showBookingConfirm: false
};

function Tillagg(props) {
  const [state, setState] = useState(defaultState);

  const { products, history, firestore, currentUser, authId } = props;

  if (isEmpty(products)) {
    return null;
  }

  const collection = 'tillaggBookings';
  const path = 'currentUserTillaggBookings';
  const productCategoryKey = 'tillagg';
  const { modalOpen, error, showBookingConfirm } = state;

  const handleOpen = () =>
    setState((prevState) => ({
      ...prevState,
      modalOpen: true
    }));

  const handleClose = () =>
    setState((prevState) => ({
      ...prevState,
      modalOpen: false
    }));

  const handleCheckboxChange = (_, { name, value, type, checked }) => {
    const localValue = type === 'checkbox' ? checked : value;

    setState((prevState) => {
      return { ...prevState, [name]: localValue };
    });
  };

  const handleNextButtonClick = (data) => {
    setState((prevState) => ({
      ...prevState,
      selectedProduct: { ...data, ...state }
    }));
  };

  // #region handleSubmit
  const handleSubmit = async () => {
    setState((prevState) => {
      return { ...prevState, submitting: true };
    });

    const { organizerName } = currentUser || {};

    const selectedProduct = get(state, 'selectedProduct');

    if (!selectedProduct) {
      return 'empty product';
    }

    const omitState = [
      'step',
      'kopvillkor',
      'work_man_info',
      'modalOpen',
      'selectedProduct',
      'submitting',
      'showBookingConfirm',
      'submitting',
      'createAt',
      'updateAt',
      'images'
    ];

    const combinedData = {
      ...state,
      ...selectedProduct,
      ...priceOfTillagg(selectedProduct)
    };

    const selectedState = omit(combinedData, omitState);

    const bookingDataRaw = {
      userId: authId,
      collection,
      sentInvoice: false,
      ...selectedState,
      createAt: firestore.FieldValue.serverTimestamp(),
      organizerName: organizerName || ''
    };

    const bookingData = omit(bookingDataRaw, isNil);

    firestore
      .add(
        {
          collection,
          path
        },
        bookingData
      )
      .then(() => {
        setState({ ...defaultState, showBookingConfirm: true });
      })
      .catch((err) =>
        setState((prevState) => ({
          ...prevState,
          submitting: false,
          error: err
        }))
      )
      .finally(() => {
        firestore
          .get({
            collection,
            path,
            where: ['userId', '==', authId]
          })
          .catch((err) => {
            console.error(err);
          });
      });
  };
  // #endregion

  const selectedProducts = filter(products, (item) => {
    const { category, showInBookingPage } = item;
    if (showInBookingPage) {
      return includes(category, productCategoryKey);
    }
    return false;
  });

  const renderProducts = () => {
    if (isEmpty(selectedProducts)) {
      return (
        <WhiteBox>
          <h4>Produkter inte tillgängliga.</h4>
        </WhiteBox>
      );
    }
    return selectedProducts?.map((item) => {
      const { productId, pris, maxQuantity, title } = item;

      const quantityId = `quantity_${productId}`;

      const quantity = state[quantityId] || 1;

      return (
        <WhiteBox
          className="border"
          id={`scroll_to_${productId}`}
          key={productId}
        >
          <div className="media-text">
            <div>
              <h3 className="no-underline">{title}</h3>
              <ContentEditor
                contentId={`page-book-tillagg-${productId}-description`}
              >
                Add description here.
              </ContentEditor>
            </div>
            <div className="medium-image">
              <ContentEditor
                contentId={`page-user-book-tillagg-${productId}-main-1-image`}
              >
                {`
        <img
          src="https://firebasestorage.googleapis.com/v0/b/jarvaveckan-live.appspot.com/o/assets%2FPartnerskap%20-%205x5%2Fimg-10.jpg?alt=media&token=bea2cd5e-0a72-4f23-b807-7b50400f9979"
          alt="people"
          height="auto"
          width="100%"
        />
        `}
              </ContentEditor>
            </div>
          </div>
          <hr style={{ color: 'rgba(0, 0, 0, 0.10)' }} />

          <div className="price-row">
            <div>
              <p>PRIS</p>
              <div className="pris">
                <b>{pris} kr</b> exkl.moms
              </div>
            </div>

            {maxQuantity > 1 ? (
              <div>
                <p>VÄLJ ANTAL</p>
                <div className="quantity-button-group">
                  <Button
                    primary
                    className="icon-button"
                    onClick={() => {
                      setState((prevState) => {
                        const currentQuantity = prevState?.[quantityId] || 1;
                        if (currentQuantity < 2) {
                          return prevState;
                        }

                        const newQuantity = currentQuantity - 1;
                        return { ...prevState, [quantityId]: newQuantity };
                      });
                    }}
                    disabled={quantity < 2}
                  >
                    <img className="img-icon" src={minusIcon} alt="remove" />
                  </Button>
                  <span className="count">{quantity}</span>
                  <Button
                    primary
                    className="icon-button"
                    onClick={() => {
                      setState((prevState) => {
                        const newQuantity = (prevState?.[quantityId] || 1) + 1;
                        return { ...prevState, [quantityId]: newQuantity };
                      });
                    }}
                    disabled={quantity >= maxQuantity}
                  >
                    <img className="img-icon" src={plusIcon} alt="add" />
                  </Button>
                </div>
              </div>
            ) : null}

            <div>
              <Button
                primary
                className="offset-bottom-3px"
                size="large"
                floated="right"
                icon="right arrow"
                labelPosition="right"
                content={isCurrentCountMax(item)?.label || 'Bekräfta bokning'}
                disabled={isCurrentCountMax(item)?.isDisabled}
                onClick={() => {
                  handleNextButtonClick({ ...item });
                  handleOpen();
                }}
              />
            </div>
          </div>
        </WhiteBox>
      );
    });
  };

  const renderConfirmationModal = () => {
    if (!modalOpen) {
      return null;
    }

    const { godkannKopvillkorOchBekraftelse, godkannJarvaVekanKod } = state;

    const { organizerName } = currentUser || {};

    const selectedProduct = get(state, 'selectedProduct');

    if (!selectedProduct) {
      return 'empty product';
    }

    const { pris, title } = selectedProduct;

    const { totalPrice, quantity } = priceOfTillagg(selectedProduct);

    const { submitting } = state;

    return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        size="tiny"
        closeOnDimmerClick={false}
        dimmer="inverted"
      >
        <Modal.Content>
          <WhiteBox>
            <DataBox>
              <div>
                <ul aria-label="Vänligen bekräfta dina uppgifter">
                  <li>
                    <TextCopy name="confirm-your-details" />
                  </li>
                  <li aria-label="Organisationsnamn">
                    <span className="form-like">{organizerName}</span>
                  </li>
                  <li aria-label="Tillägg">
                    <span className="form-like">{title}</span>
                  </li>
                  <li aria-label="Pris/Antal">
                    <span className="form-like">
                      {Number(pris).toLocaleString('sv')} kr exkl. moms
                    </span>
                  </li>
                  <li aria-label="Antal">
                    <span className="form-like">{quantity}</span>
                  </li>
                  <li aria-label="Pris">
                    <span className="form-like">
                      {Number(totalPrice).toLocaleString('sv')} kr exkl. moms
                    </span>
                  </li>
                </ul>
              </div>
              {currentUser ? (
                <RenderUserDetails
                  currentUser={currentUser}
                  authId={authId}
                  firestore={firestore}
                />
              ) : null}
              <TermsCheckBox handleCheckboxChange={handleCheckboxChange} />
            </DataBox>
          </WhiteBox>
        </Modal.Content>
        {error ? (
          <Message negative header="Error occurred" content={error} />
        ) : null}
        <Modal.Actions>
          <div className="flex no-gap">
            <Button
              basic
              onClick={handleClose}
              primary
              content="Avbryt"
              icon="cancel"
              labelPosition="right"
            />
            <Button
              type="submit"
              loading={submitting}
              disabled={
                !(godkannKopvillkorOchBekraftelse && godkannJarvaVekanKod)
              }
              primary
              content="Bekräfta"
              labelPosition="right"
              icon="checkmark"
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <TillaggContainer>
      <ContentContainer>
        <section>
          <WhiteBox>
            <h1>
              <ContentEditor inline contentId="page-tillagg-title-1">
                Övriga produkter och tjänster
              </ContentEditor>
            </h1>
          </WhiteBox>
          {showBookingConfirm ? (
            <BookingConfirm history={history} />
          ) : (
            renderProducts()
          )}
        </section>
      </ContentContainer>
      {renderConfirmationModal()}
    </TillaggContainer>
  );
}

Tillagg.propTypes = {
  products: PropTypes.object,
  firestore: PropTypes.object,
  currentUser: PropTypes.object,
  authId: PropTypes.string,
  history: PropTypes.object
};

export default Tillagg;
